import React from "react";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";

export default function Galeria({ imagenes }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Galería</Typography>
        <GridList cellHeight={200} cols={3}>
            {imagenes.map(imagen => (
                <GridListTile key={imagen.id} cols={1}>
                <img src={imagen.image_url} alt={"Imagen "+ imagen.id} />
                </GridListTile>
            ))}
        </GridList>
      </CardContent>
    </Card>
  );
}

Galeria.propTypes = {
  imagenes: PropTypes.array.isRequired
};
