import {
  GET_AVAILABLE_REQUEST,
  UPLOAD_RESERVATION_REQUEST,
  UPLOAD_RESERVATION_START
} from "../../consts/actionTypes";

export const get = payload => ({
  type: GET_AVAILABLE_REQUEST,
  payload
});

export const start = payload => ({
  type: UPLOAD_RESERVATION_START,
  payload
});

export const upload = payload => ({
  type: UPLOAD_RESERVATION_REQUEST,
  payload
});
