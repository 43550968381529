import {
  USER_LOGIN_REQUEST,
  GET_USER_REQUEST,
  USER_LOGOUT_REQUEST
} from "../../consts/actionTypes";

export const login = payload => ({
  type: USER_LOGIN_REQUEST,
  payload
});

export const loginWithToken = payload => ({
  type: GET_USER_REQUEST,
  payload
});

export const logout = payload => ({
  type: USER_LOGOUT_REQUEST,
  payload
});
