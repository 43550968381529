import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_AVAILABLE_REQUEST,
  GET_AVAILABLE_SUCCESS,
  GET_AVAILABLE_FAILURE,
  UPLOAD_RESERVATION_REQUEST,
  UPLOAD_RESERVATION_SUCCESS,
  UPLOAD_RESERVATION_FAILURE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* getAvailables({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "disponibilidad",
      {
        hotel_id: payload.hotel_id,
        desde: payload.desde,
        hasta: payload.hasta
      },
      payload.header,
      "POST"
    );
    yield put({ type: GET_AVAILABLE_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_AVAILABLE_FAILURE, error });
  }
}

export function* uploadReservation({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion",
      payload.reservation,
      payload.header,
      "POST"
    );
    yield put({ type: UPLOAD_RESERVATION_SUCCESS, results });
  } catch (error) {
    yield put({ type: UPLOAD_RESERVATION_FAILURE, error });
  }
}

export default function* nuevaReservacion() {
  yield takeLatest(GET_AVAILABLE_REQUEST, getAvailables);
  yield takeLatest(UPLOAD_RESERVATION_REQUEST, uploadReservation);
}
