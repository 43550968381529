import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_ACCOUNTS_REQUEST,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
  LOAD_ACCOUNTS_REQUEST,
  LOAD_ACCOUNTS_SUCCESS,
  LOAD_ACCOUNTS_FAILURE,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  ADD_ACCOUNT_REQUEST,
  ADD_ACCOUNT_SUCCESS,
  ADD_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* getAccounts({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "cuentas?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_ACCOUNTS_SUCCESS, results, search: payload.search });
  } catch (error) {
    yield put({ type: GET_ACCOUNTS_FAILURE, error });
  }
}

export function* loadAccounts({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "cuentas?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: LOAD_ACCOUNTS_SUCCESS, results });
  } catch (error) {
    yield put({ type: LOAD_ACCOUNTS_FAILURE, error });
  }
}

export function* updateAccount({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "cuentas/" + payload.id,
      payload.user,
      payload.header,
      "PUT"
    );
    yield put({ type: UPDATE_ACCOUNT_SUCCESS, results });
  } catch (error) {
    yield put({ type: UPDATE_ACCOUNT_FAILURE, error });
  }
}

export function* updatePassword({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "password/" + payload.id,
      payload.user,
      payload.header,
      "PUT"
    );
    yield put({ type: UPDATE_PASSWORD_SUCCESS, results });
  } catch (error) {
    yield put({ type: UPDATE_PASSWORD_FAILURE, error });
  }
}

export function* addAccount({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "cuentas",
      payload.user,
      payload.header,
      "POST"
    );
    yield put({ type: ADD_ACCOUNT_SUCCESS, results });
  } catch (error) {
    yield put({ type: ADD_ACCOUNT_FAILURE, error });
  }
}

export function* deleteAccount({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "cuentas/" + payload.id,
      null,
      payload.header,
      "DELETE"
    );
    yield put({ type: DELETE_ACCOUNT_SUCCESS, results });
  } catch (error) {
    yield put({ type: DELETE_ACCOUNT_FAILURE, error });
  }
}

export default function* usuarios() {
  yield takeLatest(GET_ACCOUNTS_REQUEST, getAccounts);
  yield takeLatest(LOAD_ACCOUNTS_REQUEST, loadAccounts);
  yield takeLatest(UPDATE_ACCOUNT_REQUEST, updateAccount);
  yield takeLatest(UPDATE_PASSWORD_REQUEST, updatePassword);
  yield takeLatest(ADD_ACCOUNT_REQUEST, addAccount);
  yield takeLatest(DELETE_ACCOUNT_REQUEST, deleteAccount);
}
