import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE,
  LOAD_SERVICES_REQUEST,
  LOAD_SERVICES_SUCCESS,
  LOAD_SERVICES_FAILURE,
  GET_SERVICE_BY_ID_REQUEST,
  GET_SERVICE_BY_ID_SUCCESS,
  GET_SERVICE_BY_ID_FAILURE,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAILURE,
  ADD_SERVICE_REQUEST,
  ADD_SERVICE_SUCCESS,
  ADD_SERVICE_FAILURE,
  DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAILURE,
  GET_ALL_SERVICES_REQUEST,
  GET_ALL_SERVICES_SUCCESS,
  GET_ALL_SERVICES_FAILURE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* getServices({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "servicios?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&orderBy=" +
        payload.orderBy +
        "&order=" +
        payload.order +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_SERVICES_SUCCESS, results, search: payload.search });
  } catch (error) {
    yield put({ type: GET_SERVICES_FAILURE, error });
  }
}

export function* loadServices({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "servicios?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&orderBy=" +
        payload.orderBy +
        "&order=" +
        payload.order +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: LOAD_SERVICES_SUCCESS, results });
  } catch (error) {
    yield put({ type: LOAD_SERVICES_FAILURE, error });
  }
}

export function* getById({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "servicios/" + payload.id,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_SERVICE_BY_ID_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_SERVICE_BY_ID_FAILURE, error });
  }
}

export function* updateService({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "servicios/" + payload.id,
      payload.service,
      payload.header,
      "PUT"
    );
    yield put({ type: UPDATE_SERVICE_SUCCESS, results });
  } catch (error) {
    yield put({ type: UPDATE_SERVICE_FAILURE, error });
  }
}

export function* addService({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "servicios",
      payload.service,
      payload.header,
      "POST"
    );
    yield put({ type: ADD_SERVICE_SUCCESS, results });
  } catch (error) {
    yield put({ type: ADD_SERVICE_FAILURE, error });
  }
}

export function* deleteService({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "servicios/" + payload.id,
      null,
      payload.header,
      "DELETE"
    );
    yield put({ type: DELETE_SERVICE_SUCCESS, results });
  } catch (error) {
    yield put({ type: DELETE_SERVICE_FAILURE, error });
  }
}

export function* getAllServices({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "servicios_list/" + payload.hotel_id,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_ALL_SERVICES_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_ALL_SERVICES_FAILURE, error });
  }
}

export default function* servicios() {
  yield takeLatest(GET_SERVICES_REQUEST, getServices);
  yield takeLatest(LOAD_SERVICES_REQUEST, loadServices);
  yield takeLatest(GET_SERVICE_BY_ID_REQUEST, getById);
  yield takeLatest(UPDATE_SERVICE_REQUEST, updateService);
  yield takeLatest(ADD_SERVICE_REQUEST, addService);
  yield takeLatest(DELETE_SERVICE_REQUEST, deleteService);
  yield takeLatest(GET_ALL_SERVICES_REQUEST, getAllServices);
}
