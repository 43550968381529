import {
  GET_ROOMTYPES_REQUEST,
  LOAD_ROOMTYPES_REQUEST,
  GET_ROOMTYPE_BY_ID_REQUEST,
  UPDATE_ROOMTYPE_REQUEST,
  ADD_ROOMTYPE_REQUEST,
  DELETE_ROOMTYPE_REQUEST,
  GET_ROOMTYPES_FOR_SELECT_REQUEST,
  UPLOAD_ROOMTYPE_IMAGE_REQUEST,
  UPLOAD_ROOMTYPE_IMAGE_FINISH,
  ADD_GALLERY_IMAGE_REQUEST
} from "../../consts/actionTypes";

export const get = payload => ({
  type: GET_ROOMTYPES_REQUEST,
  payload
});

export const load = payload => ({
  type: LOAD_ROOMTYPES_REQUEST,
  payload
});

export const getById = payload => ({
  type: GET_ROOMTYPE_BY_ID_REQUEST,
  payload
});

export const update = payload => ({
  type: UPDATE_ROOMTYPE_REQUEST,
  payload
});

export const add = payload => ({
  type: ADD_ROOMTYPE_REQUEST,
  payload
});

export const del = payload => ({
  type: DELETE_ROOMTYPE_REQUEST,
  payload
});

export const getSelect = payload => ({
  type: GET_ROOMTYPES_FOR_SELECT_REQUEST,
  payload
});

export const upload = payload => ({
  type: UPLOAD_ROOMTYPE_IMAGE_REQUEST,
  payload
});

export const finish = payload => ({
  type: UPLOAD_ROOMTYPE_IMAGE_FINISH,
  payload
});

export const addImage = payload => ({
  type: ADD_GALLERY_IMAGE_REQUEST,
  payload
});
