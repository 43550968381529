import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_RES_INFO_REQUEST,
  GET_RES_INFO_SUCCESS,
  GET_RES_INFO_FAILURE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* getReservation({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/" + payload.code,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_RES_INFO_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_RES_INFO_FAILURE, error });
  }
}

export default function* contrato() {
  yield takeLatest(GET_RES_INFO_REQUEST, getReservation);
}
