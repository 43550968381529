import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { updateComment } from "../../redux/actions/reservaciones";

import styles from "./style";

function Notas({ reservacion_id, comentarios }) {
  const classes = styles();
  const dispatch = useDispatch();
  const isUpdatingComment = useSelector(
    state => state.reservaciones.isUpdatingComment
  );

  const [edit, setEdit] = React.useState(false);
  const [coment, setComentarios] = React.useState(comentarios || "");

  const handleSubmit = () => {
    dispatch(
      updateComment({
        id: reservacion_id,
        comentarios: coment,
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
  };

  return (
    <div className={classes.nested}>
      <div className={classes.verticalSpacing} />
      <TextField
        value={coment}
        onChange={e => {
          setEdit(true);
          setComentarios(e.target.value);
        }}
        multiline
        rows={5}
        fullWidth
        variant="outlined"
        style={{ backgroundColor: "#fff" }}
      />
      <div className={classes.verticalSpacing} />
      <div style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          disabled={!edit || isUpdatingComment}
          onClick={() => handleSubmit()}
        >
          Guardar{" "}
          {isUpdatingComment && <CircularProgress size={12} style={{ marginLeft: 4 }} />}
        </Button>
      </div>
      <div className={classes.verticalSpacing} />
    </div>
  );
}

Notas.propTypes = {
  reservacion_id: PropTypes.number.isRequired,
  comentarios: PropTypes.string
};

export default Notas;
