import {
  GET_PRODUCTS_REQUEST,
  LOAD_PRODUCTS_REQUEST,
  GET_PRODUCT_BY_ID_REQUEST,
  UPDATE_PRODUCT_REQUEST,
  ADD_PRODUCT_REQUEST,
  DELETE_PRODUCT_REQUEST,
  GET_ALL_PRODUCTS_REQUEST
} from "../../consts/actionTypes";

export const get = payload => ({
  type: GET_PRODUCTS_REQUEST,
  payload
});

export const load = payload => ({
  type: LOAD_PRODUCTS_REQUEST,
  payload
});

export const getById = payload => ({
  type: GET_PRODUCT_BY_ID_REQUEST,
  payload
});

export const update = payload => ({
  type: UPDATE_PRODUCT_REQUEST,
  payload
});

export const add = payload => ({
  type: ADD_PRODUCT_REQUEST,
  payload
});

export const del = payload => ({
  type: DELETE_PRODUCT_REQUEST,
  payload
});

export const getAll = payload => ({
  type: GET_ALL_PRODUCTS_REQUEST,
  payload
});
