import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getAll } from "../../../redux/actions/servicios";
import { addService } from "../../../redux/actions/reservaciones";
import { moneyFormat } from "../../../functions";

function Servicios({ open, handleClose, reservacion_id }) {
  const dispatch = useDispatch();
  const inputRef = React.useRef(null);

  const hotel_id = useSelector(state => state.user.hotel_id);
  const servicios = useSelector(state => state.servicios.select);
  const isFetching = useSelector(state => state.servicios.isFetching);
  const isUpdating = useSelector(state => state.reservaciones.isUpdating);

  const [servicio, setServicio] = React.useState(-1);
  const [upload, setUpload] = React.useState(false);

  React.useEffect(() => {
    if (!open && (servicio !== -1 || upload)) {
      setServicio(-1);
      setUpload(false);
    }
  }, [open, servicio, upload]);

  React.useEffect(() => {
    if (upload && !isUpdating) {
      handleClose();
    }
  });

  React.useEffect(() => {
    if (!servicios && !isFetching) {
      dispatch(
        getAll({
          hotel_id: hotel_id,
          header: {
            Authorization:
              localStorage.getItem("token_type") +
              " " +
              localStorage.getItem("access_token")
          }
        })
      );
    }
  });

  const handleSubmit = e => {
    e.preventDefault();
    if (servicio === -1) {
      alert("Selecciona un servicio.");
      return;
    }
    dispatch(
      addService({
        reservacion_id: reservacion_id,
        servicio_id: servicio,
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    setUpload(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"xs"}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Agregar un servicio.</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} autoComplete="off">
          <TextField
            select
            value={servicio}
            onChange={e => setServicio(e.target.value)}
            label="Producto"
            variant="outlined"
            fullWidth
            required
          >
            <MenuItem value={-1} disabled>
              {isFetching ? "Cargando..." : "Selecciona un servicio"}
            </MenuItem>
            {servicios &&
              servicios.map(serv => (
                <MenuItem key={serv.id} value={serv.id}>
                  {serv.nombre.es + " / " + moneyFormat(serv.precio)}
                </MenuItem>
              ))}
          </TextField>
          <button hidden type="submit" ref={inputRef} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
        <Button
          onClick={() => inputRef.current.click()}
          color="primary"
          disabled={isUpdating}
          endIcon={isUpdating && <CircularProgress size={12} />}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Servicios.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reservacion_id: PropTypes.number
};

export default Servicios;
