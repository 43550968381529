import React from "react";
import { Provider } from "react-redux";
import PropTypes from "prop-types";

import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "./consts/theme";

import moment from "moment";
import "moment/locale/es";

import Router from "./Router";

function App({ store }) {
  moment.updateLocale("es", {
    months : 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    weekdays : 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sábado'.split('_'),
  });

  return (
    <>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <Router />
        </Provider>
      </MuiThemeProvider>
    </>
  );
}

App.propTypes = {
  store: PropTypes.object.isRequired
};

export default App;
