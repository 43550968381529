import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_PENDING_RESERVATION_REQUEST,
  GET_PENDING_RESERVATION_SUCCESS,
  GET_PENDING_RESERVATION_FAILURE,
  LOAD_PENDING_RESERVATION_REQUEST,
  LOAD_PENDING_RESERVATION_SUCCESS,
  LOAD_PENDING_RESERVATION_FAILURE,
  GET_PENDING_RESERVATION_BY_ID_REQUEST,
  GET_PENDING_RESERVATION_BY_ID_SUCCESS,
  GET_PENDING_RESERVATION_BY_ID_FAILURE,
  CONFIRM_RESERVATION_REQUEST,
  CONFIRM_RESERVATION_SUCCESS,
  CONFIRM_RESERVATION_FAILURE,
  CANCEL_PENDING_RESERVATION_REQUEST,
  CANCEL_PENDING_RESERVATION_SUCCESS,
  CANCEL_PENDING_RESERVATION_FAILURE,
  NUEVO_MENSAJE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* getReservations({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "pendientes?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({
      type: GET_PENDING_RESERVATION_SUCCESS,
      results,
      search: payload.search
    });
  } catch (error) {
    yield put({ type: GET_PENDING_RESERVATION_FAILURE, error });
  }
}

export function* loadReservations({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "pendientes?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: LOAD_PENDING_RESERVATION_SUCCESS, results });
  } catch (error) {
    yield put({ type: LOAD_PENDING_RESERVATION_FAILURE, error });
  }
}

export function* getById({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/" + payload.code,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_PENDING_RESERVATION_BY_ID_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_PENDING_RESERVATION_BY_ID_FAILURE, error });
  }
}

export function* confirm({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "pendientes/confirmar",
      {
        reservacion_id: payload.reservacion_id
      },
      payload.header,
      "POST"
    );
    yield put({ type: CONFIRM_RESERVATION_SUCCESS, results });
    yield put({
      type: NUEVO_MENSAJE,
      mensaje: "Reservación confirmada.",
      tipo: "success"
    });
  } catch (error) {
    yield put({ type: CONFIRM_RESERVATION_FAILURE, error });
  }
}

export function* cancel({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/cancelar",
      {
        reservacion_id: payload.reservacion_id
      },
      payload.header,
      "POST"
    );
    yield put({ type: CANCEL_PENDING_RESERVATION_SUCCESS, results });
    yield put({
      type: NUEVO_MENSAJE,
      mensaje: "Reservación cancelada.",
      tipo: "success"
    });
  } catch (error) {
    yield put({ type: CANCEL_PENDING_RESERVATION_FAILURE, error });
  }
}

export default function* pendientes() {
  yield takeLatest(GET_PENDING_RESERVATION_REQUEST, getReservations);
  yield takeLatest(LOAD_PENDING_RESERVATION_REQUEST, loadReservations);
  yield takeLatest(GET_PENDING_RESERVATION_BY_ID_REQUEST, getById);
  yield takeLatest(CONFIRM_RESERVATION_REQUEST, confirm);
  yield takeLatest(CANCEL_PENDING_RESERVATION_REQUEST, cancel);
}
