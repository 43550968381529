import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";

import { add } from "../../redux/actions/cuartos";

import styles from "./style";
import RoomStateSelect from "../../components/RoomStateSelect";
import RoomTypesSelect from "../../components/RoomTypesSelect";

export default function Formulario() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const hotel_id = useSelector(state => state.user.hotel_id);
  const isUploading = useSelector(state => state.cuartos.isUploading);

  const [tipo_de_cuarto, setTipoCuarto] = React.useState(1);
  const [numero, setNumero] = React.useState("");
  const [estado, setEstado] = React.useState(1);
  const [piso, setPiso] = React.useState(1);
  const [activo, setActivo] = React.useState(true);
  const [descripcionEs, setDescripcionEs] = React.useState("");
  const [descripcionEn, setDescripcionEn] = React.useState("");
  const [isCompleted, setIsCompleted] = React.useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(
      add({
        room: {
          hotel_id: hotel_id,
          piso: piso,
          tipo_de_cuarto: tipo_de_cuarto,
          numero: numero,
          activo: activo,
          estado: estado,
          descripcion: {
            es: descripcionEs,
            en: descripcionEn
          }
        },
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    setIsCompleted(true);
  };

  React.useEffect(() => {
    if (!isUploading && isCompleted) {
      history.push("/cuartos");
    }
  }, [isCompleted, isUploading, history]);

  if (isUploading) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={64} />
        <Typography variant="subtitle1">Enviando informacón</Typography>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Tipo de cuarto{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <RoomTypesSelect
            value={tipo_de_cuarto}
            handleChange={value => setTipoCuarto(value)}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Número{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <TextField
            id="input-numero"
            autoFocus
            fullWidth
            value={numero}
            onChange={({ target }) => setNumero(target.value)}
            variant="outlined"
            margin="dense"
            required
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Piso{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <TextField
            id="input-piso"
            autoFocus
            fullWidth
            value={piso}
            onChange={({ target }) => setPiso(target.value)}
            variant="outlined"
            margin="dense"
            InputProps={{ type: "number" }}
            required
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Estado{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <RoomStateSelect
            value={estado}
            handleChange={value => setEstado(value)}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Reservar en web{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <Switch
            checked={activo}
            onChange={event => setActivo(event.target.checked)}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">Descripción (español)</Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <TextField
            id="input-descripcion-es"
            fullWidth
            value={descripcionEs}
            onChange={({ target }) => setDescripcionEs(target.value)}
            variant="outlined"
            margin="dense"
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">Descripción (ingles)</Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <TextField
            id="input-descripcion-en"
            fullWidth
            value={descripcionEn}
            onChange={({ target }) => setDescripcionEn(target.value)}
            variant="outlined"
            margin="dense"
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
      <div className={classes.verticalSpacing} />
      <div className={classes.actions}>
        <Button variant="contained" color="secondary" type="submit">
          Guardar
        </Button>
      </div>
    </form>
  );
}
