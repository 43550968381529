import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import makeStyles from "@material-ui/styles/makeStyles";

import routes from "./consts/routes";
import AppBar from "./components/AppBar";
import Login from "./pages/Login";
import Inicio from "./pages/Inicio";
import TiposCuarto from "./pages/TiposCuarto";
import TipoCuartoDetalle from "./pages/TipoCuartoDetalle";
import TipoCuartoNuevo from "./pages/TipoCuartoNuevo";
import Cuartos from "./pages/Cuartos";
import CuartoDetalle from "./pages/CuartoDetalle";
import CuartoNuevo from "./pages/CuartoNuevo";
import Tarifas from "./pages/Tarifas";
import TarifaDetalle from "./pages/TarifaDetalle";
import TarifaNueva from "./pages/TarifaNueva";
import Servicio from "./pages/Servicios";
import ServicioDetalle from "./pages/ServicioDetalle";
import ServicioNuevo from "./pages/ServicioNuevo";
import Productos from "./pages/Productos";
import ProductoDetalle from "./pages/ProductoDetalle";
import ProductoNuevo from "./pages/ProductoNuevo";
import Mensajes from "./pages/Mensajes";
import Usuarios from "./pages/Usuarios";
import UsuariosNuevo from "./pages/UsuariosNuevo";
import NuevaReservacion from "./pages/NuevaReservacion";
import Reservaciones from "./pages/Reservaciones";
import Contrato from "./pages/Contrato";
import Historial from "./pages/Historial";
import Pendietes from "./pages/Pendientes";

import { limpiar } from "./redux/actions/snackbar";
import SnackBar from "./components/Mensajes";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.common.white,
    minHeight: "100vh"
  },
  content: {
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(7),
    paddingTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(1),
      width: `calc(100% - ${theme.spacing(7) + 1}px)`
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  }
}));

export default function MyRouter() {
  const dispatch = useDispatch();
  const mensaje = useSelector(state => state.snackbar.mensaje);
  const tipo = useSelector(state => state.snackbar.tipo);
  const loggedIn = useSelector(state => state.user.loggedIn);
  const classes = useStyles();

  if (!loggedIn) {
    return <Login />;
  }

  return (
    <Router>
      <div className={classes.root}>
        <AppBar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Route exact path={routes.main} component={Inicio} />
          <Route exact path={routes.tiposCuarto} component={TiposCuarto} />
          <Route
            exact
            path={routes.tipoCuartoDetalle}
            component={TipoCuartoDetalle}
          />
          <Route
            exact
            path={routes.tipoCuartoNuevo}
            component={TipoCuartoNuevo}
          />
          <Route exact path={routes.cuartos} component={Cuartos} />
          <Route exact path={routes.cuartoDetalle} component={CuartoDetalle} />
          <Route exact path={routes.cuartoNuevo} component={CuartoNuevo} />
          <Route exact path={routes.tarifas} component={Tarifas} />
          <Route exact path={routes.tarifasDetalle} component={TarifaDetalle} />
          <Route exact path={routes.tarifasNueva} component={TarifaNueva} />
          <Route exact path={routes.servicios} component={Servicio} />
          <Route exact path={routes.servicioNuevo} component={ServicioNuevo} />
          <Route
            exact
            path={routes.servicioDetalle}
            component={ServicioDetalle}
          />
          <Route exact path={routes.productos} component={Productos} />
          <Route
            exact
            path={routes.productoDetalle}
            component={ProductoDetalle}
          />
          <Route exact path={routes.productoNuevo} component={ProductoNuevo} />
          <Route exact path={routes.mensajes} component={Mensajes} />
          <Route exact path={routes.usuarios} component={Usuarios} />
          <Route exact path={routes.usuarioNuevo} component={UsuariosNuevo} />
          <Route exact path={routes.nueva} component={NuevaReservacion} />
          <Route exact path={routes.reservaciones} component={Reservaciones} />
          <Route exact path={routes.contrato} component={Contrato} />
          <Route exact path={routes.historial} component={Historial} />
          <Route exact path={routes.pendientes} component={Pendietes} />
        </main>
        <SnackBar
          open={Boolean(mensaje)}
          handleClose={() => dispatch(limpiar())}
          message={mensaje}
          variant={tipo}
        />
      </div>
    </Router>
  );
}
