import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { checkOut } from "../../redux/actions/reservaciones";

function CheckOut({ open, handleClose, reservacion_id }) {
  const dispatch = useDispatch();
  const isUpdating = useSelector(state => state.reservaciones.isUpdating);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle id="checkout-dialog-title">
        Confirmar la salida del huesped.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Al confirmar los cuartos reservados seran liberados y la reservacion
          pasara al registro historico, asegurese de que los pagos han sido
          completados correctamente.
        </DialogContentText>
        <DialogContentText>
          Los datos de la tarjeta del cliente seran eliminados al momento de
          confirmar la salida del huesped.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
        <Button
          color="primary"
          disabled={isUpdating}
          onClick={() =>
            dispatch(
              checkOut({
                reservacion_id: reservacion_id,
                header: {
                  Authorization:
                    localStorage.getItem("token_type") +
                    " " +
                    localStorage.getItem("access_token")
                }
              })
            )
          }
          endIcon={isUpdating && <CircularProgress size={12} />}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CheckOut.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reservacion_id: PropTypes.number
};

export default CheckOut;
