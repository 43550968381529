import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_ROOMS_REQUEST,
  GET_ROOMS_SUCCESS,
  GET_ROOMS_FAILURE,
  LOAD_ROOMS_REQUEST,
  LOAD_ROOMS_SUCCESS,
  LOAD_ROOMS_FAILURE,
  GET_ROOM_BY_ID_REQUEST,
  GET_ROOM_BY_ID_SUCCESS,
  GET_ROOM_BY_ID_FAILURE,
  UPDATE_ROOM_REQUEST,
  UPDATE_ROOM_SUCCESS,
  UPDATE_ROOM_FAILURE,
  ADD_ROOM_REQUEST,
  ADD_ROOM_SUCCESS,
  ADD_ROOM_FAILURE,
  DELETE_ROOM_REQUEST,
  DELETE_ROOM_SUCCESS,
  DELETE_ROOM_FAILURE,
  ADD_CLOSE_DATE_REQUEST,
  ADD_CLOSE_DATE_SUCCESS,
  ADD_CLOSE_DATE_FAILURE,
  REMOVE_CLOSE_DATE_REQUEST,
  REMOVE_CLOSE_DATE_SUCCESS,
  REMOVE_CLOSE_DATE_FAILURE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* getRooms({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "cuarto?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&orderBy=" +
        payload.orderBy +
        "&order=" +
        payload.order +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_ROOMS_SUCCESS, results, search: payload.search });
  } catch (error) {
    yield put({ type: GET_ROOMS_FAILURE, error });
  }
}

export function* loadRooms({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "cuarto?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&orderBy=" +
        payload.orderBy +
        "&order=" +
        payload.order +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: LOAD_ROOMS_SUCCESS, results });
  } catch (error) {
    yield put({ type: LOAD_ROOMS_FAILURE, error });
  }
}

export function* getById({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "cuarto/" + payload.id,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_ROOM_BY_ID_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_ROOM_BY_ID_FAILURE, error });
  }
}

export function* updateRoom({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "cuarto/" + payload.id,
      payload.room,
      payload.header,
      "PUT"
    );
    yield put({ type: UPDATE_ROOM_SUCCESS, results });
  } catch (error) {
    yield put({ type: UPDATE_ROOM_FAILURE, error });
  }
}

export function* addRoom({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "cuarto",
      payload.room,
      payload.header,
      "POST"
    );
    yield put({ type: ADD_ROOM_SUCCESS, results });
  } catch (error) {
    yield put({ type: ADD_ROOM_FAILURE, error });
  }
}

export function* deleteRoom({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "cuarto/" + payload.id,
      null,
      payload.header,
      "DELETE"
    );
    yield put({ type: DELETE_ROOM_SUCCESS, results });
  } catch (error) {
    yield put({ type: DELETE_ROOM_FAILURE, error });
  }
}

export function* addDate({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "fecha_cerrada",
      payload.closeDate,
      payload.header,
      "POST"
    );
    yield put({ type: ADD_CLOSE_DATE_SUCCESS, results });
  } catch (error) {
    yield put({ type: ADD_CLOSE_DATE_FAILURE, error });
  }
}

export function* removeDate({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "fecha_cerrada/" + payload.id,
      null,
      payload.header,
      "DELETE"
    );
    yield put({ type: REMOVE_CLOSE_DATE_SUCCESS, results });
  } catch (error) {
    yield put({ type: REMOVE_CLOSE_DATE_FAILURE, error });
  }
}

export default function* cuartos() {
  yield takeLatest(GET_ROOMS_REQUEST, getRooms);
  yield takeLatest(LOAD_ROOMS_REQUEST, loadRooms);
  yield takeLatest(GET_ROOM_BY_ID_REQUEST, getById);
  yield takeLatest(UPDATE_ROOM_REQUEST, updateRoom);
  yield takeLatest(ADD_ROOM_REQUEST, addRoom);
  yield takeLatest(DELETE_ROOM_REQUEST, deleteRoom);
  yield takeLatest(ADD_CLOSE_DATE_REQUEST, addDate);
  yield takeLatest(REMOVE_CLOSE_DATE_REQUEST, removeDate);
}
