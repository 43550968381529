import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_ROOMSTATES_REQUEST,
  GET_ROOMSTATES_SUCCESS,
  GET_ROOMSTATES_FAILURE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* getStates({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "estados_cuarto",
      null,
      payload.header,
      "GET"
    );
    yield put({
      type: GET_ROOMSTATES_SUCCESS,
      results,
      search: payload.search
    });
  } catch (error) {
    yield put({ type: GET_ROOMSTATES_FAILURE, error });
  }
}
export default function* estadosCuarto() {
  yield takeLatest(GET_ROOMSTATES_REQUEST, getStates);
}
