import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles(theme => ({
  verticalSpacing: {
    height: theme.spacing(2)
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end"
  },
  paper: {
    padding: "16px 8px 16px 16px"
  },
  loading: {
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));
