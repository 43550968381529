import { combineReducers } from "redux";
import user from "./user";
import tiposCuarto from "./tiposCuarto";
import cuartos from "./cuartos";
import estadosCuarto from "./estadosCuarto";
import tarifas from "./tarifas";
import servicios from "./servicios";
import productos from "./productos";
import mensajes from "./mensajes";
import usuarios from "./usuarios";
import roles from "./roles";
import nuevaReservacion from "./nuevaReservacion";
import reservaciones from "./reservaciones";
import snackbar from "./snackbar";
import contrato from "./contrato";
import historial from "./historial";
import tiposPago from "./tiposPago";
import pendientes from "./pendientes";

const rootReducer = combineReducers({
  user,
  tiposCuarto,
  cuartos,
  estadosCuarto,
  tarifas,
  servicios,
  productos,
  mensajes,
  usuarios,
  roles,
  nuevaReservacion,
  reservaciones,
  snackbar,
  contrato,
  historial,
  tiposPago,
  pendientes
});

export default rootReducer;
