import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import { add } from "../../redux/actions/usuarios";

import styles from "./style";
import RolesSelect from "../../components/RolesSelect";

export default function Formulario() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const hotel_id = useSelector(state => state.user.hotel_id);
  const isUploading = useSelector(state => state.usuarios.isUploading);
  const error = useSelector(state => state.usuarios.error)

  const [nombre, setNombre] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [telefono, setTelefono] = React.useState("");
  const [rol, setRol] = React.useState(0);
  const [password, setPassword] = React.useState("");
  const [password_confirmation, setPasswordConfirmation] = React.useState("");
  const [isCompleted, setIsCompleted] = React.useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    if(password !== password_confirmation) {
      alert("Las contraseñas no coinciden.")
      return
    }
    if(rol === 0) {
      alert("Selecciones el rol de usuario.")
      return
    }
    dispatch(
      add({
        user: {
          hotel_id: hotel_id,
          nombre: nombre,
          email: email,
          password: password,
          password_confirmation: password_confirmation,
          telefono: telefono,
          rol: rol
        },
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    setIsCompleted(true);
  };

  React.useEffect(() => {
    if (!error && !isUploading && isCompleted) {
      history.push("/usuarios");
    }
  }, [isCompleted, isUploading, history, error]);

  if (isUploading) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={64} />
        <Typography variant="subtitle1">Enviando informacón</Typography>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <Typography variant="body1">
                Nombre completo{" "}
                <Typography color="error" component="span">
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              <TextField
                id="input-nombre"
                autoFocus
                fullWidth
                value={nombre}
                onChange={({ target }) => setNombre(target.value)}
                variant="outlined"
                margin="dense"
                inputProps={{ maxLength: 255 }}
                required
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <Typography variant="body1">
                Rol{" "}
                <Typography color="error" component="span">
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              <RolesSelect value={rol} handleChange={value => setRol(value)} />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <Typography variant="body1">
                Teléfono{" "}
                <Typography color="error" component="span">
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              <TextField
                id="input-phone"
                fullWidth
                value={telefono}
                onChange={({ target }) => setTelefono(target.value)}
                variant="outlined"
                margin="dense"
                inputProps={{ type: "number", max: 9999999999 }}
                required
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <Typography variant="body1">
                Email{" "}
                <Typography color="error" component="span">
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              <TextField
                id="input-email"
                fullWidth
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                variant="outlined"
                margin="dense"
                inputProps={{ type: "email" }}
                required
                error={Boolean(error && error.response.data.errors.email)}
                helperText={error && error.response.data.errors.email? "Ya existe una cuenta con este correo.":""}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <Typography variant="body1">
                Contraseña{" "}
                <Typography color="error" component="span">
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              <TextField
                id="input-password"
                fullWidth
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                variant="outlined"
                margin="dense"
                inputProps={{ type: "password", maxLength: 255 }}
                required
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <Typography variant="body1">
                Confirmar contraseña{" "}
                <Typography color="error" component="span">
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              <TextField
                id="input-password2"
                fullWidth
                value={password_confirmation}
                onChange={({ target }) => setPasswordConfirmation(target.value)}
                variant="outlined"
                margin="dense"
                inputProps={{ type: "password", maxLength: 255 }}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.verticalSpacing} />
      <div className={classes.actions}>
        <Button variant="contained" color="secondary" type="submit">
          Guardar
        </Button>
      </div>
    </form>
  );
}
