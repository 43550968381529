import React from "react";
import { useHistory } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

import styles from "./style";
import Formulario from './Formulario'

function CuartoNuevo() {
  const classes = styles();
  const history = useHistory();

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          color="primary"
          style={{ cursor: "pointer" }}
          onClick={() => history.goBack()}
        >
          Habitaciones
        </Link>
        <Typography color="textPrimary">Nueva</Typography>
      </Breadcrumbs>
      <div className={classes.verticalSpacing} />
      <Paper className={classes.paper}>
        <Typography variant="h6">Nueva habitación.</Typography>
        <div className={classes.verticalSpacing} />
        <Formulario />
      </Paper>
    </>
  );
}

export default CuartoNuevo;
