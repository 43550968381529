import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { deleteCard } from "../../redux/actions/reservaciones";

function DeleteCard({ open, handleClose, tarjeta }) {
  const dispatch = useDispatch();
  const isUpdating = useSelector(state => state.reservaciones.isUpdating);
  const [isCompleted, setIsCompleted] = React.useState(false);

  React.useEffect(() => {
    if (!isUpdating && isCompleted) {
      handleClose();
    }
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Eliminar método de pago.</DialogTitle>
      <DialogContent>
        <DialogContentText>¿Desea elimar el método de pago?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
        <Button
          color="primary"
          disabled={isUpdating}
          onClick={() => {
            dispatch(
              deleteCard({
                id: tarjeta,
                header: {
                  Authorization:
                    localStorage.getItem("token_type") +
                    " " +
                    localStorage.getItem("access_token")
                }
              })
            );
            setIsCompleted(true);
          }}
          endIcon={isUpdating && <CircularProgress size={16} />}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteCard.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  tarjeta: PropTypes.number
};

export default DeleteCard;
