import {
  GET_RATES_REQUEST,
  GET_RATES_SUCCESS,
  GET_RATES_FAILURE,
  LOAD_RATES_REQUEST,
  LOAD_RATES_SUCCESS,
  LOAD_RATES_FAILURE,
  GET_RATE_BY_ID_REQUEST,
  GET_RATE_BY_ID_SUCCESS,
  GET_RATE_BY_ID_FAILURE,
  UPDATE_RATE_REQUEST,
  UPDATE_RATE_SUCCESS,
  UPDATE_RATE_FAILURE,
  ADD_RATE_REQUEST,
  ADD_RATE_SUCCESS,
  ADD_RATE_FAILURE,
  DELETE_RATE_REQUEST,
  DELETE_RATE_SUCCESS,
  DELETE_RATE_FAILURE,
  USER_LOGOUT_SUCCESS
} from "../../consts/actionTypes";

const initialState = {
  lista: [],
  seleccionado: null,
  total: 1,
  actual: 0,
  search: "",
  isLoading: false,
  isLoadingMore: false,
  isUploading: false,
  isDeleting: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_RATES_REQUEST:
      return { ...state, isLoading: true };
    case GET_RATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lista: action.results.data.data,
        search: action.search,
        page: 1,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case GET_RATES_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case LOAD_RATES_REQUEST:
      return { ...state, isLoadingMore: true };
    case LOAD_RATES_SUCCESS:
      return {
        ...state,
        isLoadingMore: false,
        lista: state.lista.concat(action.results.data.data),
        page: action.results.data.current_page,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case LOAD_RATES_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoadingMore: false };
    case GET_RATE_BY_ID_REQUEST:
      return { ...state, isLoading: true };
    case GET_RATE_BY_ID_SUCCESS:
      return { ...state, isLoading: false, seleccionado: action.results.data };
    case GET_RATE_BY_ID_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case UPDATE_RATE_REQUEST:
      return { ...state, isLoading: true };
    case UPDATE_RATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        seleccionado: action.results.data,
        lista: state.lista.map(tarifa => {
          if (tarifa.id === action.results.data.id) {
            return action.results.data;
          }
          return tarifa;
        })
      };
    case UPDATE_RATE_FAILURE:
      return { ...state, error: action.error, isLoading: false };
    case ADD_RATE_REQUEST:
      return { ...state, isUploading: true };
    case ADD_RATE_SUCCESS:
      return {
        ...state,
        isUploading: false,
        lista: [action.results.data, ...state.lista]
      };
    case ADD_RATE_FAILURE:
      return { ...state, error: action.error, isUploading: false };
    case DELETE_RATE_REQUEST:
      return { ...state, isDeleting: true };
    case DELETE_RATE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        lastDeleted: action.results.data.id,
        lista: state.lista.filter(
          tarifa => tarifa.id.toString() !== action.results.data.id
        )
      };
    case DELETE_RATE_FAILURE:
      return { ...state, error: action.error, isDeleting: false };
    case USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return { ...state };
  }
}
