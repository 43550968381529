import React from "react";
import PropTypes from "prop-types";
// Material-UI
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import StepButton from "@material-ui/core/StepButton";
// Estilos
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
// Iconos
import DateRangeIcon from "@material-ui/icons/DateRange";
import HotelIcon from "@material-ui/icons/Hotel";
import PersonIcon from "@material-ui/icons/Person";
import CreditCardIcon from "@material-ui/icons/CreditCard";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 136deg, #81d4fa 0%, #03a9f4 50%, #0277bd 100%)"
    }
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 136deg, #80cbc4 0%, #009688 50%, #00695c 100%)"
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1
  }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, #81d4fa 0%, #03a9f4 50%, #0277bd 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, #80cbc4 0%, #009688 50%, #00695c 100%)"
  }
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <DateRangeIcon />,
    2: <HotelIcon />,
    3: <PersonIcon />,
    4: <CreditCardIcon />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

export default function MyStepper({ step, setStep }) {
  return (
    <Stepper
      alternativeLabel
      activeStep={step}
      connector={<ColorlibConnector />}
    >
      <Step completed={step > 0}>
        <StepButton onClick={() => setStep(0)}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>Fechas</StepLabel>
        </StepButton>
      </Step>
      <Step completed={step > 1}>
        <StepButton onClick={() => setStep(1)}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            Habitaciones
          </StepLabel>
        </StepButton>
      </Step>
      <Step completed={step > 2}>
        <StepButton onClick={() => setStep(2)}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>Datos</StepLabel>
        </StepButton>
      </Step>
      <Step completed={step > 3}>
        <StepButton onClick={() => setStep(3)}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>Pago</StepLabel>
        </StepButton>
      </Step>
    </Stepper>
  );
}

MyStepper.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired
};
