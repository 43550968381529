import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";

import SettingsIcon from "@material-ui/icons/Settings";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import BackspaceIcon from "@material-ui/icons/Backspace";

import {
  removeProduct,
  removePayment,
  removeService,
  removeExtra
} from "../../../redux/actions/reservaciones";

import { moneyFormat } from "../../../functions";
import styles from "./style";
import Pagos from "./Pagos";
import Productos from "./Productos";
import Servicios from "./Servicios";
import Ajustes from "./Ajustes";

function Cuenta({
  reservados,
  pagos,
  ajustes,
  productos,
  servicios,
  reservacion_id
}) {
  const classes = styles();
  const dispatch = useDispatch();

  const [openP, setOpenP] = React.useState(false);
  const [openPr, setOpenPr] = React.useState(false);
  const [openS, setOpenS] = React.useState(false);
  const [openA, setOpenA] = React.useState(false);

  const Total = () => {
    let t = 0;
    reservados.forEach(element => {
      t += parseFloat(element.precio);
    });
    ajustes.forEach(element => {
      t += parseFloat(element.valor);
    });
    productos.forEach(element => {
      t += parseFloat(element.precio);
    });
    servicios.forEach(element => {
      t += parseFloat(element.precio);
    });
    let a = t;
    pagos.forEach(element => {
      a -= parseFloat(element.valor);
    });
    return [moneyFormat(t), moneyFormat(a)];
  };

  const [total, adeudo] = Total();

  return (
    <div style={{ paddingLeft: 8, paddingRight: 4 }}>
      <Typography variant="h6" gutterBottom>
        Detalles de pagos
      </Typography>
      <div className={classes.chips}>
        <Chip
          icon={<MonetizationOnIcon style={{ color: "#fff" }} />}
          label="Registrar pago."
          onClick={() => setOpenP(true)}
          className={classes.botonPago}
        />
        <Chip
          icon={<AddShoppingCartIcon />}
          label="Productos."
          onClick={() => setOpenPr(true)}
        />
        <Chip
          icon={<RoomServiceIcon />}
          label="Servicios."
          onClick={() => setOpenS(true)}
        />
        <Chip
          icon={<SettingsIcon style={{ color: "#fff" }} />}
          label="Ajustes."
          onClick={() => setOpenA(true)}
          style={{ backgroundColor: "#353A4B", color: "#fff" }}
        />
      </div>
      <div className={classes.verticalSpacing} />
      <Table>
        <TableBody>
          {reservados.map((res, index) => (
            <TableRow key={index}>
              <TableCell>{res.cuarto.tipo_cuarto.nombre_hab.es}</TableCell>
              <TableCell className={classes.price}>
                {moneyFormat(res.precio)}
              </TableCell>
            </TableRow>
          ))}
          {servicios.map((serv, index) => (
            <TableRow key={index}>
              <TableCell>{serv.servicio.nombre.es}</TableCell>
              <TableCell className={classes.price}>
                {moneyFormat(serv.precio)}
                <IconButton
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={() =>
                    dispatch(
                      removeService({
                        id: serv.id,
                        header: {
                          Authorization:
                            localStorage.getItem("token_type") +
                            " " +
                            localStorage.getItem("access_token")
                        }
                      })
                    )
                  }
                >
                  <BackspaceIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {productos.map((comprado, index) => (
            <TableRow key={index}>
              <TableCell>{comprado.producto.nombre}</TableCell>
              <TableCell className={classes.price}>
                {moneyFormat(comprado.precio)}
                <IconButton
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={() =>
                    dispatch(
                      removeProduct({
                        id: comprado.id,
                        header: {
                          Authorization:
                            localStorage.getItem("token_type") +
                            " " +
                            localStorage.getItem("access_token")
                        }
                      })
                    )
                  }
                >
                  <BackspaceIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {ajustes.map((ajuste, index) => (
            <TableRow key={index}>
              <TableCell>{ajuste.descripcion}</TableCell>
              <TableCell className={classes.price}>
                {moneyFormat(ajuste.valor)}
                <IconButton
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={() =>
                    dispatch(
                      removeExtra({
                        id: ajuste.id,
                        header: {
                          Authorization:
                            localStorage.getItem("token_type") +
                            " " +
                            localStorage.getItem("access_token")
                        }
                      })
                    )
                  }
                >
                  <BackspaceIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell style={{ fontWeight: "600" }}>{total}</TableCell>
          </TableRow>
          {pagos.map(pago => (
            <TableRow key={pago.id}>
              <TableCell>{pago.concepto || "Pago"}</TableCell>
              <TableCell style={{ display: "flex" }}>
                {"- " + moneyFormat(pago.valor)}
                <div style={{ flexGrow: 1 }} />
                <IconButton
                  size="small"
                  onClick={() =>
                    dispatch(
                      removePayment({
                        id: pago.id,
                        header: {
                          Authorization:
                            localStorage.getItem("token_type") +
                            " " +
                            localStorage.getItem("access_token")
                        }
                      })
                    )
                  }
                >
                  <BackspaceIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell style={{ fontSize: "1.25em" }}>Adeudo</TableCell>
            <TableCell style={{ fontSize: "1.25em", fontWeight: "600" }}>
              {adeudo}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Pagos
        open={openP}
        handleClose={() => setOpenP(false)}
        reservacion_id={reservacion_id}
      />
      <Productos
        open={openPr}
        handleClose={() => setOpenPr(false)}
        reservacion_id={reservacion_id}
      />
      <Servicios
        open={openS}
        handleClose={() => setOpenS(false)}
        reservacion_id={reservacion_id}
      />
      <Ajustes
        open={openA}
        handleClose={() => setOpenA(false)}
        reservacion_id={reservacion_id}
      />
    </div>
  );
}

Cuenta.propTypes = {
  reservados: PropTypes.array.isRequired,
  pagos: PropTypes.array.isRequired,
  ajustes: PropTypes.array.isRequired,
  productos: PropTypes.array.isRequired,
  servicios: PropTypes.array.isRequired,
  reservacion_id: PropTypes.number.isRequired
};

export default Cuenta;
