import React from "react";
import PropTypes from "prop-types";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HotelIcon from "@material-ui/icons/Hotel";
import PersonIcon from "@material-ui/icons/Person";
import HelpIcon from "@material-ui/icons/Help";

import styles from "./style";
import { moneyFormat } from "../../functions";

function Habitacion({ habitacion, handleSelected, handleNext }) {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const max = habitacion.cuartos_count;

  const [selected, setSelected] = React.useState(
    new Array(habitacion.tarifas.length).fill(0)
  );
  const [openR, setOpenR] = React.useState(false);
  const [openNR, setOpenNR] = React.useState(false);

  const getHuespedes = (index, precio) => {
    const result = [];
    let c = 0;
    selected.forEach((item, i) => {
      if (i !== index) {
        c = c + item;
      }
    });
    for (let i = 0; i <= max; i++) {
      result.push(
        <MenuItem key={i} value={i} disabled={i + c > max}>
          {i} {i !== 0 && "- " + moneyFormat(precio * i)}
        </MenuItem>
      );
    }
    return result;
  };

  const handleHuespedes = (value, i, tarifa_id) => {
    setSelected(
      selected.map((item, index) => {
        if (i === index) {
          return value;
        }
        return item;
      })
    );
    const tarifa = habitacion.tarifas.find(item => item.id === tarifa_id);
    handleSelected({
      tarifa: tarifa,
      cantidad: value,
      cuarto: habitacion.nombre_hab.es,
      id: habitacion.id,
      image_url: habitacion.image_url
    });
  };

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id={"panel-" + habitacion.id + "-header"}
        classes={{ content: classes.expansion }}
      >
        <Avatar
          alt={`Avatar for${habitacion.nombre_hab.es}`}
          src={habitacion.image_url}
          className={classes.avatar}
        />
        <div className={classes.habColumn} style={{ minWidth: 200 }}>
          <Typography variant={matches ? "subtitle1" : "h6"}>
            {habitacion.nombre_hab.es}
          </Typography>
          <Hidden smDown>
            <Typography variant={"subtitle1"} color="textSecondary">
              {habitacion.descripcion_hab.es}
            </Typography>
          </Hidden>
        </div>
        <Hidden xsDown>
          <div
            className={classes.habColumn}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <div className={classes.features}>
              <PersonIcon fontSize="large" style={{ paddingRight: 4 }} />
              <Typography variant="h6">{habitacion.huespedes}</Typography>
            </div>
            <div className={classes.features}>
              <HotelIcon fontSize="large" style={{ paddingRight: 4 }} />
              <Typography variant="h6">{habitacion.camas}</Typography>
            </div>
          </div>
        </Hidden>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={{ root: classes.panelDetails }}>
        <div style={{ flex: 1, overflow: "hidden" }}>
          <Divider />
          <div className={classes.verticalSpacing} />
          <Typography variant="h6">Tarifas:</Typography>
          <div className={classes.tarifas}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell align="right">Max. Huespedes</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell align="right">Cantidad</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {habitacion.tarifas.map((tarifa, index) => (
                  <TableRow key={tarifa.id}>
                    <TableCell>{tarifa.etiqueta}</TableCell>
                    <TableCell align="right">{tarifa.huespedes}</TableCell>
                    <TableCell
                      onClick={() =>
                        tarifa.reembolsable ? setOpenR(true) : setOpenNR(true)
                      }
                      className={classes.reembolsableBtn}
                    >
                      {tarifa.reembolsable ? (
                        <>
                          {"Cancelacion gratis"}{" "}
                          <HelpIcon fontSize="small" color="primary" />
                        </>
                      ) : (
                        <>
                          {"No reembolsable"}{" "}
                          <HelpIcon fontSize="small" color="primary" />
                        </>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        select
                        variant="outlined"
                        value={selected[index]}
                        onChange={({ target }) =>
                          handleHuespedes(target.value, index, tarifa.id)
                        }
                        InputProps={{ style: { paddingRight: 4 } }}
                        margin="dense"
                      >
                        {getHuespedes(index, tarifa.precio)}
                      </TextField>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div className={classes.verticalSpacing} />
          <div className={classes.habActions}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext()}
            >
              Siguiente
            </Button>
          </div>
        </div>
        <Dialog
          open={openR}
          onClose={() => setOpenR(false)}
          aria-labelledby="reembolsable-dialog-title"
        >
          <DialogTitle id="reembolsable-dialog-title">
            {"Cancelación Gratis"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>No requiere deposito.</DialogContentText>
            <DialogContentText>
              Es requerida una tarjeta de crédito como garantía.
            </DialogContentText>
            <DialogContentText>
              El pago de la reservacón será cargado hasta el día de llegada.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenR(false)} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openNR}
          onClose={() => setOpenNR(false)}
          aria-labelledby="no-reembolsable-dialog-title"
        >
          <DialogTitle id="no-reembolsable-dialog-title">
            {"No reembolsable"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>La mejor tarifa disponible!</DialogContentText>
            <DialogContentText>
              El pago de la reservación completa será cargado a su tarjeta.
            </DialogContentText>
            <DialogContentText>
              Esta tarifa es <b>NO REEMBOLSABLE</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenNR(false)} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

Habitacion.propTypes = {
  habitacion: PropTypes.object.isRequired,
  handleSelected: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired
};

export default Habitacion;
