import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  LOAD_PRODUCTS_REQUEST,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_FAILURE,
  GET_PRODUCT_BY_ID_REQUEST,
  GET_PRODUCT_BY_ID_SUCCESS,
  GET_PRODUCT_BY_ID_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  GET_ALL_PRODUCTS_REQUEST,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FAILURE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* getProducts({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "productos?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&orderBy=" +
        payload.orderBy +
        "&order=" +
        payload.order +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_PRODUCTS_SUCCESS, results, search: payload.search });
  } catch (error) {
    yield put({ type: GET_PRODUCTS_FAILURE, error });
  }
}

export function* loadProducts({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "productos?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&orderBy=" +
        payload.orderBy +
        "&order=" +
        payload.order +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: LOAD_PRODUCTS_SUCCESS, results });
  } catch (error) {
    yield put({ type: LOAD_PRODUCTS_FAILURE, error });
  }
}

export function* getById({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "productos/" + payload.id,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_PRODUCT_BY_ID_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_PRODUCT_BY_ID_FAILURE, error });
  }
}

export function* updateProduct({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "productos/" + payload.id,
      payload.product,
      payload.header,
      "PUT"
    );
    yield put({ type: UPDATE_PRODUCT_SUCCESS, results });
  } catch (error) {
    yield put({ type: UPDATE_PRODUCT_FAILURE, error });
  }
}

export function* addProduct({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "productos",
      payload.product,
      payload.header,
      "POST"
    );
    yield put({ type: ADD_PRODUCT_SUCCESS, results });
  } catch (error) {
    yield put({ type: ADD_PRODUCT_FAILURE, error });
  }
}

export function* deleteProduct({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "productos/" + payload.id,
      null,
      payload.header,
      "DELETE"
    );
    yield put({ type: DELETE_PRODUCT_SUCCESS, results });
  } catch (error) {
    yield put({ type: DELETE_PRODUCT_FAILURE, error });
  }
}

export function* getAllProducts({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "productos_list/" + payload.hotel_id,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_ALL_PRODUCTS_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_ALL_PRODUCTS_FAILURE, error });
  }
}

export default function* productos() {
  yield takeLatest(GET_PRODUCTS_REQUEST, getProducts);
  yield takeLatest(LOAD_PRODUCTS_REQUEST, loadProducts);
  yield takeLatest(GET_PRODUCT_BY_ID_REQUEST, getById);
  yield takeLatest(UPDATE_PRODUCT_REQUEST, updateProduct);
  yield takeLatest(ADD_PRODUCT_REQUEST, addProduct);
  yield takeLatest(DELETE_PRODUCT_REQUEST, deleteProduct);
  yield takeLatest(GET_ALL_PRODUCTS_REQUEST, getAllProducts);
}
