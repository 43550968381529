import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";

import CloseIcon from "@material-ui/icons/Close";

import { update } from "../../redux/actions/servicios";

import styles from "./style";

export default function Formulario({ servicio, handleEdit }) {
  const classes = styles();
  const dispatch = useDispatch();

  const [precio, setPrecio] = useState(servicio.precio);
  const [nombreEs, setNombreEs] = React.useState(servicio.nombre.es);
  const [nombreEn, setNombreEn] = React.useState(servicio.nombre.en);
  const [descripcionEs, setDescripcionEs] = React.useState(
    servicio.descripcion.es || ""
  );
  const [descripcionEn, setDescripcionEn] = React.useState(
    servicio.descripcion.en || ""
  );

  const handleUpdate = event => {
    event.preventDefault();
    dispatch(
      update({
        id: servicio.id,
        service: {
          precio: precio,
          nombre: {
            es: nombreEs,
            en: nombreEn
          },
          descripcion: {
            es: descripcionEs,
            en: descripcionEn
          }
        },
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    handleEdit();
  };

  return (
    <CardContent>
      <div className={classes.header}>
        <Typography variant="h6">Editar información</Typography>
        <div style={{ flexGrow: 1 }} />
        <IconButton onClick={() => handleEdit()}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.verticalSpacing} />
      <form onSubmit={handleUpdate} autoComplete="off">
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Precio{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-numero"
              autoFocus
              fullWidth
              value={precio}
              onChange={({ target }) => setPrecio(target.value)}
              variant="outlined"
              margin="dense"
              inputProps={{ type: "number", max: 99999999 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
              required
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Nombre (español){" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-nombre-es"
              fullWidth
              value={nombreEs}
              onChange={({ target }) => setNombreEs(target.value)}
              variant="outlined"
              margin="dense"
              required
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Nombre (ingles){" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-nombre-en"
              fullWidth
              value={nombreEn}
              onChange={({ target }) => setNombreEn(target.value)}
              variant="outlined"
              margin="dense"
              required
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Descripción (español)
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-descripcion-es"
              fullWidth
              value={descripcionEs}
              onChange={({ target }) => setDescripcionEs(target.value)}
              variant="outlined"
              margin="dense"
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Descripción (ingles)
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-descripcion-en"
              fullWidth
              value={descripcionEn}
              onChange={({ target }) => setDescripcionEn(target.value)}
              variant="outlined"
              margin="dense"
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
        <div className={classes.verticalSpacing} />
        <div className={classes.actions}>
          <Button variant="contained" color="secondary" type="submit">
            Guardar
          </Button>
        </div>
      </form>
    </CardContent>
  );
}

Formulario.propTypes = {
  servicio: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired
};
