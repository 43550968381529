import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import RoomServiceIcon from "@material-ui/icons/RoomServiceOutlined";
import InfoIcon from "@material-ui/icons/Info";
import SyncIcon from "@material-ui/icons/Sync";

import SearchTextField from "../../components/SearchTextField";
import Mensaje from "../../components/Mensaje";
import ItemServicio from "./ItemServicio";
import BotonCargar from "./BotonCargar";
import styles from "./style";

import { get } from "../../redux/actions/servicios";
import { useHistory } from "react-router-dom";

export default function Cuartos() {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const history = useHistory();

  const dispatch = useDispatch();
  const hotel_id = useSelector(state => state.user.hotel_id);
  const isLoading = useSelector(state => state.servicios.isLoading);
  const total = useSelector(state => state.servicios.total);
  const servicios = useSelector(state => state.servicios.lista);
  const currentSearch = useSelector(state => state.servicios.search);

  const [search, setSearch] = React.useState("");
  const [sync, setSync] = React.useState(false);

  React.useEffect(() => {
    if ((total !== 0 && servicios.length < 1) || search !== currentSearch || sync === true) {
      dispatch(
        get({
          page: 1,
          hotel_id: hotel_id,
          orderBy: "id",
          order: "asc",
          search: search,
          header: {
            Authorization:
              localStorage.getItem("token_type") +
              " " +
              localStorage.getItem("access_token")
          }
        })
      );
    }
    setSync(false);
  }, [hotel_id, search, sync, currentSearch, dispatch, servicios, total]);

  return (
    <>
      <div className={classes.header}>
        <Typography
          variant={matches ? "h6" : "h4"}
          gutterBottom
          className={classes.title}
        >
          <RoomServiceIcon fontSize="inherit" style={{ marginRight: 8 }} />{" "}
          Servicios
        </Typography>
        <div style={{ flexGrow: 1 }} />
        <Tooltip title="Actualizar">
          <IconButton color="secondary" onClick={() => setSync(true)}>
            <SyncIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.verticalSpacing} />
      <div className={classes.toolbar}>
        <SearchTextField
          search={search}
          handleTextChange={value => setSearch(value)}
        />
        <div style={{ flexGrow: 1, paddingRight: 2 }} />
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/servicios/nuevo"
        >
          Nuevo
        </Button>
      </div>
      <div className={classes.verticalSpacing} />
      {isLoading ? (
        <Mensaje
          leftComponent={
            <CircularProgress size={64} style={{ color: "#000" }} />
          }
          header={"Un momento..."}
          content={"Estamos buscando servicios."}
        />
      ) : servicios.length > 0 ? (
        <>
          <List>
            {servicios.map((servicio, index) => (
              <ItemServicio
                key={servicio.id}
                servicio={servicio}
                index={index}
                handleSelect={id => history.push("/servicios/detalle?id=" + id)}
              />
            ))}
          </List>
          <BotonCargar search={search} />
        </>
      ) : (
        <Mensaje
          leftComponent={<InfoIcon style={{ fontSize: 64 }} />}
          header={"No encontramos servicios registrados."}
          content={
            <>
              Haz clic en <b>Nuevo</b> para insertar un servicio.
            </>
          }
        />
      )}
    </>
  );
}
