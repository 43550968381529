import React from "react";
import PropTypes from "prop-types";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import moment from "moment";
import styles from "./style";

function CustomListItem({ reservacion }) {
  const classes = styles();

  return (
    <ListItem className={classes.listStyle} button>
      <ListItemAvatar>
        <Avatar
          style={
            reservacion.estado === 4
              ? { backgroundColor: "#43a047" }
              : { backgroundColor: "#dc3545" }
          }
        >
          {reservacion.estado === 4 ? "F" : "C"}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <b>Codigo:</b> {reservacion.code} | <b>Huesped:</b>{" "}
            {reservacion.huesped.nombre_completo}
          </>
        }
        secondary={
          <>
            <b>Desde: </b>
            {moment(reservacion.desde).format("dddd, DD MMMM YYYY")}
            {" - "}
            <b>Hasta: </b>
            {moment(reservacion.hasta).format("dddd, DD MMMM YYYY")}
          </>
        }
      />
      <ListItemIcon>
        <NavigateNextIcon />
      </ListItemIcon>
    </ListItem>
  );
}

CustomListItem.propTypes = {
  reservacion: PropTypes.object.isRequired
};

export default CustomListItem;
