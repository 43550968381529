import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import EditIcon from "@material-ui/icons/Edit";

import moment from "moment";
import MomentUtils from "@date-io/moment";

import { get, start } from "../../redux/actions/nuevaReservacion";

import styles from "./style";

function Fechas({ desde, hasta, handleNext }) {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch();
  const hotel_id = useSelector(state => state.user.hotel_id);
  const isLoading = useSelector(state => state.nuevaReservacion.isLoading);
  const disponibles = useSelector(
    state => state.nuevaReservacion.disponibles.length > 0
  );

  const [inicio, setInicio] = React.useState(desde);
  const [openD, setOpenD] = React.useState(false);
  const [fin, setFin] = React.useState(hasta);
  const [openH, setOpenH] = React.useState(false);
  const [action, setAction] = React.useState(false);

  React.useEffect(() => {
    if (action && disponibles) {
      handleNext({ desde: inicio, hasta: fin });
    }
  });

  const handleSubmit = () => {
    if (fin.diff(inicio, "d") < 0) {
      alert("Fechas invalidas.");
      return;
    }
    dispatch(start());
    dispatch(
      get({
        hotel_id: hotel_id,
        desde: inicio.format("YYYY-MM-DD"),
        hasta: fin.format("YYYY-MM-DD"),
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    setAction(true);
  };

  return (
    <div className={classes.stepContainer}>
      <Typography variant={matches ? "subtitle1" : "h6"}>
        Selecciona las fechas de estancia.
      </Typography>
      <div className={classes.verticalSpacing} />
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress />
          <div className={classes.verticalSpacing} />
          <Typography>Buscando habitaciones disponibles...</Typography>
        </div>
      ) : (
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div onClick={() => setOpenD(true)} className={classes.fechaButton}>
              <Typography variant="h5">Entrada</Typography>
              <div style={{ display: "flex" }}>
                <Typography variant="h3">
                  <b>{moment(inicio).format("DD")}</b>
                </Typography>
                <div style={{ width: 8 }} />
                <div>
                  <Typography
                    variant="body1"
                    style={{ textTransform: "capitalize" }}
                  >
                    <b>{moment(inicio).format("dddd")}</b>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ textTransform: "capitalize" }}
                  >
                    <b>{moment(inicio).format("MMMM YYYY")}</b>
                  </Typography>
                </div>
                <div style={{ flexGrow: 1 }} />
                <IconButton>
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div onClick={() => setOpenH(true)} className={classes.fechaButton}>
              <Typography variant="h5">Salida</Typography>
              <div style={{ display: "flex" }}>
                <Typography variant="h3">
                  <b>{moment(fin).format("DD")}</b>
                </Typography>
                <div style={{ width: 8 }} />
                <div>
                  <Typography
                    variant="body1"
                    style={{ textTransform: "capitalize" }}
                  >
                    <b>{moment(fin).format("dddd")}</b>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ textTransform: "capitalize" }}
                  >
                    <b>{moment(fin).format("MMMM YYYY")}</b>
                  </Typography>
                </div>
                <div style={{ flexGrow: 1 }} />
                <IconButton>
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      <div className={classes.verticalSpacing} />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        onClick={() => handleSubmit()}
        disabled={isLoading}
      >
        Siguiente
      </Button>
      <div style={{ display: "none" }}>
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
          <DatePicker
            inputVariant="outlined"
            format="MM/DD/YYYY"
            margin="dense"
            id="date-picker-desde"
            value={inicio}
            onChange={date =>
              setInicio(
                date.set({ hour: 1, minute: 0, second: 0, millisecond: 0 })
              )
            }
            open={openD}
            onClose={() => setOpenD(false)}
            minDate={moment()}
          />
          <DatePicker
            inputVariant="outlined"
            format="MM/DD/YYYY"
            margin="dense"
            id="date-picker-hasta"
            value={fin}
            onChange={date =>
              setFin(
                date.set({ hour: 1, minute: 0, second: 0, millisecond: 0 })
              )
            }
            open={openH}
            onClose={() => setOpenH(false)}
            minDate={moment(inicio).add(1, "d")}
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
}

Fechas.propTypes = {
  desde: PropTypes.object.isRequired,
  hasta: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired
};

export default Fechas;
