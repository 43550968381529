import React from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import CalendarTodayOutlined from "@material-ui/icons/CalendarTodayOutlined";
import SyncIcon from "@material-ui/icons/Sync";

import styles from "./style";
import Lista from "./Lista";
import Detalles from "./Detalles";

import { get } from "../../redux/actions/pendientes";

export default function Pendientes() {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { code } = queryString.parse(window.location.search);

  const dispatch = useDispatch();
  const hotel_id = useSelector(state => state.user.hotel_id);
  const total = useSelector(state => state.pendientes.total);
  const reservaciones = useSelector(state => state.pendientes.lista);
  const currentSearch = useSelector(state => state.pendientes.search);

  const [selected, setSelected] = React.useState(code);
  const [search, setSearch] = React.useState(code || "");
  const [sync, setSync] = React.useState(false);

  React.useEffect(() => {
    if (code !== selected) {
      setSelected(code);
    }
    if (
      (total !== 0 && reservaciones.length < 1) ||
      search !== currentSearch ||
      sync === true
    ) {
      dispatch(
        get({
          page: 1,
          hotel_id: hotel_id,
          search: search,
          header: {
            Authorization:
              localStorage.getItem("token_type") +
              " " +
              localStorage.getItem("access_token")
          }
        })
      );
    }
    setSync(false);
  }, [
    hotel_id,
    search,
    sync,
    currentSearch,
    dispatch,
    reservaciones,
    total,
    code,
    selected
  ]);

  return (
    <>
      <div className={classes.header}>
        <Typography
          variant={matches ? "h6" : "h4"}
          gutterBottom
          className={classes.title}
        >
          <CalendarTodayOutlined
            fontSize="inherit"
            style={{ marginRight: 8 }}
          />{" "}
          Reservaciones Pendientes
        </Typography>
        <div style={{ flexGrow: 1 }} />
        <Tooltip title="Actualizar">
          <IconButton color="secondary" onClick={() => setSync(true)}>
            <SyncIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.verticalSpacing} />
      {matches ? (
        selected ? (
          <Detalles code={code} />
        ) : (
          <Lista
            reservaciones={reservaciones}
            search={search}
            setSearch={value => setSearch(value)}
          />
        )
      ) : (
        <Grid container style={{ height: "100%" }}>
          <Grid item md={4} style={{ overflowY: "scroll" }}>
            <Lista
              reservaciones={reservaciones}
              search={search}
              setSearch={value => setSearch(value)}
            />
          </Grid>
          <Grid item md={8}>
            <Detalles code={code} />
          </Grid>
        </Grid>
      )}
    </>
  );
}
