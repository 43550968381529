import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import SyncIcon from "@material-ui/icons/Sync";

import styles from "./style";
import Lista from "./Lista";
import Detalles from "./Detalles";
import Formulario from "./Formulario";

import { get } from "../../redux/actions/usuarios";

export default function Mensajes() {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const hotel_id = useSelector(state => state.user.hotel_id);
  const total = useSelector(state => state.usuarios.total);
  const usuarios = useSelector(state => state.usuarios.lista);
  const currentSearch = useSelector(state => state.usuarios.search);
  const selected = useSelector(state => Boolean(state.usuarios.seleccionado));

  const [search, setSearch] = React.useState("");
  const [editar, setEditar] = React.useState(false);
  const [sync, setSync] = React.useState(false);

  React.useEffect(() => {
    if (
      (total !== 0 && usuarios.length < 1) ||
      search !== currentSearch ||
      sync === true
    ) {
      dispatch(
        get({
          page: 1,
          hotel_id: hotel_id,
          search: search,
          header: {
            Authorization:
              localStorage.getItem("token_type") +
              " " +
              localStorage.getItem("access_token")
          }
        })
      );
    }
    setSync(false);
  }, [hotel_id, search, sync, currentSearch, dispatch, usuarios, total]);

  return (
    <>
      <div className={classes.header}>
        <Typography
          variant={matches ? "h6" : "h4"}
          gutterBottom
          className={classes.title}
        >
          <MessageOutlinedIcon fontSize="inherit" style={{ marginRight: 8 }} />{" "}
          Usuarios
        </Typography>
        <div style={{ flexGrow: 1 }} />
        <Tooltip title="Actualizar">
          <IconButton color="secondary" onClick={() => setSync(true)}>
            <SyncIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.verticalSpacing} />
      {matches ? (
        selected ? (
          editar ? (
            <Formulario handleEdit={() => setEditar(false)} />
          ) : (
            <Detalles handleEdit={() => setEditar(true)} />
          )
        ) : (
          <Lista
            usuarios={usuarios}
            search={search}
            setSearch={value => setSearch(value)}
          />
        )
      ) : (
        <Grid container style={{ height: "100%" }}>
          <Grid item sm={12} md={4} style={{ overflowY: "scroll" }}>
            <Lista
              usuarios={usuarios}
              search={search}
              setSearch={value => setSearch(value)}
            />
          </Grid>
          <Grid item md={8}>
            {editar ? (
              <Formulario handleEdit={() => setEditar(false)} />
            ) : (
              <Detalles handleEdit={() => setEditar(true)} />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
