import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import { checkIn } from "../../redux/actions/reservaciones";

function CheckIn({
  open,
  handleClose,
  reservacion_id,
  code,
  cuartosReservados
}) {
  const dispatch = useDispatch();
  const isUpdating = useSelector(state => state.reservaciones.isUpdating);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Confirmar ingreso del huesped
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tipo de cuarto</TableCell>
              <TableCell>Asignado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cuartosReservados.map(reservado => (
              <TableRow key={reservado.id}>
                <TableCell>
                  {reservado.cuarto.tipo_cuarto.nombre_hab.es}
                </TableCell>
                <TableCell>{reservado.cuarto.numero}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <br />
        <DialogContentText>
          Si desea asignar un cuarto diferente, vaya a la seccion de cuartos
          asignados en la pantalla anterior.
        </DialogContentText>
        <Button
          variant="contained"
          style={{ color: "#fff", backgroundColor: "#d50000" }}
          component={Link}
          target="_blank"
          to={"/contrato?code=" + code}
          endIcon={<PictureAsPdfIcon />}
        >
          Ver contrato.
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
        <Button
          color="primary"
          disabled={isUpdating}
          onClick={() =>
            dispatch(
              checkIn({
                reservacion_id: reservacion_id,
                header: {
                  Authorization:
                    localStorage.getItem("token_type") +
                    " " +
                    localStorage.getItem("access_token")
                }
              })
            )
          }
          endIcon={isUpdating && <CircularProgress size={12} />}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CheckIn.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reservacion_id: PropTypes.number,
  code: PropTypes.string,
  cuartosReservados: PropTypes.array
};

export default CheckIn;
