import {
  GET_AVAILABLE_REQUEST,
  GET_AVAILABLE_SUCCESS,
  GET_AVAILABLE_FAILURE,
  UPLOAD_RESERVATION_START,
  UPLOAD_RESERVATION_REQUEST,
  UPLOAD_RESERVATION_SUCCESS,
  UPLOAD_RESERVATION_FAILURE,
  USER_LOGOUT_SUCCESS
} from "../../consts/actionTypes";

const initialState = {
  disponibles: [],
  isLoading: false,
  isUploading: false,
  error: null,
  isCompleted: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_AVAILABLE_REQUEST:
      return { ...state, isLoading: true, error: null, disponibles: [] };
    case GET_AVAILABLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        disponibles: action.results.data
      };
    case GET_AVAILABLE_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case UPLOAD_RESERVATION_START:
      return { ...state, error: null, isCompleted: null };
    case UPLOAD_RESERVATION_REQUEST:
      return { ...state, isUploading: true };
    case UPLOAD_RESERVATION_SUCCESS:
      return { ...state, isUploading: false, isCompleted: action.results.data };
    case UPLOAD_RESERVATION_FAILURE:
      return {
        ...state,
        error: action.error,
        isUploading: false,
        isCompleted: null
      };
    case USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return { ...state };
  }
}
