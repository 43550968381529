import {
  GET_RES_INFO_REQUEST,
  GET_RES_INFO_SUCCESS,
  GET_RES_INFO_FAILURE,
  USER_LOGOUT_SUCCESS
} from "../../consts/actionTypes";

const initialState = {
  reservacion: null,
  isLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_RES_INFO_REQUEST:
      return { ...state, isLoading: true };
    case GET_RES_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reservacion: action.results.data
      };
    case GET_RES_INFO_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return { ...state };
  }
}
