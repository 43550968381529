import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import NumberTextField from "../../../components/NumberTextField";
import { get } from "../../../redux/actions/tiposPago";
import { addPayment } from "../../../redux/actions/reservaciones";
import { MenuItem } from "@material-ui/core";
import style from "./style";

function Pagos({ open, handleClose, reservacion_id }) {
  const classes = style();
  const dispatch = useDispatch();
  const inputRef = React.useRef(null);

  const tiposPago = useSelector(state => state.tiposPago.lista);
  const tiposLoading = useSelector(state => state.tiposPago.isLoading);
  const isUpdating = useSelector(state => state.reservaciones.isUpdating);

  const [valor, setValor] = React.useState(0);
  const [tipoPago, setTipoPago] = React.useState(-1);
  const [concepto, setConcepto] = React.useState("");
  const [upload, setUpload] = React.useState(false);

  React.useEffect(() => {
    if (!open && (valor !== 0 || tipoPago !== -1 || upload)) {
      setValor(0);
      setTipoPago(-1);
      setUpload(false);
    }
  }, [open, valor, tipoPago, upload]);

  React.useEffect(() => {
    if (upload && !isUpdating) {
      handleClose();
    }
  });

  React.useEffect(() => {
    if (tiposPago.length < 1) {
      dispatch(
        get({
          header: {
            Authorization:
              localStorage.getItem("token_type") +
              " " +
              localStorage.getItem("access_token")
          }
        })
      );
    }
  });

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(
      addPayment({
        reservacion_id: reservacion_id,
        valor: valor,
        tipo_id: tipoPago,
        concepto: concepto,
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    setUpload(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"xs"}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Registrar un nuevo Pago</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} autoComplete="off">
          <TextField
            select
            value={tipoPago}
            onChange={e => setTipoPago(e.target.value)}
            label="Tipo de pago"
            variant="outlined"
            fullWidth
            required
          >
            <MenuItem value={-1} disabled>
              {tiposLoading ? "Cargando..." : "Seleccione el tipo de pago."}
            </MenuItem>
            {tiposPago.map(tipo => (
              <MenuItem key={tipo.id} value={tipo.id}>
                {tipo.nombre}
              </MenuItem>
            ))}
          </TextField>
          <div className={classes.verticalSpacing} />
          <NumberTextField
            autoFocus
            label="Pago"
            value={valor}
            onChange={value => setValor(value)}
            maxLength={10}
            required
          />
          <div className={classes.verticalSpacing} />
          <TextField
            value={concepto}
            onChange={e => setConcepto(e.target.value)}
            label="Concepto"
            variant="outlined"
            fullWidth
          />
          <button hidden type="submit" ref={inputRef} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() => inputRef.current.click()}
          color="primary"
          disabled={isUpdating}
          endIcon={isUpdating && <CircularProgress size={12} />}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Pagos.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reservacion_id: PropTypes.number
};

export default Pagos;
