import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    height: "100%"
  },
  paper: {
    padding: 32
  },
  form: {
    textAlign: "center"
  },
  input: {
    margin: theme.spacing(1) + "px 0",
    borderRadius: 4
  },
  verticalSpacing: {
    height: 16
  },
  button: {
    marginTop: 32
  },
  loadingBox: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    padding: "32px 0px"
  },
  errorBox: {
    backgroundColor: "#ffebee",
    color: "#b71c1c",
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 16,
    borderColor: "#b71c1c",
    padding: theme.spacing(2) + "px " + theme.spacing(2) + "px",
    textAlign: 'left'
  }
}));
