import {
  NUEVO_MENSAJE,
  LIMPIAR_MENSAJE,
  CHECK_OUT_FAILURE,
  ADD_PAYMENT_FAILURE,
  USER_LOGOUT_SUCCESS
} from "../../consts/actionTypes";

const initialState = {
  mensaje: "",
  tipo: "default"
};

export default function(state = initialState, action) {
  switch (action.type) {
    case NUEVO_MENSAJE:
      return { mensaje: action.mensaje, tipo: action.tipo };
    case LIMPIAR_MENSAJE:
      return initialState;
    case CHECK_OUT_FAILURE:
      return {
        mensaje: action.error.response.data.message,
        tipo: "error"
      };
    case ADD_PAYMENT_FAILURE:
      return {
        mensaje: action.error.response.data.message,
        tipo: "error"
      };
    case USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return { ...state };
  }
}
