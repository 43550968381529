import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";

import CloseIcon from "@material-ui/icons/Close";

import { update } from "../../redux/actions/cuartos";

import styles from "./style";
import RoomStateSelect from "../../components/RoomStateSelect";
import RoomTypesSelect from "../../components/RoomTypesSelect";

export default function Formulario({ cuarto, handleEdit }) {
  const classes = styles();
  const dispatch = useDispatch();

  const [tipo_de_cuarto, setTipoCuarto] = React.useState(cuarto.tipo_de_cuarto);
  const [numero, setNumero] = React.useState(cuarto.numero);
  const [estado, setEstado] = React.useState(cuarto.estado.id);
  const [piso, setPiso] = React.useState(cuarto.piso);
  const [activo, setActivo] = React.useState(Boolean(cuarto.activo));
  const [descripcionEs, setDescripcionEs] = React.useState(
    cuarto.descripcion.es || ""
  );
  const [descripcionEn, setDescripcionEn] = React.useState(
    cuarto.descripcion.en || ""
  );

  const handleUpdate = event => {
    event.preventDefault();
    dispatch(
      update({
        id: cuarto.id,
        room: {
          tipo_de_cuarto: tipo_de_cuarto,
          numero: numero,
          estado: estado,
          piso: piso,
          activo: activo,
          descripcion: {
            es: descripcionEs,
            en: descripcionEn
          }
        },
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    handleEdit();
  };

  return (
    <CardContent>
      <div className={classes.header}>
        <Typography variant="h6">Editar información</Typography>
        <div style={{ flexGrow: 1 }} />
        <IconButton onClick={() => handleEdit()}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.verticalSpacing} />
      <form onSubmit={handleUpdate} autoComplete="off">
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Tipo de cuarto{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <RoomTypesSelect
              value={tipo_de_cuarto}
              handleChange={value => setTipoCuarto(value)}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Número{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-numero"
              autoFocus
              fullWidth
              value={numero}
              onChange={({ target }) => setNumero(target.value)}
              variant="outlined"
              margin="dense"
              required
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Piso{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-piso"
              autoFocus
              fullWidth
              value={piso}
              onChange={({ target }) => setPiso(target.value)}
              variant="outlined"
              margin="dense"
              InputProps={{ type: "number" }}
              required
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Estado{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <RoomStateSelect
              value={estado}
              handleChange={value => setEstado(value)}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Reservar en web{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <Switch
              checked={activo}
              onChange={(event) => setActivo(event.target.checked)}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">Descripción (español)</Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-descripcion-es"
              fullWidth
              value={descripcionEs}
              onChange={({ target }) => setDescripcionEs(target.value)}
              variant="outlined"
              margin="dense"
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">Descripción (ingles)</Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-descripcion-en"
              fullWidth
              value={descripcionEn}
              onChange={({ target }) => setDescripcionEn(target.value)}
              variant="outlined"
              margin="dense"
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
        <div className={classes.verticalSpacing} />
        <div className={classes.actions}>
          <Button variant="contained" color="secondary" type="submit">
            Guardar
          </Button>
        </div>
      </form>
    </CardContent>
  );
}

Formulario.propTypes = {
  cuarto: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired
};
