import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

import HotelIcon from "@material-ui/icons/Hotel";
import PeopleIcon from "@material-ui/icons/People";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";

import styles from "./style";

import { del } from "../../redux/actions/tiposCuarto";

export default function Detalles({ cuarto, handleEdit }) {
  const classes = styles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const isDeleting = useSelector(state => state.tiposCuarto.isDeleting);
  const lastDeleted = useSelector(state => state.tiposCuarto.lastDeleted);
  const [inProcess, setInProcess] = React.useState(false) 

  React.useEffect(() => {
    if (inProcess && lastDeleted === cuarto.id.toString()) {
      history.goBack();
    }
  }, [inProcess, lastDeleted, history, cuarto]);

  const handleDelete = () => {
    setAnchorEl(null);
    dispatch(
      del({
        id: cuarto.id,
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    setInProcess(true)
  };

  if (isDeleting) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={64} />
        <Typography variant="subtitle1">Eliminando cuarto</Typography>
      </div>
    );
  }

  return (
    <CardContent className={classes.content}>
      <div className={classes.header}>
        <Typography variant="h6">{cuarto.nombre_hab.es}</Typography>
        <div style={{ flexGrow: 1 }} />
        <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="room-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => handleEdit()}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Editar" />
          </MenuItem>
          <MenuItem onClick={() => handleDelete()}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Eliminar" />
          </MenuItem>
        </Menu>
      </div>
      <div className={classes.verticalSpacing} />
      <Typography variant="body1">{cuarto.descripcion_hab.es}</Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <HotelIcon />
          </ListItemIcon>
          <ListItemText primary="Camas" secondary={cuarto.camas} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText
            primary="Capacidad máxima"
            secondary={cuarto.huespedes}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PlaylistAddCheckIcon />
          </ListItemIcon>
          <ListItemText
            primary="Cuartos registrados"
            secondary={cuarto.cuartos_count}
          />
        </ListItem>
      </List>
      <Button
        color="primary"
        component={Link}
        to={"/tarifas?cuarto=" + cuarto.nombre_hab.es}
      >
        Ver tarifas...
      </Button>
    </CardContent>
  );
}

Detalles.propTypes = {
  cuarto: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired
};
