// USUARIO
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";
// TIPOS DE CUARTO
export const GET_ROOMTYPES_REQUEST = "GET_ROOMTYPES_REQUEST";
export const GET_ROOMTYPES_SUCCESS = "GET_ROOMTYPES_SUCCESS";
export const GET_ROOMTYPES_FAILURE = "GET_ROOMTYPES_FAILURE";

export const LOAD_ROOMTYPES_REQUEST = "LOAD_ROOMTYPES_REQUEST";
export const LOAD_ROOMTYPES_SUCCESS = "LOAD_ROOMTYPES_SUCCESS";
export const LOAD_ROOMTYPES_FAILURE = "LOAD_ROOMTYPES_FAILURE";

export const GET_ROOMTYPE_BY_ID_REQUEST = "GET_ROOMTYPE_BY_ID_REQUEST";
export const GET_ROOMTYPE_BY_ID_SUCCESS = "GET_ROOMTYPE_BY_ID_SUCCESS";
export const GET_ROOMTYPE_BY_ID_FAILURE = "GET_ROOMTYPE_BY_ID_FAILURE";

export const UPDATE_ROOMTYPE_REQUEST = "UPDATE_ROOMTYPE_REQUEST";
export const UPDATE_ROOMTYPE_SUCCESS = "UPDATE_ROOMTYPE_SUCCESS";
export const UPDATE_ROOMTYPE_FAILURE = "UPDATE_ROOMTYPE_FAILURE";

export const ADD_ROOMTYPE_REQUEST = "ADD_ROOMTYPE_REQUEST";
export const ADD_ROOMTYPE_SUCCESS = "ADD_ROOMTYPE_SUCCESS";
export const ADD_ROOMTYPE_FAILURE = "ADD_ROOMTYPE_FAILURE";

export const DELETE_ROOMTYPE_REQUEST = "DELETE_ROOMTYPE_REQUEST";
export const DELETE_ROOMTYPE_SUCCESS = "DELETE_ROOMTYPE_SUCCESS";
export const DELETE_ROOMTYPE_FAILURE = "DELETE_ROOMTYPE_FAILURE";

export const GET_ROOMTYPES_FOR_SELECT_REQUEST =
  "GET_ROOMTYPES_FOR_SELECT_REQUEST";
export const GET_ROOMTYPES_FOR_SELECT_SUCCESS =
  "GET_ROOMTYPES_FOR_SELECT_SUCCESS";
export const GET_ROOMTYPES_FOR_SELECT_FAILURE =
  "GET_ROOMTYPES_FOR_SELECT_FAILURE";

export const UPLOAD_ROOMTYPE_IMAGE_REQUEST = "UPLOAD_ROOMTYPE_IMAGE_REQUEST";
export const UPLOAD_ROOMTYPE_IMAGE_SUCCESS = "UPLOAD_ROOMTYPE_IMAGE_SUCCESS";
export const UPLOAD_ROOMTYPE_IMAGE_FAILURE = "UPLOAD_ROOMTYPE_IMAGE_FAILURE";
export const UPLOAD_ROOMTYPE_IMAGE_FINISH = "UPLOAD_ROOMTYPE_IMAGE_FINISH";

export const ADD_GALLERY_IMAGE_REQUEST = "ADD_GALLERY_IMAGE_REQUEST";
export const ADD_GALLERY_IMAGE_SUCCESS = "ADD_GALLERY_IMAGE_SUCCESS";
export const ADD_GALLERY_IMAGE_FAILURE = "ADD_GALLERY_IMAGE_FAILURE";

// CUARTOS
export const GET_ROOMS_REQUEST = "GET_ROOMS_REQUEST";
export const GET_ROOMS_SUCCESS = "GET_ROOMS_SUCCESS";
export const GET_ROOMS_FAILURE = "GET_ROOMS_FAILURE";

export const LOAD_ROOMS_REQUEST = "LOAD_ROOMS_REQUEST";
export const LOAD_ROOMS_SUCCESS = "LOAD_ROOMS_SUCCESS";
export const LOAD_ROOMS_FAILURE = "LOAD_ROOMS_FAILURE";

export const GET_ROOM_BY_ID_REQUEST = "GET_ROOM_BY_ID_REQUEST";
export const GET_ROOM_BY_ID_SUCCESS = "GET_ROOM_BY_ID_SUCCESS";
export const GET_ROOM_BY_ID_FAILURE = "GET_ROOM_BY_ID_FAILURE";

export const UPDATE_ROOM_REQUEST = "UPDATE_ROOM_REQUEST";
export const UPDATE_ROOM_SUCCESS = "UPDATE_ROOM_SUCCESS";
export const UPDATE_ROOM_FAILURE = "UPDATE_ROOM_FAILURE";

export const ADD_ROOM_REQUEST = "ADD_ROOM_REQUEST";
export const ADD_ROOM_SUCCESS = "ADD_ROOM_SUCCESS";
export const ADD_ROOM_FAILURE = "ADD_ROOM_FAILURE";

export const DELETE_ROOM_REQUEST = "DELETE_ROOM_REQUEST";
export const DELETE_ROOM_SUCCESS = "DELETE_ROOM_SUCCESS";
export const DELETE_ROOM_FAILURE = "DELETE_ROOM_FAILURE";

export const ADD_CLOSE_DATE_REQUEST = "ADD_CLOSE_DATE_REQUEST";
export const ADD_CLOSE_DATE_SUCCESS = "ADD_CLOSE_DATE_SUCCESS";
export const ADD_CLOSE_DATE_FAILURE = "ADD_CLOSE_DATE_FAILURE";

export const REMOVE_CLOSE_DATE_REQUEST = "REMOVE_CLOSE_DATE_REQUEST";
export const REMOVE_CLOSE_DATE_SUCCESS = "REMOVE_CLOSE_DATE_SUCCESS";
export const REMOVE_CLOSE_DATE_FAILURE = "REMOVE_CLOSE_DATE_FAILURE";

// Estados de cuarto
export const GET_ROOMSTATES_REQUEST = "GET_ROOMSTATES_REQUEST";
export const GET_ROOMSTATES_SUCCESS = "GET_ROOMSTATES_SUCCESS";
export const GET_ROOMSTATES_FAILURE = "GET_ROOMSTATES_FAILURE";

// Tarifas
export const GET_RATES_REQUEST = "GET_RATES_REQUEST";
export const GET_RATES_SUCCESS = "GET_RATES_SUCCESS";
export const GET_RATES_FAILURE = "GET_RATES_FAILURE";

export const LOAD_RATES_REQUEST = "LOAD_RATES_REQUEST";
export const LOAD_RATES_SUCCESS = "LOAD_RATES_SUCCESS";
export const LOAD_RATES_FAILURE = "LOAD_RATES_FAILURE";

export const GET_RATE_BY_ID_REQUEST = "GET_RATE_BY_ID_REQUEST";
export const GET_RATE_BY_ID_SUCCESS = "GET_RATE_BY_ID_SUCCESS";
export const GET_RATE_BY_ID_FAILURE = "GET_RATE_BY_ID_FAILURE";

export const UPDATE_RATE_REQUEST = "UPDATE_RATE_REQUEST";
export const UPDATE_RATE_SUCCESS = "UPDATE_RATE_SUCCESS";
export const UPDATE_RATE_FAILURE = "UPDATE_RATE_FAILURE";

export const ADD_RATE_REQUEST = "ADD_RATE_REQUEST";
export const ADD_RATE_SUCCESS = "ADD_RATE_SUCCESS";
export const ADD_RATE_FAILURE = "ADD_RATE_FAILURE";

export const DELETE_RATE_REQUEST = "DELETE_RATE_REQUEST";
export const DELETE_RATE_SUCCESS = "DELETE_RATE_SUCCESS";
export const DELETE_RATE_FAILURE = "DELETE_RATE_FAILURE";

// Servicios
export const GET_SERVICES_REQUEST = "GET_SERVICES_REQUEST";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAILURE = "GET_SERVICES_FAILURE";

export const LOAD_SERVICES_REQUEST = "LOAD_SERVICES_REQUEST";
export const LOAD_SERVICES_SUCCESS = "LOAD_SERVICES_SUCCESS";
export const LOAD_SERVICES_FAILURE = "LOAD_SERVICES_FAILURE";

export const GET_SERVICE_BY_ID_REQUEST = "GET_SERVICE_BY_ID_REQUEST";
export const GET_SERVICE_BY_ID_SUCCESS = "GET_SERVICE_BY_ID_SUCCESS";
export const GET_SERVICE_BY_ID_FAILURE = "GET_SERVICE_BY_ID_FAILURE";

export const UPDATE_SERVICE_REQUEST = "UPDATE_SERVICE_REQUEST";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAILURE = "UPDATE_SERVICE_FAILURE";

export const ADD_SERVICE_REQUEST = "ADD_SERVICE_REQUEST";
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const ADD_SERVICE_FAILURE = "ADD_SERVICE_FAILURE";

export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAILURE = "DELETE_SERVICE_FAILURE";

export const GET_ALL_SERVICES_REQUEST = "GET_ALL_SERVICES_REQUEST";
export const GET_ALL_SERVICES_SUCCESS = "GET_ALL_SERVICES_SUCCESS";
export const GET_ALL_SERVICES_FAILURE = "GET_ALL_SERVICES_FAILURE";

//Productos
export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

export const LOAD_PRODUCTS_REQUEST = "LOAD_PRODUCTS_REQUEST";
export const LOAD_PRODUCTS_SUCCESS = "LOAD_PRODUCTS_SUCCESS";
export const LOAD_PRODUCTS_FAILURE = "LOAD_PRODUCTS_FAILURE";

export const GET_PRODUCT_BY_ID_REQUEST = "GET_PRODUCT_BY_ID_REQUEST";
export const GET_PRODUCT_BY_ID_SUCCESS = "GET_PRODUCT_BY_ID_SUCCESS";
export const GET_PRODUCT_BY_ID_FAILURE = "GET_PRODUCT_BY_ID_FAILURE";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const GET_ALL_PRODUCTS_REQUEST = "GET_ALL_PRODUCTS_REQUEST";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILURE = "GET_ALL_PRODUCTS_FAILURE";

// Mensajes
export const GET_MESSSGES_REQUEST = "GET_MESSSGES_REQUEST";
export const GET_MESSSGES_SUCCESS = "GET_MESSSGES_SUCCESS";
export const GET_MESSSGES_FAILURE = "GET_MESSSGES_FAILURE";

export const UPDATE_MESSAGE_REQUEST = "UPDATE_MESSAGE_REQUEST";
export const UPDATE_MESSAGE_SUCCESS = "UPDATE_MESSAGE_SUCCESS";
export const UPDATE_MESSAGE_FAILURE = "UPDATE_MESSAGE_FAILURE";

export const LOAD_MESSSGES_REQUEST = "LOAD_MESSSGES_REQUEST";
export const LOAD_MESSSGES_SUCCESS = "LOAD_MESSSGES_SUCCESS";
export const LOAD_MESSSGES_FAILURE = "LOAD_MESSSGES_FAILURE";

export const SELECT_MESSAGE = "SELECT_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// Usuarios
export const GET_ACCOUNTS_REQUEST = "GET_ACCOUNTS_REQUEST";
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS";
export const GET_ACCOUNTS_FAILURE = "GET_ACCOUNTS_FAILURE";

export const LOAD_ACCOUNTS_REQUEST = "LOAD_ACCOUNTS_REQUEST";
export const LOAD_ACCOUNTS_SUCCESS = "LOAD_ACCOUNTS_SUCCESS";
export const LOAD_ACCOUNTS_FAILURE = "LOAD_ACCOUNTS_FAILURE";

export const UPDATE_ACCOUNT_REQUEST = "UPDATE_ACCOUNT_REQUEST";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAILURE = "UPDATE_ACCOUNT_FAILURE";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export const ADD_ACCOUNT_REQUEST = "ADD_ACCOUNT_REQUEST";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_FAILURE = "ADD_ACCOUNT_FAILURE";

export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const SELECT_ACCOUNT = "SELECT_ACCOUNT";
export const CLEAR_ACCOUNT = "CLEAR_ACCOUNT";

// Roles de usuario
export const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";

// Nueva Reservacion
export const GET_AVAILABLE_REQUEST = "GET_AVAILABLE_REQUEST";
export const GET_AVAILABLE_SUCCESS = "GET_AVAILABLE_SUCCESS";
export const GET_AVAILABLE_FAILURE = "GET_AVAILABLE_FAILURE";

export const UPLOAD_RESERVATION_START = "UPLOAD_RESERVATION_START";
export const UPLOAD_RESERVATION_REQUEST = "UPLOAD_RESERVATION_REQUEST";
export const UPLOAD_RESERVATION_SUCCESS = "UPLOAD_RESERVATION_SUCCESS";
export const UPLOAD_RESERVATION_FAILURE = "UPLOAD_RESERVATION_FAILURE";

// Reservaciones
export const GET_RESERVATIONS_REQUEST = "GET_RESERVATIONS_REQUEST";
export const GET_RESERVATIONS_SUCCESS = "GET_RESERVATIONS_SUCCESS";
export const GET_RESERVATIONS_FAILURE = "GET_RESERVATIONS_FAILURE";

export const LOAD_RESERVATIONS_REQUEST = "LOAD_RESERVATIONS_REQUEST";
export const LOAD_RESERVATIONS_SUCCESS = "LOAD_RESERVATIONS_SUCCESS";
export const LOAD_RESERVATIONS_FAILURE = "LOAD_RESERVATIONS_FAILURE";

export const GET_RESERVATION_BY_ID_REQUEST = "GET_RESERVATION_BY_ID_REQUEST";
export const GET_RESERVATION_BY_ID_SUCCESS = "GET_RESERVATION_BY_ID_SUCCESS";
export const GET_RESERVATION_BY_ID_FAILURE = "GET_RESERVATION_BY_ID_FAILURE";

export const CLEAR_RESERVATION_SELECTED = "CLEAR_RESERVATION_SELECTED";

export const UPDATE_GUEST_REQUEST = "UPDATE_GUEST_REQUEST";
export const UPDATE_GUEST_SUCCESS = "UPDATE_GUEST_SUCCESS";
export const UPDATE_GUEST_FAILURE = "UPDATE_GUEST_FAILURE";

export const UPDATE_DATES_REQUEST = "UPDATE_DATES_REQUEST";
export const UPDATE_DATES_SUCCESS = "UPDATE_DATES_SUCCESS";
export const UPDATE_DATES_FAILURE = "UPDATE_DATES_FAILURE";

export const UPDATE_RESERVATION_COMMENT_REQUEST =
  "UPDATE_RESERVATION_COMMENT_REQUEST";
export const UPDATE_RESERVATION_COMMENT_SUCCESS =
  "UPDATE_RESERVATION_COMMENT_SUCCESS";
export const UPDATE_RESERVATION_COMMENT_FAILURE =
  "UPDATE_RESERVATION_COMMENT_FAILURE";

export const GET_FULL_AVAILABILITY_REQUEST = "GET_FULL_AVAILABILITY_REQUEST";
export const GET_FULL_AVAILABILITY_SUCCESS = "GET_FULL_AVAILABILITY_SUCCESS";
export const GET_FULL_AVAILABILITY_FAILURE = "GET_FULL_AVAILABILITY_FAILURE";

export const UPDATE_RESERVATION_ROOMS_REQUEST =
  "UPDATE_RESERVATION_ROOMS_REQUEST";
export const UPDATE_RESERVATION_ROOMS_SUCCESS =
  "UPDATE_RESERVATION_ROOMS_SUCCESS";
export const UPDATE_RESERVATION_ROOMS_FAILURE =
  "UPDATE_RESERVATION_ROOMS_FAILURE";

export const UPDATE_CARD_REQUEST = "UPDATE_CARD_REQUEST";
export const UPDATE_CARD_SUCCESS = "UPDATE_CARD_SUCCESS";
export const UPDATE_CARD_FAILURE = "UPDATE_CARD_FAILURE";

export const DELETE_CARD_REQUEST = "DELETE_CARD_REQUEST";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const CHECK_IN_REQUEST = "CHECK_IN_REQUEST";
export const CHECK_IN_SUCCESS = "CHECK_IN_SUCCESS";
export const CHECK_IN_FAILURE = "CHECK_IN_FAILURE";

export const CHECK_OUT_REQUEST = "CHECK_OUT_REQUEST";
export const CHECK_OUT_SUCCESS = "CHECK_OUT_SUCCESS";
export const CHECK_OUT_FAILURE = "CHECK_OUT_FAILURE";

export const ADD_PRODUCT_TO_RESERVATION_REQUEST =
  "ADD_PRODUCT_TO_RESERVATION_REQUEST";
export const ADD_PRODUCT_TO_RESERVATION_SUCCESS =
  "ADD_PRODUCT_TO_RESERVATION_SUCCESS";
export const ADD_PRODUCT_TO_RESERVATION_FAILURE =
  "ADD_PRODUCT_TO_RESERVATION_FAILURE";

export const REMOVE_PRODUCT_TO_RESERVATION_REQUEST =
  "REMOVE_PRODUCT_TO_RESERVATION_REQUEST";
export const REMOVE_PRODUCT_TO_RESERVATION_SUCCESS =
  "REMOVE_PRODUCT_TO_RESERVATION_SUCCESS";
export const REMOVE_PRODUCT_TO_RESERVATION_FAILURE =
  "REMOVE_PRODUCT_TO_RESERVATION_FAILURE";

export const ADD_SERVICE_TO_RESERVATION_REQUEST =
  "ADD_SERVICE_TO_RESERVATION_REQUEST";
export const ADD_SERVICE_TO_RESERVATION_SUCCESS =
  "ADD_SERVICE_TO_RESERVATION_SUCCESS";
export const ADD_SERVICE_TO_RESERVATION_FAILURE =
  "ADD_SERVICE_TO_RESERVATION_FAILURE";

export const REMOVE_SERVICE_TO_RESERVATION_REQUEST =
  "REMOVE_SERVICE_TO_RESERVATION_REQUEST";
export const REMOVE_SERVICE_TO_RESERVATION_SUCCESS =
  "REMOVE_SERVICE_TO_RESERVATION_SUCCESS";
export const REMOVE_SERVICE_TO_RESERVATION_FAILURE =
  "REMOVE_SERVICE_TO_RESERVATION_FAILURE";

export const ADD_PAYMENT_REQUEST = "ADD_PAYMENT_REQUEST";
export const ADD_PAYMENT_SUCCESS = "ADD_PAYMENT_SUCCESS";
export const ADD_PAYMENT_FAILURE = "ADD_PAYMENT_FAILURE";

export const REMOVE_PAYMENT_REQUEST = "REMOVE_PAYMENT_REQUEST";
export const REMOVE_PAYMENT_SUCCESS = "REMOVE_PAYMENT_SUCCESS";
export const REMOVE_PAYMENT_FAILURE = "REMOVE_PAYMENT_FAILURE";

export const ADD_EXTRA_REQUEST = "ADD_EXTRA_REQUEST";
export const ADD_EXTRA_SUCCESS = "ADD_EXTRA_SUCCESS";
export const ADD_EXTRA_FAILURE = "ADD_EXTRA_FAILURE";

export const REMOVE_EXTRA_REQUEST = "REMOVE_EXTRA_REQUEST";
export const REMOVE_EXTRA_SUCCESS = "REMOVE_EXTRA_SUCCESS";
export const REMOVE_EXTRA_FAILURE = "REMOVE_EXTRA_FAILURE";

export const CANCEL_RESERVATION_REQUEST = "CANCEL_RESERVATION_REQUEST";
export const CANCEL_RESERVATION_SUCCESS = "CANCEL_RESERVATION_SUCCESS";
export const CANCEL_RESERVATION_FAILURE = "CANCEL_RESERVATION_FAILURE";

export const FORCE_CANCEL_RESERVATION_REQUEST =
  "FORCE_CANCEL_RESERVATION_REQUEST";
export const FORCE_CANCEL_RESERVATION_SUCCESS =
  "FORCE_CANCEL_RESERVATION_SUCCESS";
export const FORCE_CANCEL_RESERVATION_FAILURE =
  "FORCE_CANCEL_RESERVATION_FAILURE";

export const CLEAR_RESERVATION_ERRORS = "CLEAR_RESERVATION_ERRORS";

// History
export const GET_HISTORY_REQUEST = "GET_HISTORY_REQUEST";
export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS";
export const GET_HISTORY_FAILURE = "GET_HISTORY_FAILURE";

export const LOAD_HISTORY_REQUEST = "LOAD_HISTORY_REQUEST";
export const LOAD_HISTORY_SUCCESS = "LOAD_HISTORY_SUCCESS";
export const LOAD_HISTORY_FAILURE = "LOAD_HISTORY_FAILURE";

export const GET_HISTORY_BY_ID_REQUEST = "GET_HISTORY_BY_ID_REQUEST";
export const GET_HISTORY_BY_ID_SUCCESS = "GET_HISTORY_BY_ID_SUCCESS";
export const GET_HISTORY_BY_ID_FAILURE = "GET_HISTORY_BY_ID_FAILURE";

// Errores y mensajes en snackbar
export const NUEVO_MENSAJE = "NUEVO_MENSAJE";
export const LIMPIAR_MENSAJE = "LIMPIAR_MENSAJE";

// Contrato
export const GET_RES_INFO_REQUEST = "GET_RES_INFO_REQUEST";
export const GET_RES_INFO_SUCCESS = "GET_RES_INFO_SUCCESS";
export const GET_RES_INFO_FAILURE = "GET_RES_INFO_FAILURE";

// Tipos de pago
export const GET_PAYMENT_TYPES_REQUEST = "GET_PAYMENT_TYPES_REQUEST";
export const GET_PAYMENT_TYPES_SUCCESS = "GET_PAYMENT_TYPES_SUCCESS";
export const GET_PAYMENT_TYPES_FAILURE = "GET_PAYMENT_TYPES_FAILURE";

// Reservaciones pendientes
export const GET_PENDING_RESERVATION_REQUEST =
  "GET_PENDING_RESERVATION_REQUEST";
export const GET_PENDING_RESERVATION_SUCCESS =
  "GET_PENDING_RESERVATION_SUCCESS";
export const GET_PENDING_RESERVATION_FAILURE =
  "GET_PENDING_RESERVATION_FAILURE";

export const LOAD_PENDING_RESERVATION_REQUEST =
  "LOAD_PENDING_RESERVATION_REQUEST";
export const LOAD_PENDING_RESERVATION_SUCCESS =
  "LOAD_PENDING_RESERVATION_SUCCESS";
export const LOAD_PENDING_RESERVATION_FAILURE =
  "LOAD_PENDING_RESERVATION_FAILURE";

export const GET_PENDING_RESERVATION_BY_ID_REQUEST =
  "GET_PENDING_RESERVATION_BY_ID_REQUEST";
export const GET_PENDING_RESERVATION_BY_ID_SUCCESS =
  "GET_PENDING_RESERVATION_BY_ID_SUCCESS";
export const GET_PENDING_RESERVATION_BY_ID_FAILURE =
  "GET_PENDING_RESERVATION_BY_ID_FAILURE";

export const CONFIRM_RESERVATION_REQUEST = "CONFIRM_RESERVATION_REQUEST";
export const CONFIRM_RESERVATION_SUCCESS = "CONFIRM_RESERVATION_SUCCESS";
export const CONFIRM_RESERVATION_FAILURE = "CONFIRM_RESERVATION_FAILURE";

export const CANCEL_PENDING_RESERVATION_REQUEST =
  "CANCEL_PENDING_RESERVATION_REQUEST";
export const CANCEL_PENDING_RESERVATION_SUCCESS =
  "CANCEL_PENDING_RESERVATION_SUCCESS";
export const CANCEL_PENDING_RESERVATION_FAILURE =
  "CANCEL_PENDING_RESERVATION_FAILURE";

export const CLEAR_PENDING_RESERVATION_SELECTED =
  "CLEAR_PENDING_RESERVATION_SELECTED";
