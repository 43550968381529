import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_HISTORY_REQUEST,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAILURE,
  LOAD_HISTORY_REQUEST,
  LOAD_HISTORY_SUCCESS,
  LOAD_HISTORY_FAILURE,
  GET_HISTORY_BY_ID_REQUEST,
  GET_HISTORY_BY_ID_SUCCESS,
  GET_HISTORY_BY_ID_FAILURE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* getHistory({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "historial?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&orderBy=" +
        payload.orderBy +
        "&order=" +
        payload.order +
        "&search=" +
        payload.search +
        "&desde=" +
        payload.desde +
        "&hasta=" +
        payload.hasta,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_HISTORY_SUCCESS, results, search: payload.search });
  } catch (error) {
    yield put({ type: GET_HISTORY_FAILURE, error });
  }
}

export function* loadHistory({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "historial?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&orderBy=" +
        payload.orderBy +
        "&order=" +
        payload.order +
        "&search=" +
        payload.search +
        "&desde=" +
        payload.desde +
        "&hasta=" +
        payload.hasta,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: LOAD_HISTORY_SUCCESS, results });
  } catch (error) {
    yield put({ type: LOAD_HISTORY_FAILURE, error });
  }
}

export function* getByID({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "historial/" + payload.id,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_HISTORY_BY_ID_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_HISTORY_BY_ID_FAILURE, error });
  }
}

export default function* historial() {
  yield takeLatest(GET_HISTORY_REQUEST, getHistory);
  yield takeLatest(LOAD_HISTORY_REQUEST, loadHistory);
  yield takeLatest(GET_HISTORY_BY_ID_REQUEST, getByID);
}
