import {
  GET_HISTORY_REQUEST,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAILURE,
  LOAD_HISTORY_REQUEST,
  LOAD_HISTORY_SUCCESS,
  LOAD_HISTORY_FAILURE,
  USER_LOGOUT_SUCCESS
} from "../../consts/actionTypes";

const initialState = {
  lista: [],
  total: 1,
  actual: 0,
  search: "",
  isLoading: false,
  isLoadingMore: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_HISTORY_REQUEST:
      return { ...state, isLoading: true };
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lista: action.results.data.data,
        search: action.search,
        page: 1,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case GET_HISTORY_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case LOAD_HISTORY_REQUEST:
      return { ...state, isLoadingMore: true };
    case LOAD_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingMore: false,
        lista: state.lista.concat(action.results.data.data),
        page: action.results.data.current_page,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case LOAD_HISTORY_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoadingMore: false };
    case USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return { ...state };
  }
}
