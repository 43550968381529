import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import { getSelect } from "../../redux/actions/tiposCuarto";

export default function RoomTypesSelect({ value, handleChange }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.tiposCuarto.isLoadingSelect);
  const cuartos = useSelector(state => state.tiposCuarto.select);
  const hotel_id = useSelector(state => state.user.hotel_id);

  React.useEffect(() => {
    if (cuartos.length < 1) {
      dispatch(
        getSelect({
          id: hotel_id,
          header: {
            Authorization:
              localStorage.getItem("token_type") +
              " " +
              localStorage.getItem("access_token")
          }
        })
      );
    }
  });

  return (
    <TextField
      select
      value={value}
      onChange={e => handleChange(e.target.value)}
      variant="outlined"
      fullWidth
      margin="dense"
    >
      <MenuItem value={0} disabled>
        Selecciona el tipo de cuarto.
      </MenuItem>
      {isLoading ? (
        <MenuItem disabled value={value} selected>
          Cargando...
        </MenuItem>
      ) : (
        cuartos.map(cuarto => (
          <MenuItem value={cuarto.id} key={cuarto.id}>
            {cuarto.nombre_hab.es}
          </MenuItem>
        ))
      )}
    </TextField>
  );
}

RoomTypesSelect.propTypes = {
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired
};
