import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles(theme => ({
  header: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    display: "flex",
    alignContent: "center"
  },
  verticalSpacing: {
    height: theme.spacing(2)
  },
  cargarBox: {
    display: "flex",
    justifyContent: "center"
  },
  toolbar: {
    display: "flex"
  },
  avatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  listo: {
    backgroundColor: "#00c853",
    color: "#fff"
  },
  ocupado: {
    backgroundColor: "#304ffe",
    color: "#fff"
  },
  sucio: {
    backgroundColor: "#424242",
    color: "#fff"
  },
  fuera: {
    backgroundColor: "#757575",
    color: "#fff"
  },
  limpieza: {
    backgroundColor: "#ff8a80",
    color: "#fff"
  },
  listStyle: {
    "&:nth-of-type(even)": { backgroundColor: "#f2f2f2" },
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));
