import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress'

import { updateGuest } from "../../redux/actions/reservaciones";
import styles from "./style";
import NumberTextField from "../../components/NumberTextField";

function FormularioCliente({ open, handleClose, huesped, res }) {
  const classes = styles();
  const dispatch = useDispatch();
  const inputRef = React.useRef(null);

  const isUpdating = useSelector(state => state.reservaciones.isUpdating);

  const [nombre, setNombre] = React.useState(
    huesped ? huesped.nombre || "" : ""
  );
  const [apellido, setApellido] = React.useState(
    huesped ? huesped.apellido || "" : ""
  );
  const [email, setEmail] = React.useState(huesped ? huesped.email || "" : "");
  const [telefono, setTelefono] = React.useState(
    huesped ? huesped.telefono || "" : ""
  );
  const [compania, setCompania] = React.useState(
    huesped ? huesped.compania || "" : ""
  );
  const [rfc, setRfc] = React.useState(huesped ? huesped.rfc || "" : "");
  const [direccion, setDireccion] = React.useState(
    huesped ? huesped.direccion || "" : ""
  );
  const [direccion2, setDireccion2] = React.useState(
    huesped ? huesped.direccion2 || "" : ""
  );
  const [codigo_postal, setCodigoPostal] = React.useState(
    huesped ? huesped.codigo_postal || "" : ""
  );
  const [ciudad, setCiudad] = React.useState(
    huesped ? huesped.ciudad || "" : ""
  );
  const [estado, setEstado] = React.useState(
    huesped ? huesped.estado || "" : ""
  );
  const [pais, setPais] = React.useState(huesped ? huesped.pais || "" : "");

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(
      updateGuest({
        id: huesped.id,
        res: res,
        guest: {
          nombre: nombre,
          apellido: apellido,
          email: email,
          telefono: telefono,
          compania: compania,
          rfc: rfc,
          direccion: direccion,
          direccion2: direccion2,
          codigo_postal: codigo_postal,
          ciudad: ciudad,
          estado: estado,
          pais: pais
        },
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Editar información del cliente
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Typography variant="body1">
            Nombre{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
          <TextField
            autoFocus
            id="input-nombre"
            fullWidth
            variant="outlined"
            value={nombre}
            onChange={({ target }) => setNombre(target.value.toUpperCase())}
            inputProps={{
              type: "text",
              maxLength: 64
            }}
            margin="dense"
            className={classes.input}
            required
          />
          <Typography variant="body1">
            Apellido{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
          <TextField
            id="input-apellido"
            fullWidth
            variant="outlined"
            value={apellido}
            onChange={({ target }) => setApellido(target.value.toUpperCase())}
            inputProps={{
              type: "text",
              maxLength: 64
            }}
            margin="dense"
            className={classes.input}
            required
          />
          <Typography variant="body1">
            Correo electrónico{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
          <TextField
            id="input-email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            inputProps={{
              type: "email",
              maxLength: 254
            }}
            margin="dense"
            className={classes.input}
            required
          />
          <Typography variant="body1">
            Teléfono{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
          <NumberTextField
            id="input-telefono"
            fullWidth
            variant="outlined"
            value={telefono}
            onChange={value => setTelefono(value)}
            maxLength={16}
            margin="dense"
            className={classes.input}
            required
          />
          <div className={classes.verticalSpacing} />
          <Typography variant="h6" gutterBottom>
            Datos de facturación (Opcional)
          </Typography>
          <Typography variant="body1">Facturar a</Typography>
          <TextField
            id="input-compania"
            fullWidth
            variant="outlined"
            value={compania}
            onChange={({ target }) => setCompania(target.value)}
            inputProps={{
              type: "text",
              maxLength: 128
            }}
            margin="dense"
            className={classes.input}
          />
          <Typography variant="body1">RFC</Typography>
          <TextField
            id="input-rfc"
            fullWidth
            variant="outlined"
            value={rfc}
            onChange={({ target }) => setRfc(target.value)}
            inputProps={{
              type: "text",
              maxLength: 24
            }}
            margin="dense"
            className={classes.input}
          />
          <div>
            <Typography variant="body1">Dirección</Typography>
            <TextField
              id="input-direccion"
              fullWidth
              variant="outlined"
              value={direccion}
              onChange={({ target }) =>
                setDireccion(target.value.toUpperCase())
              }
              rows={2}
              rowsMax={4}
              multiline
              inputProps={{
                type: "text",
                maxLength: 128
              }}
              margin="dense"
              className={classes.input}
            />
          </div>
          <div>
            <Typography variant="body1">Dirección cont.</Typography>
            <TextField
              id="input-direccion2"
              fullWidth
              variant="outlined"
              value={direccion2}
              onChange={({ target }) =>
                setDireccion2(target.value.toUpperCase())
              }
              rows={2}
              rowsMax={4}
              multiline
              inputProps={{
                type: "text",
                maxLength: 128
              }}
              margin="dense"
              className={classes.input}
            />
          </div>
          <Typography variant="body1">C.P/ZIP</Typography>
          <TextField
            id="input-cp"
            fullWidth
            variant="outlined"
            value={codigo_postal}
            onChange={({ target }) =>
              setCodigoPostal(target.value.toUpperCase())
            }
            inputProps={{
              type: "text",
              maxLength: 16
            }}
            margin="dense"
            className={classes.input}
          />
          <Typography variant="body1">Ciudad</Typography>
          <TextField
            id="input-ciudad"
            fullWidth
            variant="outlined"
            value={ciudad}
            onChange={({ target }) => setCiudad(target.value.toUpperCase())}
            inputProps={{
              type: "text",
              maxLength: 189
            }}
            margin="dense"
            className={classes.input}
          />
          <Typography variant="body1">Estado</Typography>
          <TextField
            id="input-estado"
            fullWidth
            variant="outlined"
            value={estado}
            onChange={({ target }) => setEstado(target.value.toUpperCase())}
            inputProps={{
              type: "text",
              maxLength: 90
            }}
            margin="dense"
            className={classes.input}
          />
          <Typography variant="body1">País</Typography>
          <TextField
            id="input-pais"
            fullWidth
            variant="outlined"
            value={pais}
            onChange={({ target }) => setPais(target.value.toUpperCase())}
            inputProps={{
              type: "text",
              maxLength: 90
            }}
            margin="dense"
            className={classes.input}
          />
          <button hidden type="submit" ref={inputRef} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => inputRef.current.click()} color="primary" disabled={isUpdating}>
          Guardar {isUpdating && <CircularProgress size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FormularioCliente.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  huesped: PropTypes.object,
  res: PropTypes.number
};

export default FormularioCliente;
