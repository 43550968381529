import {
  GET_ROOMTYPES_REQUEST,
  GET_ROOMTYPES_SUCCESS,
  GET_ROOMTYPES_FAILURE,
  LOAD_ROOMTYPES_REQUEST,
  LOAD_ROOMTYPES_SUCCESS,
  LOAD_ROOMTYPES_FAILURE,
  GET_ROOMTYPE_BY_ID_REQUEST,
  GET_ROOMTYPE_BY_ID_SUCCESS,
  GET_ROOMTYPE_BY_ID_FAILURE,
  UPDATE_ROOMTYPE_REQUEST,
  UPDATE_ROOMTYPE_SUCCESS,
  UPDATE_ROOMTYPE_FAILURE,
  ADD_ROOMTYPE_REQUEST,
  ADD_ROOMTYPE_SUCCESS,
  ADD_ROOMTYPE_FAILURE,
  DELETE_ROOMTYPE_REQUEST,
  DELETE_ROOMTYPE_SUCCESS,
  DELETE_ROOMTYPE_FAILURE,
  GET_ROOMTYPES_FOR_SELECT_REQUEST,
  GET_ROOMTYPES_FOR_SELECT_SUCCESS,
  GET_ROOMTYPES_FOR_SELECT_FAILURE,
  UPLOAD_ROOMTYPE_IMAGE_REQUEST,
  UPLOAD_ROOMTYPE_IMAGE_SUCCESS,
  UPLOAD_ROOMTYPE_IMAGE_FAILURE,
  UPLOAD_ROOMTYPE_IMAGE_FINISH,
  ADD_GALLERY_IMAGE_REQUEST,
  ADD_GALLERY_IMAGE_SUCCESS,
  ADD_GALLERY_IMAGE_FAILURE,
  USER_LOGOUT_SUCCESS
} from "../../consts/actionTypes";

const initialState = {
  lista: [],
  seleccionado: null,
  total: 1,
  actual: 0,
  select: [],
  search: "",
  isLoading: true,
  isLoadingMore: false,
  isUploading: false,
  isDeleting: false,
  isLoadingSelect: false,
  isUploadingImage: false,
  isGalleryLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ROOMTYPES_REQUEST:
      return { ...state, isLoading: true };
    case GET_ROOMTYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lista: action.results.data.data,
        search: action.search,
        page: 1,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case GET_ROOMTYPES_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case LOAD_ROOMTYPES_REQUEST:
      return { ...state, isLoadingMore: true };
    case LOAD_ROOMTYPES_SUCCESS:
      return {
        ...state,
        isLoadingMore: false,
        lista: state.lista.concat(action.results.data.data),
        page: action.results.data.current_page,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case LOAD_ROOMTYPES_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoadingMore: false };
    case GET_ROOMTYPE_BY_ID_REQUEST:
      return { ...state, isLoading: true };
    case GET_ROOMTYPE_BY_ID_SUCCESS:
      return { ...state, isLoading: false, seleccionado: action.results.data };
    case GET_ROOMTYPE_BY_ID_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case UPDATE_ROOMTYPE_REQUEST:
      return { ...state, isLoading: true };
    case UPDATE_ROOMTYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        seleccionado: action.results.data,
        lista: state.lista.map(cuarto => {
          if (cuarto.id === action.results.data.id) {
            return action.results.data;
          }
          return cuarto;
        })
      };
    case UPDATE_ROOMTYPE_FAILURE:
      return { ...state, error: action.error, isLoading: false };
    case ADD_ROOMTYPE_REQUEST:
      return { ...state, isUploading: true };
    case ADD_ROOMTYPE_SUCCESS:
      return {
        ...state,
        isUploading: false,
        lista: [action.results.data, ...state.lista]
      };
    case ADD_ROOMTYPE_FAILURE:
      return { ...state, error: action.error, isUploading: false };
    case DELETE_ROOMTYPE_REQUEST:
      return { ...state, isDeleting: true };
    case DELETE_ROOMTYPE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        lastDeleted: action.results.data.id,
        lista: state.lista.filter(
          cuarto => cuarto.id.toString() !== action.results.data.id
        )
      };
    case DELETE_ROOMTYPE_FAILURE:
      return { ...state, error: action.error, isDeleting: false };
    case GET_ROOMTYPES_FOR_SELECT_REQUEST:
      return { ...state, isLoadingSelect: true };
    case GET_ROOMTYPES_FOR_SELECT_SUCCESS:
      return {
        ...state,
        isLoadingSelect: false,
        select: action.results.data
      };
    case GET_ROOMTYPES_FOR_SELECT_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoadingSelect: false };
    case UPLOAD_ROOMTYPE_IMAGE_REQUEST:
      return { ...state, isUploadingImage: true };
    case UPLOAD_ROOMTYPE_IMAGE_SUCCESS:
      return {
        ...state,
        isUploadingImage: false,
        seleccionado: action.results.data,
        lastUploaded: action.results.data.image_url,
        lista: state.lista.map(cuarto => {
          if (cuarto.id === action.results.data.id) {
            return action.results.data;
          }
          return cuarto;
        })
      };
    case UPLOAD_ROOMTYPE_IMAGE_FAILURE:
      return { ...state, error: action.error, isUploadingImage: false };
    case UPLOAD_ROOMTYPE_IMAGE_FINISH:
      return { ...state, lastUploaded: null };
    case ADD_GALLERY_IMAGE_REQUEST:
      return { ...state, isGalleryLoading: true };
    case ADD_GALLERY_IMAGE_SUCCESS:
      return {
        ...state,
        isGalleryLoading: false,
        seleccionado: {
          ...state.seleccionado,
          imagenes: [action.results.data, ...state.seleccionado.imagenes]
        }
      };
    case ADD_GALLERY_IMAGE_FAILURE:
      return { ...state, isGalleryLoading: null };
    case USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return { ...state };
  }
}
