import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import InboxIcon from "@material-ui/icons/Inbox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import styles from "./style";

import { clear, update } from "../../redux/actions/mensajes";

export default function Contenido() {
  const classes = styles();
  const dispatch = useDispatch();
  const seleccionado = useSelector(state => state.mensajes.seleccionado);
  const isUpdating = useSelector(state => state.mensajes.isUpdating);

  if (Boolean(seleccionado)) {
    return (
      <div
        className={classes.mensaje}
        style={{
          backgroundColor: seleccionado.atendido ? "#f5f5f5" : "#F8FEFF"
        }}
      >
        <div className={classes.header}>
          <IconButton onClick={() => dispatch(clear())}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="subtitle1">
            <b>De:</b> {seleccionado.nombre}
          </Typography>
          <div style={{ flexGrow: 1 }} />
          <IconButton
            disabled={isUpdating}
            onClick={() =>
              dispatch(
                update({
                  id: seleccionado.id,
                  message: { atendido: !seleccionado.atendido },
                  header: {
                    Authorization:
                      localStorage.getItem("token_type") +
                      " " +
                      localStorage.getItem("access_token")
                  }
                })
              )
            }
          >
            {seleccionado.atendido ? (
              <CheckBoxIcon style={{ color: "#00c853" }} />
            ) : (
              <CheckBoxOutlineBlankIcon />
            )}
          </IconButton>
        </div>
        <div className={classes.section}>
          <Typography variant="subtitle1">
            <b>Email:</b> {seleccionado.email}
          </Typography>
        </div>
        <div className={classes.body}>
          <Typography variant="subtitle1">{seleccionado.mensaje}</Typography>
        </div>
      </div>
    );
  }
  return (
    <div className={classes.empty}>
      <InboxIcon fontSize="large" style={{ marginRight: 8 }} />
      <Typography variant="h6">
        Clic en un mensaje para ver su contenido
      </Typography>
    </div>
  );
}
