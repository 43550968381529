import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  LOAD_PRODUCTS_REQUEST,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_FAILURE,
  GET_PRODUCT_BY_ID_REQUEST,
  GET_PRODUCT_BY_ID_SUCCESS,
  GET_PRODUCT_BY_ID_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  GET_ALL_PRODUCTS_REQUEST,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FAILURE,
  USER_LOGOUT_SUCCESS
} from "../../consts/actionTypes";

const initialState = {
  lista: [],
  seleccionado: null,
  total: 1,
  actual: 0,
  search: "",
  isLoading: false,
  isLoadingMore: false,
  isUploading: false,
  isDeleting: false,
  select: null,
  isFetching: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS_REQUEST:
      return { ...state, isLoading: true };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lista: action.results.data.data,
        search: action.search,
        page: 1,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case GET_PRODUCTS_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case LOAD_PRODUCTS_REQUEST:
      return { ...state, isLoadingMore: true };
    case LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoadingMore: false,
        lista: state.lista.concat(action.results.data.data),
        page: action.results.data.current_page,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case LOAD_PRODUCTS_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoadingMore: false };
    case GET_PRODUCT_BY_ID_REQUEST:
      return { ...state, isLoading: true };
    case GET_PRODUCT_BY_ID_SUCCESS:
      return { ...state, isLoading: false, seleccionado: action.results.data };
    case GET_PRODUCT_BY_ID_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case UPDATE_PRODUCT_REQUEST:
      return { ...state, isLoading: true };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        seleccionado: action.results.data,
        lista: state.lista.map(producto => {
          if (producto.id === action.results.data.id) {
            return action.results.data;
          }
          return producto;
        })
      };
    case UPDATE_PRODUCT_FAILURE:
      return { ...state, error: action.error, isLoading: false };
    case ADD_PRODUCT_REQUEST:
      return { ...state, isUploading: true };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        isUploading: false,
        lista: [action.results.data, ...state.lista]
      };
    case ADD_PRODUCT_FAILURE:
      return { ...state, error: action.error, isUploading: false };
    case DELETE_PRODUCT_REQUEST:
      return { ...state, isDeleting: true };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        lastDeleted: action.results.data.id,
        lista: state.lista.filter(
          producto => producto.id.toString() !== action.results.data.id
        )
      };
    case DELETE_PRODUCT_FAILURE:
      return { ...state, error: action.error, isDeleting: false };
    case GET_ALL_PRODUCTS_REQUEST:
      return { ...state, isFetching: true, select: null };
    case GET_ALL_PRODUCTS_SUCCESS:
      return { ...state, isFetching: false, select: action.results.data };
    case GET_ALL_PRODUCTS_FAILURE:
      console.log(action.error);
      return { ...state, isFetching: false, error: action.error };
    case USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return { ...state };
  }
}
