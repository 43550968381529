import axios from "axios";

const baseURL = "https://hotelws.adftec.com/api/";

export const apiCall = (url, data, headers, method) =>
  axios({
    method,
    url: baseURL + url,
    data,
    headers
  });
