import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

import InboxIcon from "@material-ui/icons/Inbox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import EmailIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import SecurityIcon from "@material-ui/icons/Security";

import styles from "./style";
import Password from "./Password";

import { clear, del } from "../../redux/actions/usuarios";

export default function Detalles({ handleEdit }) {
  const classes = styles();
  const dispatch = useDispatch();
  const seleccionado = useSelector(state => state.usuarios.seleccionado);
  const isUpdating = useSelector(state => state.usuarios.isUpdating);
  const isDeleting = useSelector(state => state.usuarios.isDeleting);

  const [pass, setPass] = React.useState(false);

  if (isUpdating) {
    return (
      <div className={classes.empty}>
        <CircularProgress />
        <Typography>Actualizando información...</Typography>
      </div>
    );
  }

  if (Boolean(seleccionado)) {
    return (
      <div className={classes.usuario}>
        <div className={classes.headerUser}>
          <IconButton onClick={() => dispatch(clear())}>
            <ArrowBackIcon />
          </IconButton>
          <div className={classes.titleUser}>
            <Avatar src={seleccionado.avatar_url} className={classes.avatar} />
            <Typography variant="h6" color="inherit">
              {seleccionado.nombre}
            </Typography>
          </div>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
        <div className={classes.verticalSpacing} />
        <List className={classes.list}>
          <ListItem>
            <ListItemIcon>
              <EmailIcon style={{ color: "#000" }} fontSize="large" />
            </ListItemIcon>
            <ListItemText primary={"Correo"} secondary={seleccionado.email} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PhoneIcon style={{ color: "#000" }} fontSize="large" />
            </ListItemIcon>
            <ListItemText
              primary={"Teléfono"}
              secondary={seleccionado.telefono}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SecurityIcon style={{ color: "#000" }} fontSize="large" />
            </ListItemIcon>
            <ListItemText
              primary={"Tipo de usuario"}
              secondary={seleccionado.user_rol.nombre}
            />
          </ListItem>
          <Divider />
          <ListItem
            style={{
              color: "#00f"
            }}
            button
            onClick={() => setPass(true)}
          >
            <ListItemIcon>
              <VpnKeyIcon style={{ color: "#00f" }} />
            </ListItemIcon>
            <ListItemText
              primary={"Cambiar contraseña"}
              primaryTypographyProps={{ color: "inherit" }}
            />
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() =>
              dispatch(
                del({
                  id: seleccionado.id,
                  header: {
                    Authorization:
                      localStorage.getItem("token_type") +
                      " " +
                      localStorage.getItem("access_token")
                  }
                })
              )
            }
            disabled={isDeleting}
          >
            <ListItemIcon>
              <DeleteIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={"Deshabilitar Cuenta"}
              primaryTypographyProps={{ color: "error" }}
            />
            {isDeleting && <ListItemIcon>
              <CircularProgress />
            </ListItemIcon>}
          </ListItem>
        </List>
        {pass && (
          <Password handleClose={() => setPass(false)} id={seleccionado.id} />
        )}
      </div>
    );
  }

  return (
    <div className={classes.empty}>
      <InboxIcon fontSize="large" style={{ marginRight: 8 }} />
      <Typography variant="h6">
        Seleccione un usuario para ver su información
      </Typography>
    </div>
  );
}
