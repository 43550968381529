import React from "react";
import PropTypes from "prop-types";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import styles from "./style";
import { moneyFormat } from "../../functions";

function ItemProducto({ producto, index, handleSelect }) {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {index > 0 && <Divider variant="inset" />}
      <ListItem
        button
        onClick={() => handleSelect(producto.id)}
        component="li"
        className={classes.listStyle}
      >
        <ListItemText
          primary={
            <React.Fragment>
              {producto.nombre}
              <Chip
                component="span"
                label={moneyFormat(producto.precio)}
                style={{ backgroundColor: "#1b5e20", color: "#fff", marginLeft: 4 }}
              />
            </React.Fragment>
          }
          primaryTypographyProps={{ variant: matches ? "subtitle1" : "h6" }}
        />
        <ListItemIcon>
          <NavigateNextIcon />
        </ListItemIcon>
      </ListItem>
    </>
  );
}

ItemProducto.propTypes = {
  producto: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired
};

export default ItemProducto;
