import React from "react";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import QueueOutlinedIcon from "@material-ui/icons/QueueOutlined";

import moment from "moment";

import styles from "./style";
import Stepper from "./Stepper";
import Fechas from "./Fechas";
import Disponibles from "./Disponibles";
import Datos from "./Datos";
import Tarjeta from "./Tarjeta";
import Resumen from "./Resumen";

export default function NuevaReservacion() {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const [step, setStep] = React.useState(0);
  const [desde, setDesde] = React.useState(
    moment().set({ hour: 1, minute: 0, second: 0, millisecond: 0 })
  );
  const [hasta, setHasta] = React.useState(moment(desde).add(1, "d"));
  const [cuartos, setCuartos] = React.useState([]);
  const [huesped, setHuesped] = React.useState({});
  const [tarjeta, setTarjeta] = React.useState(null);

  const handleStep = index => {
    switch (index) {
      case 0:
        return (
          <Fechas
            desde={desde}
            hasta={hasta}
            handleNext={results => {
              setDesde(results.desde);
              setHasta(results.hasta);
              setStep(1);
            }}
          />
        );
      case 1:
        return (
          <Disponibles
            handleNext={cuartos => {
              setCuartos(cuartos);
              setStep(2);
            }}
            handleBack={() => setStep(0)}
          />
        );
      case 2:
        return (
          <Datos
            huesped={huesped}
            handleNext={huesped => {
              setHuesped(huesped);
              setStep(3);
            }}
            handleBack={() => setStep(1)}
          />
        );
      case 3:
        return (
          <Tarjeta
            tarjeta={tarjeta}
            handleNext={tarjeta => {
              setTarjeta(tarjeta);
              setStep(4);
            }}
            handleBack={() => setStep(2)}
          />
        );
      case 4:
        return (
          <Resumen
            desde={desde}
            hasta={hasta}
            cuartos={cuartos}
            huesped={huesped}
            tarjeta={tarjeta}
            handleBack={() => setStep(3)}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className={classes.header}>
        <Typography
          variant={matches ? "h6" : "h4"}
          gutterBottom
          className={classes.title}
        >
          <QueueOutlinedIcon fontSize="inherit" style={{ marginRight: 8 }} />{" "}
          Nueva reservación
        </Typography>
        <div className={classes.verticalSpacing} />
      </div>
      <Paper elevation={matches ? 0 : 1} style={{ paddingBottom: 16 }}>
        <Stepper step={step} setStep={value => setStep(value)} />
        <div className={classes.verticalSpacing} />
        {handleStep(step)}
      </Paper>
    </>
  );
}
