import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import BackspaceIcon from "@material-ui/icons/Backspace";

import styles from "./style";
import moment from "moment";
import MomentUtils from "@date-io/moment";

import { addDate, removeDate } from "../../redux/actions/cuartos";

function FechasCerradas({ fechas, cuarto_id }) {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const isClosingDates = useSelector(state => state.cuartos.isClosingDates);
  const isRemovingDates = useSelector(state => state.cuartos.isRemovingDates);

  const [desde, setDesde] = React.useState(moment());
  const [desdeError, setDesdeError] = React.useState(null);
  const [hasta, setHasta] = React.useState(moment());
  const [hastaError, setHastaError] = React.useState(null);

  const hanldeSubmit = () => {
    if (desdeError) {
      alert("Formato de fecha invalido.");
      return;
    }
    if (hastaError) {
      alert("Formato de fecha invalido.");
      return;
    }
    dispatch(
      addDate({
        closeDate: {
          cuarto_id: cuarto_id,
          desde: desde.format("YYYY-MM-DD"),
          hasta: hasta.format("YYYY-MM-DD")
        },
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
  };

  const remove = id => {
    dispatch(
      removeDate({
        id: id,
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
  };

  return (
    <CardContent className={classes.content}>
      <div className={classes.header}>
        <Typography variant="h6">Fechas cerradas</Typography>
      </div>
      <div className={classes.verticalSpacing} />
      <div style={{ overflowX: "scroll" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Incio</TableCell>
              <TableCell>Fin</TableCell>
              <TableCell>Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                  <KeyboardDatePicker
                    disableToolbar={!matches}
                    autoOk={!matches}
                    variant={matches ? "dialog" : "inline"}
                    inputVariant="outlined"
                    format="MM/DD/YYYY"
                    margin="dense"
                    id="date-picker-desde"
                    value={desde}
                    onChange={date => setDesde(date)}
                    invalidDateMessage={"Formato de fecha invalido."}
                    KeyboardButtonProps={{
                      "aria-label": "Cambiar fecha"
                    }}
                    onError={error => setDesdeError(error)}
                  />
                </MuiPickersUtilsProvider>
              </TableCell>
              <TableCell>
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                  <KeyboardDatePicker
                    disableToolbar={!matches}
                    autoOk={!matches}
                    variant={matches ? "dialog" : "inline"}
                    inputVariant="outlined"
                    format="MM/DD/YYYY"
                    margin="dense"
                    id="date-picker-hasta"
                    value={hasta}
                    onChange={date => setHasta(date)}
                    invalidDateMessage={"Formato de fecha invalido."}
                    KeyboardButtonProps={{
                      "aria-label": "Cambiar fecha"
                    }}
                    onError={error => setHastaError(error)}
                  />
                </MuiPickersUtilsProvider>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => hanldeSubmit()}
                  disabled={isClosingDates}
                  endIcon={isClosingDates && <CircularProgress size={12} />}
                >
                  Añadir
                </Button>
              </TableCell>
            </TableRow>
            {fechas.length > 0 ? (
              fechas.map(fecha => (
                <TableRow key={fecha.id}>
                  <TableCell>
                    {moment(fecha.desde).format("dddd, MMMM Do YYYY")}
                  </TableCell>
                  <TableCell>
                    {moment(fecha.hasta).format("dddd, MMMM Do YYYY")}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => remove(fecha.id)}
                      disabled={isRemovingDates}
                    >
                      <BackspaceIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan="3" align="center">
                  No hay fechas cerradas
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </CardContent>
  );
}

FechasCerradas.propTypes = {
  fechas: PropTypes.array.isRequired,
  cuarto_id: PropTypes.number.isRequired
};

export default FechasCerradas;
