import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles(theme => ({
  loadingBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 300
  },
  verticalSpacing: {
    height: theme.spacing(2)
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  loading: {
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));
