import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles(theme => ({
  loadingBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 300
  },
  verticalSpacing: {
    height: theme.spacing(2)
  },
  card: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  imagen: {
    textAlign: "right",
    flex: 2,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingTop: "55%"
    }
  },
  details: {
    display: "flex",
    flexDirection: "column",
    flex: 4
  },
  content: {
    flex: "1 0 auto"
  },
  editarFoto: {
    backgroundColor: "#00000045",
    color: theme.palette.common.white,
    margin: 8
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  grid: {
    paddingTop: 8
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  loading: {
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dropzone: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 24,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#2196f3",
    borderStyle: "dashed",
    backgroundColor: "#e3f2fd",
    color: "#2196f3",
    cursor: "pointer"
  },
  preview: {
    maxWidth: "100%",
    marginTop: 16,
    borderWidth: 2,
    borderRadius: 8,
    borderColor: "#dddddd",
    borderStyle: "solid"
  },
  text: {
    fontSize: 16
  }
}));
