import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import InfoIcon from "@material-ui/icons/Info";
import AddIcon from "@material-ui/icons/Add";

import Mensaje from "../../components/Mensaje";
import styles from "./style";
import BotonCargar from "./BotonCargar";
import SearchTextField from "../../components/SearchTextField";

export default function Lista({ reservaciones, search, setSearch }) {
  const classes = styles();
  const history = useHistory();

  const isLoading = useSelector(state => state.reservaciones.isLoading);

  const status = state => {
    switch (state) {
      case 2:
        return (
          <Avatar style={{ color: "#fff", backgroundColor: "#43a047" }}>
            C
          </Avatar>
        );
      case 3:
        return (
          <Avatar style={{ color: "#fff", backgroundColor: "#01579b" }}>
            H
          </Avatar>
        );
      default:
        return (
          <Avatar style={{ color: "#fff", backgroundColor: "#bf360c" }}>
            E
          </Avatar>
        );
    }
  };

  return (
    <>
      <div className={classes.toolbar}>
        <div style={{ width: 8 }} />
        <SearchTextField
          search={search}
          handleTextChange={value => setSearch(value)}
        />
        <div style={{ flexGrow: 1, paddingRight: 2 }} />
        <IconButton
          variant="contained"
          color="primary"
          component={Link}
          to="/nueva"
        >
          <AddIcon />
        </IconButton>
      </div>
      <div className={classes.verticalSpacing} />
      {isLoading ? (
        <Mensaje
          leftComponent={
            <CircularProgress size={64} style={{ color: "#000" }} />
          }
          header={"Un momento..."}
          content={"Estamos buscando reservaciones."}
        />
      ) : reservaciones.length > 0 ? (
        <>
          <List>
            {reservaciones.map(reservacion => (
              <ListItem
                button
                component="li"
                key={reservacion.id}
                onClick={() =>
                  history.push("/reservaciones?code=" + reservacion.code)
                }
                className={classes.listStyle}
              >
                <ListItemAvatar>{status(reservacion.estado)}</ListItemAvatar>
                <ListItemText
                  primary={reservacion.code}
                  secondary={reservacion.huesped.nombre_completo}
                />
                <ListItemIcon>
                  <NavigateNextIcon />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
          <BotonCargar search={search} />
        </>
      ) : (
        <Mensaje
          leftComponent={<InfoIcon style={{ fontSize: 64 }} />}
          header={"No hay reservaciones."}
        />
      )}
    </>
  );
}

Lista.propTypes = {
  reservaciones: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired
};
