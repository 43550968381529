import {
  GET_ACCOUNTS_REQUEST,
  LOAD_ACCOUNTS_REQUEST,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_PASSWORD_REQUEST,
  ADD_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_REQUEST,
  SELECT_ACCOUNT,
  CLEAR_ACCOUNT
} from "../../consts/actionTypes";

export const get = payload => ({
  type: GET_ACCOUNTS_REQUEST,
  payload
});

export const load = payload => ({
  type: LOAD_ACCOUNTS_REQUEST,
  payload
});

export const update = payload => ({
  type: UPDATE_ACCOUNT_REQUEST,
  payload
});

export const updatePass = payload => ({
  type: UPDATE_PASSWORD_REQUEST,
  payload
});

export const add = payload => ({
  type: ADD_ACCOUNT_REQUEST,
  payload
});

export const del = payload => ({
  type: DELETE_ACCOUNT_REQUEST,
  payload
});

export const select = payload => ({
  type: SELECT_ACCOUNT,
  usuario: payload.usuario
});

export const clear = () => ({
  type: CLEAR_ACCOUNT
});
