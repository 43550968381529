import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import TimerIcon from "@material-ui/icons/Timer";
import SyncIcon from "@material-ui/icons/Sync";

import styles from "./style";
import SearchTextField from "../../components/SearchTextField";
import Lista from "./Lista";

import moment from "moment";
import MomentUtils from "@date-io/moment";

import { get } from "../../redux/actions/historial";

export default function Historial() {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const hotel_id = useSelector(state => state.user.hotel_id);
  const reservaciones = useSelector(state => state.historial.lista);

  const [sync, setSync] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [desde, setDesde] = React.useState(moment().startOf("month"));
  const [hasta, setHasta] = React.useState(moment().endOf("month"));
  const [order, setOrder] = React.useState("asc");

  React.useEffect(() => {
    dispatch(
      get({
        page: 1,
        hotel_id: hotel_id,
        search: search,
        desde: desde.format("YYYY-MM-DD"),
        hasta: hasta.format("YYYY-MM-DD"),
        orderBy: "desde",
        order: order,
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    setSync(false);
  }, [hotel_id, search, sync, dispatch, desde, hasta, order]);

  return (
    <>
      <div className={classes.header}>
        <Typography
          variant={matches ? "h6" : "h4"}
          gutterBottom
          className={classes.title}
        >
          <TimerIcon fontSize="inherit" style={{ marginRight: 8 }} /> Historial
        </Typography>
        <div style={{ flexGrow: 1 }} />
        <Tooltip title="Actualizar">
          <IconButton color="secondary" onClick={() => setSync(true)}>
            <SyncIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.verticalSpacing} />
      <Toolbar style={{ flexWrap: "wrap" }}>
        <SearchTextField
          search={search}
          handleTextChange={value => setSearch(value)}
        />
        <div style={{ flexGrow: 1 }} />
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
          <KeyboardDatePicker
            disableToolbar={!matches}
            autoOk={!matches}
            variant={matches ? "dialog" : "inline"}
            inputVariant="outlined"
            format="DD/MM/YYYY"
            margin="dense"
            id="date-picker-desde"
            value={desde}
            onChange={date => setDesde(date)}
            invalidDateMessage={"Formato de fecha invalido."}
            KeyboardButtonProps={{
              "aria-label": "Cambiar fecha"
            }}
            required
          />
          <KeyboardDatePicker
            disableToolbar={!matches}
            autoOk={!matches}
            variant={matches ? "dialog" : "inline"}
            inputVariant="outlined"
            format="DD/MM/YYYY"
            margin="dense"
            id="date-picker-hasta"
            value={hasta}
            onChange={date => setHasta(date)}
            invalidDateMessage={"Formato de fecha invalido."}
            KeyboardButtonProps={{
              "aria-label": "Cambiar fecha"
            }}
            required
          />
        </MuiPickersUtilsProvider>
      </Toolbar>
      <Toolbar style={{ flexWrap: "wrap" }}>
        <TextField
          select
          value={order}
          onChange={e => setOrder(e.target.value)}
          variant="outlined"
          label="Orden"
        >
          <MenuItem value="asc">Ascendente</MenuItem>
          <MenuItem value="desc">Descendente</MenuItem>
        </TextField>
      </Toolbar>
      <Lista reservaciones={reservaciones} />
    </>
  );
}
