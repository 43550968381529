import React from "react";
import PropTypes from "prop-types";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";

import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import styles from "./style";

function ItemCuarto({ cuarto, index, handleSelect }) {
  const classes = styles();

  return (
    <>
      {index > 0 && <Divider variant="inset" />}
      <ListItem
        button
        onClick={() => handleSelect(cuarto.id)}
        component="li"
        className={classes.listStyle}
      >
        <ListItemAvatar>
          <Avatar
            alt={`Avatar for${cuarto.nombre_hab.es}`}
            src={cuarto.image_url}
            className={classes.avatar}
          />
        </ListItemAvatar>
        <ListItemText
          primary={cuarto.nombre_hab.es}
          secondary={cuarto.descripcion_hab.es}
        />
        <ListItemIcon>
          <NavigateNextIcon />
        </ListItemIcon>
      </ListItem>
    </>
  );
}

ItemCuarto.propTypes = {
  cuarto: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired
};

export default ItemCuarto;
