import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import CloseIcon from "@material-ui/icons/Close";

import { update } from "../../redux/actions/productos";

import styles from "./style";

export default function Formulario({ producto, handleEdit }) {
  const classes = styles();
  const dispatch = useDispatch();

  const [precio, setPrecio] = React.useState(producto.precio);
  const [nombre, setNombre] = React.useState(producto.nombre);

  const handleUpdate = event => {
    event.preventDefault();
    dispatch(
      update({
        id: producto.id,
        product: {
          precio: precio,
          nombre: nombre
        },
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    handleEdit();
  };

  return (
    <CardContent>
      <div className={classes.header}>
        <Typography variant="h6">Editar información</Typography>
        <div style={{ flexGrow: 1 }} />
        <IconButton onClick={() => handleEdit()}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.verticalSpacing} />
      <form onSubmit={handleUpdate} autoComplete="off">
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Nombre{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-nombre"
              autoFocus
              fullWidth
              value={nombre}
              onChange={({ target }) => setNombre(target.value)}
              variant="outlined"
              margin="dense"
              inputProps={{ maxLength: 128 }}
              required
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Precio{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-precio"
              fullWidth
              value={precio}
              onChange={({ target }) => setPrecio(target.value)}
              variant="outlined"
              margin="dense"
              inputProps={{ type: "number", max: 99999999 }}
              required
            />
          </Grid>
        </Grid>
        <div className={classes.verticalSpacing} />
        <div className={classes.actions}>
          <Button variant="contained" color="secondary" type="submit">
            Guardar
          </Button>
        </div>
      </form>
    </CardContent>
  );
}

Formulario.propTypes = {
  producto: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired
};
