import {
    GET_RATES_REQUEST,
    LOAD_RATES_REQUEST,
    GET_RATE_BY_ID_REQUEST,
    UPDATE_RATE_REQUEST,
    ADD_RATE_REQUEST,
    DELETE_RATE_REQUEST
  } from "../../consts/actionTypes";

  export const get = payload => ({
    type: GET_RATES_REQUEST,
    payload
  });
  
  export const load = payload => ({
    type: LOAD_RATES_REQUEST,
    payload
  });
  
  export const getById = payload => ({
    type: GET_RATE_BY_ID_REQUEST,
    payload
  });
  
  export const update = payload => ({
    type: UPDATE_RATE_REQUEST,
    payload
  });
  
  export const add = payload => ({
    type: ADD_RATE_REQUEST,
    payload
  });
  
  export const del = payload => ({
    type: DELETE_RATE_REQUEST,
    payload
  });