import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import HotelIcon from "@material-ui/icons/Hotel";
import PeopleIcon from "@material-ui/icons/People";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import EventNoteIcon from "@material-ui/icons/EventNote";

import styles from "./style";
import { moneyFormat } from "../../functions";

import { del } from "../../redux/actions/tarifas";

export default function Detalles({ tarifa, handleEdit }) {
  const classes = styles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const isDeleting = useSelector(state => state.tarifas.isDeleting);
  const lastDeleted = useSelector(state => state.tarifas.lastDeleted);
  const [inProcess, setInProcess] = React.useState(false);

  React.useEffect(() => {
    if (inProcess && lastDeleted === tarifa.id.toString()) {
      history.goBack();
    }
  }, [inProcess, lastDeleted, history, tarifa]);

  const handleDelete = () => {
    setAnchorEl(null);
    dispatch(
      del({
        id: tarifa.id,
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    setInProcess(true);
  };

  if (isDeleting) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={64} />
        <Typography variant="subtitle1">Eliminando tarifa...</Typography>
      </div>
    );
  }
  
  return (
    <CardContent className={classes.content}>
      <div className={classes.header}>
        <Typography variant="h6">{tarifa.etiqueta}</Typography>
        <div style={{ flexGrow: 1 }} />
        <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="room-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => handleEdit()}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Editar" />
          </MenuItem>
          <MenuItem onClick={() => handleDelete()}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Eliminar" />
          </MenuItem>
        </Menu>
      </div>
      <div className={classes.verticalSpacing} />
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              alt={`Avatar for${tarifa.etiqueta}`}
              src={tarifa.tipo_cuarto.image_url}
            />
          </ListItemAvatar>
          <ListItemText
            primary="Tipo de habitación"
            secondary={tarifa.tipo_cuarto.nombre_hab.es}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <HotelIcon style={{ color: "#1b5e20" }} />
          </ListItemIcon>
          <ListItemText
            primary="Precio"
            secondary={moneyFormat(tarifa.precio)}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PeopleIcon style={{ color: "#37474f" }} />
          </ListItemIcon>
          <ListItemText
            primary="Huespedes para esta tarifa"
            secondary={tarifa.huespedes}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SwapHorizIcon style={{ color: "#0097a7" }} />
          </ListItemIcon>
          <ListItemText
            primary="Reembolsable"
            secondary={tarifa.reembolsable ? "Si" : "No"}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <EventNoteIcon style={{ color: "#0288d1" }} />
          </ListItemIcon>
          <ListItemText
            primary="Disponible desde"
            secondary={moment(tarifa.desde).format("dddd, DD MMMM YYYY")}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <EventNoteIcon style={{ color: "#bf360c" }} />
          </ListItemIcon>
          <ListItemText
            primary="Finaliza"
            secondary={
              tarifa.hasta
                ? moment(tarifa.hasta).format("dddd, DD MMMM YYYY")
                : "Sin finalización"
            }
          />
        </ListItem>
      </List>
    </CardContent>
  );
}

Detalles.propTypes = {
  tarifa: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired
};
