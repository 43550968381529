import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import InfoIcon from "@material-ui/icons/Info";
import EmailIcon from "@material-ui/icons/Email";
import DraftsIcon from "@material-ui/icons/Drafts";

import Mensaje from "../../components/Mensaje";
import styles from "./style";
import BotonCargar from "./BotonCargar";
import SearchTextField from "../../components/SearchTextField";

import { select } from "../../redux/actions/mensajes";

export default function Lista({ mensajes, search, setSearch }) {
  const classes = styles();
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.mensajes.isLoading);

  return (
    <>
      <div className={classes.toolbar}>
        <SearchTextField
          search={search}
          handleTextChange={value => setSearch(value)}
        />
      </div>
      <div className={classes.verticalSpacing} />
      {isLoading ? (
        <Mensaje
          leftComponent={
            <CircularProgress size={64} style={{ color: "#000" }} />
          }
          header={"Un momento..."}
          content={"Estamos buscando mensajes."}
        />
      ) : mensajes.length > 0 ? (
        <>
          <List>
            {mensajes.map(mensaje => (
              <ListItem
                button
                component="li"
                key={mensaje.id}
                onClick={() => dispatch(select({ mensaje: mensaje }))}
              >
                <ListItemIcon>
                  {mensaje.atendido ? (
                    <DraftsIcon />
                  ) : (
                    <EmailIcon style={{ color: "#ffc400" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={mensaje.nombre} secondary={mensaje.created_at} />
                <ListItemIcon>
                  <NavigateNextIcon />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
          <BotonCargar search={search} />
        </>
      ) : (
        <Mensaje
          leftComponent={<InfoIcon style={{ fontSize: 64 }} />}
          header={"La bandeja de entrada esta vacia"}
        />
      )}
    </>
  );
}

Lista.propTypes = {
  mensajes: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired
};
