import { all } from "redux-saga/effects";
import user from "./user";
import tiposCuarto from "./tiposCuarto";
import cuartos from "./cuartos";
import estadosCuarto from "./estadosCuarto";
import tarifas from "./tarifas";
import servicios from "./servicios";
import productos from "./productos";
import mensajes from "./mensajes";
import usuarios from "./usuarios";
import roles from "./roles";
import nuevaReservacion from "./nuevaReservacion";
import reservaciones from "./reservaciones";
import contrato from "./contrato";
import historial from "./historial";
import tiposPago from "./tiposPago";
import pendientes from "./pendientes";

export default function* rootSaga() {
  yield all([
    user(),
    tiposCuarto(),
    cuartos(),
    estadosCuarto(),
    tarifas(),
    servicios(),
    productos(),
    mensajes(),
    usuarios(),
    roles(),
    nuevaReservacion(),
    reservaciones(),
    contrato(),
    historial(),
    tiposPago(),
    pendientes()
  ]);
}
