import {
  GET_MESSSGES_REQUEST,
  LOAD_MESSSGES_REQUEST,
  UPDATE_MESSAGE_REQUEST,
  SELECT_MESSAGE,
  CLEAR_MESSAGE
} from "../../consts/actionTypes";

export const get = payload => ({
  type: GET_MESSSGES_REQUEST,
  payload
});

export const load = payload => ({
  type: LOAD_MESSSGES_REQUEST,
  payload
});

export const update = payload => ({
  type: UPDATE_MESSAGE_REQUEST,
  payload
});

export const select = payload => ({
  type: SELECT_MESSAGE,
  mensaje: payload.mensaje
});

export const clear = () => ({
  type: CLEAR_MESSAGE
});
