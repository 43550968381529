import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import { updateDates } from "../../redux/actions/reservaciones";
import MomentUtils from "@date-io/moment";
import moment from "moment";

function Fechas({ open, handleClose, desde, hasta, reservacion_id }) {
  const dispatch = useDispatch();
  const inputRef = React.useRef(null);
  const isUpdating = useSelector(state => state.reservaciones.isUpdating);
  const datesError = useSelector(state => state.reservaciones.datesError);

  const [inicio, setInicio] = React.useState(moment(desde) || moment());
  const [fin, setFin] = React.useState(moment(hasta) || moment().add(1, "d"));
  const [desdeError, setDesdeError] = React.useState(null);
  const [hastaError, setHastaError] = React.useState(null);
  const [reasignar, setReasingnar] = React.useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    if (!desdeError && !hastaError) {
      dispatch(
        updateDates({
          id: reservacion_id,
          desde: inicio.format("YYYY-MM-DD"),
          hasta: fin.format("YYYY-MM-DD"),
          reasignar: reasignar,
          header: {
            Authorization:
              localStorage.getItem("token_type") +
              " " +
              localStorage.getItem("access_token")
          }
        })
      );
    }
  };

  const ErrorText = datesError => {
    switch (datesError.type) {
      case 1:
        return (
          <DialogContentText color="error">
            No hay cuartos disponibles en las fechas indicadas.
          </DialogContentText>
        );
      case 2:
        return (
          <>
            <DialogContentText color="error">
              El cuarto asignado no esta disponible en la fecha especificada.
            </DialogContentText>
            <DialogContentText>
              Presione REASIGNAR para buscar un nuevo cuarto en las fechas
              especificadas.
            </DialogContentText>
            <Button
              onClick={async () => {
                await setReasingnar(true);
                inputRef.current.click();
              }}
            >
              Reasignar
            </Button>
          </>
        );
      default:
        return (
          <DialogContentText color="error">Ocurrio un Error</DialogContentText>
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Editar fechas de reservación
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} autoComplete="off">
          <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant={"inline"}
              inputVariant="outlined"
              format="DD/MM/YYYY"
              margin="dense"
              id="date-picker-desde"
              label="Fecha de llegada"
              value={inicio}
              onChange={date => setInicio(date)}
              invalidDateMessage={"Formato de fecha invalido."}
              KeyboardButtonProps={{
                "aria-label": "Cambiar fecha"
              }}
              onError={error => setDesdeError(error)}
              required
            />
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant={"inline"}
              inputVariant="outlined"
              format="DD/MM/YYYY"
              margin="dense"
              id="date-picker-hasta"
              label="Fecha de salida"
              minDate={moment(inicio).add(1, "d")}
              value={fin}
              onChange={date => setFin(date)}
              invalidDateMessage={"Formato de fecha invalido."}
              KeyboardButtonProps={{
                "aria-label": "Cambiar fecha"
              }}
              onError={error => setHastaError(error)}
              required
            />
          </MuiPickersUtilsProvider>
          <button hidden type="submit" ref={inputRef} />
        </form>
        {datesError && ErrorText(datesError)}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() => {
            setReasingnar(false);
            inputRef.current.click();
          }}
          color="primary"
          disabled={isUpdating}
        >
          Guardar {isUpdating && <CircularProgress size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Fechas.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  desde: PropTypes.string,
  hasta: PropTypes.string,
  reservacion_id: PropTypes.number
};

export default Fechas;
