import {
  GET_HISTORY_REQUEST,
  LOAD_HISTORY_REQUEST,
  GET_HISTORY_BY_ID_REQUEST
} from "../../consts/actionTypes";

export const get = payload => ({
  type: GET_HISTORY_REQUEST,
  payload
});

export const load = payload => ({
  type: LOAD_HISTORY_REQUEST,
  payload
});

export const getByID = payload => ({
  type: GET_HISTORY_BY_ID_REQUEST,
  payload
});
