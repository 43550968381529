import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DateRangeIcon from "@material-ui/icons/DateRange";
import PersonIcon from "@material-ui/icons/Person";
import HotelIcon from "@material-ui/icons/Hotel";
import EmailIcon from "@material-ui/icons/Email";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import styles from "./style";
import { moneyFormat } from "../../functions";

import { upload } from "../../redux/actions/nuevaReservacion";

function Resumen({ desde, hasta, cuartos, huesped, tarjeta, handleBack }) {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const history = useHistory();
  const dispatch = useDispatch();
  const hotel_id = useSelector(state => state.user.hotel_id);
  const isCompleted = useSelector(state => state.nuevaReservacion.isCompleted);
  const isUploading = useSelector(state => state.nuevaReservacion.isUploading);

  const [lng, setLng] = React.useState("es");
  const [comentarios, setComentarios] = React.useState("");

  let t = 0;
  cuartos.forEach(cuarto => {
    t = t + parseFloat(cuarto.tarifa.precio) * cuarto.cantidad;
  });
  const dias = hasta.diff(desde, "d");
  const total = t * dias;

  React.useEffect(() => {
    if (isCompleted) {
      history.push("/reservaciones?code=" + isCompleted);
    }
  });

  const onSubmit = () => {
    dispatch(
      upload({
        reservation: {
          hotel_id: hotel_id,
          desde: desde.format("YYYY-MM-DD"),
          hasta: hasta.format("YYYY-MM-DD"),
          comentarios: comentarios,
          cuartos: cuartos,
          lng: lng,
          huesped: huesped,
          tarjeta: tarjeta
        },
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
  };

  return (
    <div className={classes.stepContainer}>
      <div className={classes.header}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant={matches ? "subtitle1" : "h6"}>
          Resumen de reservación
        </Typography>
      </div>
      <div className={classes.verticalSpacing} />
      <Grid container wrap="wrap-reverse">
        <Grid item xs={12} md={6}>
          <List>
            <ListItem>
              <ListItemIcon>
                <HotelIcon />
              </ListItemIcon>
              <ListItemText primary="Habitaciones" />
            </ListItem>
            {cuartos.map((cuarto, index) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar alt="" src={cuarto.image_url} />
                </ListItemAvatar>
                <ListItemText
                  primary={cuarto.cuarto + " - cant. " + cuarto.cantidad}
                  secondary={
                    cuarto.tarifa.etiqueta +
                    " " +
                    moneyFormat(cuarto.tarifa.precio) +
                    " /noche"
                  }
                />
              </ListItem>
            ))}
            <ListItem>
              <ListItemText
                primary={"Total por noche."}
                secondary={moneyFormat(t)}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={"Dias."} secondary={dias} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Total a pagar."}
                secondary={moneyFormat(total)}
                primaryTypographyProps={{ variant: "h6" }}
                secondaryTypographyProps={{ variant: "h6" }}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <List>
            <ListItem>
              <ListItemIcon>
                <DateRangeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Fecha de llegada"
                secondary={desde.format("dddd, DD MMMM YYYY")}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DateRangeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Fecha de salida"
                secondary={hasta.format("dddd, DD MMMM YYYY")}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText
                primary="Huesped"
                secondary={huesped.nombre + " " + huesped.apellido}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText primary="Email" secondary={huesped.email} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText
                primary="Metodo de pago"
                secondary={
                  tarjeta
                    ? "*" + tarjeta.numero.substr(tarjeta.numero.length - 4)
                    : "No registrado"
                }
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <div className={classes.verticalSpacing} />
      <Typography variant="subtitle1">Enviar correo en:</Typography>
      <TextField
        select
        fullWidth
        variant="outlined"
        value={lng}
        onChange={({ target }) => setLng(target.value)}
      >
        <MenuItem value="es">Español</MenuItem>
        <MenuItem value="en">Ingles</MenuItem>
      </TextField>
      <div className={classes.verticalSpacing} />
      <Typography variant="subtitle1">Notas de la reservación</Typography>
      <TextField
        fullWidth
        variant="outlined"
        value={comentarios}
        onChange={({ target }) => setComentarios(target.value)}
        multiline
        rows={3}
        rowsMax={5}
        inputProps={{ maxLength: 255 }}
      />
      <div className={classes.verticalSpacing} />
      <div className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSubmit()}
          disabled={isUploading}
          endIcon={
            isUploading && (
              <CircularProgress size={12} style={{ marginLeft: 8 }} />
            )
          }
        >
          Registrar
        </Button>
      </div>
    </div>
  );
}

Resumen.propTypes = {
  desde: PropTypes.object.isRequired,
  hasta: PropTypes.object.isRequired,
  cuartos: PropTypes.array.isRequired,
  huesped: PropTypes.object.isRequired,
  tarjeta: PropTypes.object,
  handleBack: PropTypes.func.isRequired
};

export default Resumen;
