import React from "react";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import ExpiryTextField from "../../components/ExpiryTextField";
import NumberTextField from "../../components/NumberTextField";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import styles from "./style";

function Tarjeta({ tarjeta, handleNext, handleBack }) {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const [cardNumber, setCardNumber] = React.useState("");
  const [name, setName] = React.useState("");
  const [expiry, setExpiry] = React.useState("");
  const [cvv, setCVV] = React.useState("");
  const [maxLength, setMaxLength] = React.useState(16);
  const [issuer, setIssuer] = React.useState("");
  const [focused, setFocused] = React.useState("number");
  const [isValid, setIsValid] = React.useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    if (isValid) {
      handleNext({
        numero: cardNumber,
        nombre: name,
        mes: expiry.substr(0, 2),
        anio: expiry.substr(2, 2),
        cvv: cvv,
        tipo: issuer
      });
    } else {
      alert("El número de tarjeta no es valido.");
    }
  };

  return (
    <Container>
      <div className={classes.stepContainer}>
        <div className={classes.header}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant={matches ? "subtitle1" : "h6"}>
            Selecciona las habitaciones.
          </Typography>
        </div>
        <div className={classes.verticalSpacing} />
        <Grid container>
          <Grid item xs={12} md={5} lg={4}>
            <Cards
              name={name}
              number={cardNumber}
              expiry={expiry}
              cvc={cvv}
              acceptedCards={["visa", "mastercard", "amex"]}
              callback={(type, isValid) => {
                setIssuer(type.issuer);
                setMaxLength(type.maxLength);
                setIsValid(isValid);
              }}
              placeholders={{
                name: "TU NOMBRE"
              }}
              locale={{ valid: "Vence" }}
              focused={focused}
            />
            <div className={classes.verticalSpacing} />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            lg={8}
            style={matches ? {} : { paddingLeft: 8, paddingRight: 8 }}
          >
            <form onSubmit={handleSubmit} autoComplete="off">
              <NumberTextField
                autoFocus
                label="Número"
                value={cardNumber}
                onChange={value => setCardNumber(value)}
                maxLength={maxLength}
                onFocus={() => setFocused("number")}
                style={{ margin: "8px 4px" }}
                required
                margin="dense"
              />
              <TextField
                fullWidth
                label="Titular"
                variant="outlined"
                value={name}
                onChange={({ target }) => setName(target.value.toUpperCase())}
                inputProps={{
                  maxLength: 21
                }}
                onFocus={() => setFocused("name")}
                style={{ margin: "8px 4px" }}
                required
                margin="dense"
              />
              <Grid container>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Expiración"
                    variant="outlined"
                    value={expiry}
                    onChange={({ target }) => setExpiry(target.value)}
                    InputProps={{
                      type: "text",
                      inputComponent: ExpiryTextField
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onFocus={() => setFocused("expiry")}
                    style={{ margin: "8px 4px" }}
                    required
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberTextField
                    label="cvv"
                    value={cvv}
                    onChange={value => setCVV(value)}
                    maxLength={4}
                    onFocus={() => setFocused("cvc")}
                    style={{ margin: "8px 4px" }}
                    required
                    margin="dense"
                  />
                </Grid>
              </Grid>
              <div className={classes.actions}>
                <Button
                  variant="contained"
                  color="default"
                  fullWidth
                  onClick={() => handleNext(null)}
                >
                  Omitir
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Continuar
                </Button>
              </div>
            </form>
          </Grid>
        </Grid>
        <div className={classes.verticalSpacing} />
      </div>
    </Container>
  );
}

Tarjeta.propTypes = {
  tarjeta: PropTypes.object,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired
};

export default Tarjeta;
