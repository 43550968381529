import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import InfoIcon from "@material-ui/icons/Info";
import AddIcon from "@material-ui/icons/Add";

import Mensaje from "../../components/Mensaje";
import styles from "./style";
import BotonCargar from "./BotonCargar";
import SearchTextField from "../../components/SearchTextField";

import { select } from "../../redux/actions/usuarios";

export default function Lista({ usuarios, search, setSearch }) {
  const classes = styles();
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.usuarios.isLoading);

  return (
    <>
      <div className={classes.toolbar}>
        <SearchTextField
          search={search}
          handleTextChange={value => setSearch(value)}
        />
        <div style={{ flexGrow: 1, paddingRight: 2 }} />
        <IconButton
          variant="contained"
          color="primary"
          component={Link}
          to="/usuarios/nuevo"
        >
          <AddIcon />
        </IconButton>
      </div>
      <div className={classes.verticalSpacing} />
      {isLoading ? (
        <Mensaje
          leftComponent={
            <CircularProgress size={64} style={{ color: "#000" }} />
          }
          header={"Un momento..."}
          content={"Estamos buscando usuarios."}
        />
      ) : usuarios.length > 0 ? (
        <>
          <List>
            {usuarios.map(usuario => (
              <ListItem
                button
                component="li"
                key={usuario.id}
                onClick={() => dispatch(select({ usuario: usuario }))}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={`Avatar for${usuario.nombre}`}
                    src={usuario.avatar_url}
                    className={classes.avatar}
                  />
                </ListItemAvatar>
                <ListItemText primary={usuario.nombre} secondary={usuario.email} />
                <ListItemIcon>
                  <NavigateNextIcon />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
          <BotonCargar search={search} />
        </>
      ) : (
        <Mensaje
          leftComponent={<InfoIcon style={{ fontSize: 64 }} />}
          header={"No hay cuentas creadas."}
        />
      )}
    </>
  );
}

Lista.propTypes = {
  usuarios: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired
};
