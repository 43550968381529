import React from "react";
import PropTypes from 'prop-types'
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme";

function Mensaje({ leftComponent, header, content }) {
  const theme = useTheme();
  return (
    <div
      style={{
        backgroundColor: theme.palette.background.default,
        borderWidth: 1,
        borderColor: "#bdbdbd",
        borderStyle: "solid",
        marginTop: 16,
        padding: "8px 16px",
        display: "flex",
        alignItems: "center"
      }}
    >
      {leftComponent}
      <div style={{ marginLeft: 8 }}>
        <Typography variant="h6" component="p" align="left">
          {header}
        </Typography>
        <Typography variant="subtitle1" component="p" align="left">
          {content}
        </Typography>
      </div>
    </div>
  );
}

Mensaje.propTypes = {
    leftComponent: PropTypes.node,
    header: PropTypes.string.isRequired,
    content: PropTypes.node
}

export default Mensaje;
