import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { add } from "../../redux/actions/tiposCuarto";

import styles from "./style";

export default function Formulario() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const hotel_id = useSelector(state => state.user.hotel_id);
  const isUploading = useSelector(state => state.tiposCuarto.isUploading);

  const [nombreEs, setNombreEs] = React.useState("");
  const [nombreEn, setNombreEn] = React.useState("");
  const [descripcionEs, setDescripcionEs] = React.useState("");
  const [descripcionEn, setDescripcionEn] = React.useState("");
  const [camas, setCamas] = React.useState("");
  const [huespedes, setHuespedes] = React.useState("");
  const [isCompleted, setIsCompleted] = React.useState(false);

  React.useEffect(() => {
    if (!isUploading && isCompleted) {
      history.push("/tiposCuarto");
    }
  }, [isCompleted, isUploading, history]);

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(
      add({
        room: {
          hotel_id: hotel_id,
          camas: camas,
          huespedes: huespedes,
          nombre: {
            es: nombreEs,
            en: nombreEn
          },
          descripcion: {
            es: descripcionEs,
            en: descripcionEn
          }
        },
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    setIsCompleted(true);
  };

  if (isUploading) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={64} />
        <Typography variant="subtitle1">Enviando informacón</Typography>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Nombre (español){" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <TextField
            id="input-nombre-es"
            autoFocus
            fullWidth
            value={nombreEs}
            onChange={({ target }) => setNombreEs(target.value)}
            variant="outlined"
            margin="dense"
            required
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Nombre (ingles){" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <TextField
            id="input-nombre-en"
            autoFocus
            fullWidth
            value={nombreEn}
            onChange={({ target }) => setNombreEn(target.value)}
            variant="outlined"
            margin="dense"
            required
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Descripción (español){" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <TextField
            id="input-desc-es"
            autoFocus
            fullWidth
            value={descripcionEs}
            onChange={({ target }) => setDescripcionEs(target.value)}
            variant="outlined"
            margin="dense"
            required
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Descripción (ingles){" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <TextField
            id="input-desc-en"
            autoFocus
            fullWidth
            value={descripcionEn}
            onChange={({ target }) => setDescripcionEn(target.value)}
            variant="outlined"
            margin="dense"
            required
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Camas
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <TextField
            id="input-camas"
            autoFocus
            fullWidth
            value={camas}
            onChange={({ target }) => setCamas(target.value)}
            variant="outlined"
            margin="dense"
            required
            InputProps={{
              type: "number"
            }}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Capacidad máxima
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <TextField
            id="input-capacidad"
            autoFocus
            fullWidth
            value={huespedes}
            onChange={({ target }) => setHuespedes(target.value)}
            variant="outlined"
            margin="dense"
            required
            InputProps={{
              type: "number"
            }}
          />
        </Grid>
      </Grid>
      <div className={classes.verticalSpacing} />
      <div className={classes.actions}>
        <Button variant="contained" color="secondary" type="submit">
          Guardar
        </Button>
      </div>
    </form>
  );
}
