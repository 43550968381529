import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE
} from "../../consts/actionTypes";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { ...state, isLoading: true };
    case USER_LOGIN_SUCCESS:
      return { ...action.results.data.data, isLoading: false, loggedIn: true };
    case USER_LOGIN_FAILURE:
      console.log(action);
      return { error: action.error, isLoading: false, loggedIn: false };
    case GET_USER_REQUEST:
      return { ...state, isLoading: true };
    case GET_USER_SUCCESS:
      return { ...action.results.data, isLoading: false, loggedIn: true };
    case GET_USER_FAILURE:
      console.log(action);
      return { error: action.error, isLoading: false, loggedIn: false };
    case USER_LOGOUT_REQUEST:
      return { ...state, isLoading: true };
    case USER_LOGOUT_SUCCESS:
      return {}
    case USER_LOGOUT_FAILURE:
        console.log(action);
        return { error: action.error, isLoading: false };
    default:
      return { ...state };
  }
}
