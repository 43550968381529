import React from "react";
import NumberFormat from "react-number-format";

function ExpiryTextField(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      format="##/##"
      placeholder="MM/YY"
      mask={["M", "M", "Y", "Y"]}
    />
  );
}

export default ExpiryTextField;