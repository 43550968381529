import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { cancel } from "../../redux/actions/reservaciones";

function Cancelar({ open, handleClose, reservacion_id, reservados }) {
  const dispatch = useDispatch();
  const isUpdating = useSelector(state => state.reservaciones.isUpdating);

  const reembolsable = () => {
    let resp = true;
    reservados.forEach(res => {
      if (!res.tarifa.reembolsable) {
        resp = false;
      }
    });
    return resp;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        ¿Desea cancelar la reservación?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {reembolsable() ? (
            "Esta reservación cuenta con tarifas reembolsables, asegurese de que cumpla con las políticas de resercación antes de confirmar."
          ) : (
            <>
              Esta reservación cuenta con tarifas <b>NO REEMBOLSABLES,</b>
              realice el cobro de la reservación antes de confirmar la
              cancelación."
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
        <Button
          color="primary"
          disabled={isUpdating}
          onClick={() =>
            dispatch(
              cancel({
                reservacion_id: reservacion_id,
                header: {
                  Authorization:
                    localStorage.getItem("token_type") +
                    " " +
                    localStorage.getItem("access_token")
                }
              })
            )
          }
          endIcon={isUpdating && <CircularProgress size={12} />}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Cancelar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reservacion_id: PropTypes.number,
  reservados: PropTypes.array
};

export default Cancelar;
