import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_MESSSGES_REQUEST,
  GET_MESSSGES_SUCCESS,
  GET_MESSSGES_FAILURE,
  LOAD_MESSSGES_REQUEST,
  LOAD_MESSSGES_SUCCESS,
  LOAD_MESSSGES_FAILURE,
  UPDATE_MESSAGE_REQUEST,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_FAILURE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* getMessages({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "mensajes?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_MESSSGES_SUCCESS, results, search: payload.search });
  } catch (error) {
    yield put({ type: GET_MESSSGES_FAILURE, error });
  }
}

export function* loadMessages({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "mensajes?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: LOAD_MESSSGES_SUCCESS, results });
  } catch (error) {
    yield put({ type: LOAD_MESSSGES_FAILURE, error });
  }
}

export function* updateMessage({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "mensajes/" + payload.id,
      payload.message,
      payload.header,
      "PUT"
    );
    yield put({ type: UPDATE_MESSAGE_SUCCESS, results });
  } catch (error) {
    yield put({ type: UPDATE_MESSAGE_FAILURE, error });
  }
}

export default function* mensajes() {
  yield takeLatest(GET_MESSSGES_REQUEST, getMessages);
  yield takeLatest(LOAD_MESSSGES_REQUEST, loadMessages);
  yield takeLatest(UPDATE_MESSAGE_REQUEST, updateMessage);
}
