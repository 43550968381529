import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { updatePass } from "../../redux/actions/usuarios";

export default function Password({ handleClose, id }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.usuarios.passwordReset);
  const lastReset = useSelector(state => state.usuarios.lastReset);

  const [password, setPassword] = React.useState("");
  const [password_confirmation, setPasswordConfirmation] = React.useState("");
  const [action, setAction] = React.useState(false)

  React.useEffect(() => {
    if (id === lastReset && action) {
      handleClose();
    }
  }, [action, lastReset, id, handleClose]);

  const handleSubmit = () => {
    if (password !== password_confirmation) {
      alert("Las contraseñas no coinciden.");
      return;
    }
    dispatch(
      updatePass({
        id: id,
        user: {
          password: password,
          password_confirmation: password_confirmation
        },
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    setAction(true)
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"xs"}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Cambiar contraseña</DialogTitle>
      <DialogContent>
        <DialogContentText>Ingresa tu nueva contraseña.</DialogContentText>
        <Typography variant="body1">
          Contraseña{" "}
          <Typography color="error" component="span">
            *
          </Typography>
        </Typography>
        <TextField
          id="input-password"
          fullWidth
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          variant="outlined"
          margin="dense"
          inputProps={{ type: "password", maxLength: 255 }}
          required
        />
        <Typography variant="body1">
          Confirmar contraseña{" "}
          <Typography color="error" component="span">
            *
          </Typography>
        </Typography>
        <TextField
          id="input-password2"
          fullWidth
          value={password_confirmation}
          onChange={({ target }) => setPasswordConfirmation(target.value)}
          variant="outlined"
          margin="dense"
          inputProps={{ type: "password", maxLength: 255 }}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" disabled={isLoading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Password.propTypes = {
  id: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired
};
