import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Fade from "@material-ui/core/Fade";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InputAdornment from "@material-ui/core/InputAdornment";

import moment from "moment";
import MomentUtils from "@date-io/moment";

import CloseIcon from "@material-ui/icons/Close";

import { update } from "../../redux/actions/tarifas";

import styles from "./style";
import RoomTypesSelect from "../../components/RoomTypesSelect";

export default function Formulario({ tarifa, handleEdit }) {
  const classes = styles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [tipo_de_cuarto, setTipoCuarto] = React.useState(tarifa.tipo_de_cuarto);
  const [precio, setPrecio] = React.useState(tarifa.precio);
  const [etiqueta, setEtiqueta] = React.useState(tarifa.etiqueta);
  const [huespedes, setHuespedes] = React.useState(tarifa.huespedes);
  const [reembolsable, setReembolsable] = React.useState(
    Boolean(tarifa.reembolsable)
  );
  const [desde, setDesde] = React.useState(moment(tarifa.desde));
  const [desdeError, setDesdeError] = React.useState(null);
  const [finaliza, setFinaliza] = React.useState(Boolean(tarifa.hasta));
  const [hasta, setHasta] = React.useState(
    tarifa.hasta ? moment(tarifa.hasta) : moment()
  );
  const [hastaError, setHastaError] = React.useState(null);

  const handleUpdate = event => {
    event.preventDefault();
    if (desdeError) {
      alert("Formato de fecha invalido.");
      return;
    }
    if (hastaError) {
      alert("Formato de fecha invalido.");
      return;
    }
    dispatch(
      update({
        id: tarifa.id,
        rate: {
          tipo_de_cuarto: tipo_de_cuarto,
          precio: precio,
          etiqueta: etiqueta,
          huespedes: huespedes,
          reembolsable: reembolsable,
          desde: desde.format("YYYY-MM-DD"),
          hasta: finaliza ? hasta.format("YYYY-MM-DD") : null
        },
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    handleEdit();
  };

  return (
    <CardContent>
      <div className={classes.header}>
        <Typography variant="h6">Editar información</Typography>
        <div style={{ flexGrow: 1 }} />
        <IconButton onClick={() => handleEdit()}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.verticalSpacing} />
      <form onSubmit={handleUpdate} autoComplete="off">
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Tipo de cuarto{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <RoomTypesSelect
              value={tipo_de_cuarto}
              handleChange={value => setTipoCuarto(value)}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Etiqueta{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-etiqueta"
              autoFocus
              fullWidth
              value={etiqueta}
              onChange={({ target }) => setEtiqueta(target.value)}
              variant="outlined"
              margin="dense"
              inputProps={{ maxLength: 128 }}
              required
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Precio{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-numero"
              autoFocus
              fullWidth
              value={precio}
              onChange={({ target }) => setPrecio(target.value)}
              variant="outlined"
              margin="dense"
              inputProps={{ type: "number", max: 99999999 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
              required
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Huespedes para esta tarifa{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-numero"
              autoFocus
              fullWidth
              value={huespedes}
              onChange={({ target }) => setHuespedes(target.value)}
              variant="outlined"
              margin="dense"
              inputProps={{ type: "number", max: 12 }}
              required
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Reembolsable{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <Switch
              checked={reembolsable}
              onChange={event => setReembolsable(event.target.checked)}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Avtiva desde{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
              <KeyboardDatePicker
                disableToolbar={!matches}
                autoOk={!matches}
                variant={matches ? "dialog" : "inline"}
                inputVariant="outlined"
                format="MM/DD/YYYY"
                margin="dense"
                id="date-picker-desde"
                value={desde}
                onChange={date => setDesde(date)}
                invalidDateMessage={"Formato de fecha invalido."}
                KeyboardButtonProps={{
                  "aria-label": "Cambiar fecha"
                }}
                onError={error => setDesdeError(error)}
                required
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">Establecer finalización</Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <Switch
              checked={finaliza}
              onChange={event => setFinaliza(event.target.checked)}
            />
          </Grid>
        </Grid>
        <Fade in={finaliza} exit={!finaliza}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <Typography variant="body1">
                Hasta{" "}
                <Typography color="error" component="span">
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <KeyboardDatePicker
                  disableToolbar={!matches}
                  autoOk={!matches}
                  variant={matches ? "dialog" : "inline"}
                  inputVariant="outlined"
                  format="MM/DD/YYYY"
                  margin="dense"
                  id="date-picker-hasta"
                  value={hasta}
                  onChange={date => setHasta(date)}
                  invalidDateMessage={"Formato de fecha invalido."}
                  KeyboardButtonProps={{
                    "aria-label": "Cambiar fecha"
                  }}
                  onError={error => setHastaError(error)}
                  required
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Fade>
        <div className={classes.verticalSpacing} />
        <div className={classes.actions}>
          <Button variant="contained" color="secondary" type="submit">
            Guardar
          </Button>
        </div>
      </form>
    </CardContent>
  );
}

Formulario.propTypes = {
  tarifa: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired
};
