import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_RATES_REQUEST,
  GET_RATES_SUCCESS,
  GET_RATES_FAILURE,
  LOAD_RATES_REQUEST,
  LOAD_RATES_SUCCESS,
  LOAD_RATES_FAILURE,
  GET_RATE_BY_ID_REQUEST,
  GET_RATE_BY_ID_SUCCESS,
  GET_RATE_BY_ID_FAILURE,
  UPDATE_RATE_REQUEST,
  UPDATE_RATE_SUCCESS,
  UPDATE_RATE_FAILURE,
  ADD_RATE_REQUEST,
  ADD_RATE_SUCCESS,
  ADD_RATE_FAILURE,
  DELETE_RATE_REQUEST,
  DELETE_RATE_SUCCESS,
  DELETE_RATE_FAILURE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* getRates({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "tarifas?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&orderBy=" +
        payload.orderBy +
        "&order=" +
        payload.order +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_RATES_SUCCESS, results, search: payload.search });
  } catch (error) {
    yield put({ type: GET_RATES_FAILURE, error });
  }
}

export function* loadRates({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "tarifas?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&orderBy=" +
        payload.orderBy +
        "&order=" +
        payload.order +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: LOAD_RATES_SUCCESS, results });
  } catch (error) {
    yield put({ type: LOAD_RATES_FAILURE, error });
  }
}

export function* getById({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "tarifas/" + payload.id,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_RATE_BY_ID_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_RATE_BY_ID_FAILURE, error });
  }
}

export function* updateRate({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "tarifas/" + payload.id,
      payload.rate,
      payload.header,
      "PUT"
    );
    yield put({ type: UPDATE_RATE_SUCCESS, results });
  } catch (error) {
    yield put({ type: UPDATE_RATE_FAILURE, error });
  }
}

export function* addRate({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "tarifas",
      payload.rate,
      payload.header,
      "POST"
    );
    yield put({ type: ADD_RATE_SUCCESS, results });
  } catch (error) {
    yield put({ type: ADD_RATE_FAILURE, error });
  }
}

export function* deleteRate({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "tarifas/" + payload.id,
      null,
      payload.header,
      "DELETE"
    );
    yield put({ type: DELETE_RATE_SUCCESS, results });
  } catch (error) {
    yield put({ type: DELETE_RATE_FAILURE, error });
  }
}

export default function* tarifas() {
  yield takeLatest(GET_RATES_REQUEST, getRates);
  yield takeLatest(LOAD_RATES_REQUEST, loadRates);
  yield takeLatest(GET_RATE_BY_ID_REQUEST, getById);
  yield takeLatest(UPDATE_RATE_REQUEST, updateRate);
  yield takeLatest(ADD_RATE_REQUEST, addRate);
  yield takeLatest(DELETE_RATE_REQUEST, deleteRate);
}
