import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import ExpiryTextField from "../../components/ExpiryTextField";
import NumberTextField from "../../components/NumberTextField";

import { updateCard } from "../../redux/actions/reservaciones";

function Tarjeta({ open, handleClose, tarjeta, reservacion_id }) {
  const dispatch = useDispatch();
  const inputRef = React.useRef(null);

  const isUpdating = useSelector(state => state.reservaciones.isUpdating);

  const [cardNumber, setCardNumber] = React.useState(tarjeta.numero || "");
  const [name, setName] = React.useState(tarjeta.titular || "");
  const [expiry, setExpiry] = React.useState(tarjeta.mes + tarjeta.anio || "");
  const [cvv, setCVV] = React.useState(tarjeta.cvv || "");
  const [maxLength, setMaxLength] = React.useState(16);
  const [issuer, setIssuer] = React.useState(tarjeta.tipo);
  const [focused, setFocused] = React.useState("number");
  const [isValid, setIsValid] = React.useState(true);

  const handleSubmit = e => {
    e.preventDefault();
    if (isValid) {
      dispatch(
        updateCard({
          id: tarjeta.id || false,
          reservacion_id: reservacion_id,
          numero: cardNumber,
          titular: name,
          mes: expiry.substr(0, 2),
          anio: expiry.substr(2, 2),
          cvv: cvv,
          tipo: issuer,
          header: {
            Authorization:
              localStorage.getItem("token_type") +
              " " +
              localStorage.getItem("access_token")
          }
        })
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Editar información del cliente
      </DialogTitle>
      <DialogContent>
        <Cards
          name={name}
          number={cardNumber}
          expiry={expiry}
          cvc={cvv}
          acceptedCards={["visa", "mastercard", "amex"]}
          callback={(type, isValid) => {
            setIssuer(type.issuer);
            setMaxLength(type.maxLength);
            setIsValid(isValid);
          }}
          placeholders={{
            name: "TU NOMBRE"
          }}
          locale={{ valid: "Vence" }}
          focused={focused}
        />
        <form onSubmit={handleSubmit} autoComplete="off">
          <NumberTextField
            autoFocus
            label="Número"
            value={cardNumber}
            onChange={value => setCardNumber(value)}
            maxLength={maxLength}
            onFocus={() => setFocused("number")}
            style={{ margin: "8px 4px" }}
            required
          />
          <TextField
            fullWidth
            label="Titular"
            variant="outlined"
            value={name}
            onChange={({ target }) => setName(target.value.toUpperCase())}
            inputProps={{
              maxLength: 21
            }}
            onFocus={() => setFocused("name")}
            style={{ margin: "8px 4px" }}
            required
          />
          <Grid container>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Expiración"
                variant="outlined"
                value={expiry}
                onChange={({ target }) => setExpiry(target.value)}
                InputProps={{
                  type: "text",
                  inputComponent: ExpiryTextField
                }}
                InputLabelProps={{
                  shrink: true
                }}
                onFocus={() => setFocused("expiry")}
                style={{ margin: "8px 4px" }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <NumberTextField
                label="cvv"
                value={cvv}
                onChange={value => setCVV(value)}
                maxLength={4}
                onFocus={() => setFocused("cvc")}
                style={{ margin: "8px 4px" }}
                required
              />
            </Grid>
          </Grid>
          <button hidden type="submit" ref={inputRef} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() => inputRef.current.click()}
          color="primary"
          disabled={isUpdating}
        >
          Guardar {isUpdating && <CircularProgress size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Tarjeta.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  tarjeta: PropTypes.object,
  reservacion_id: PropTypes.number
};

export default Tarjeta;
