import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_ROOMTYPES_REQUEST,
  GET_ROOMTYPES_SUCCESS,
  GET_ROOMTYPES_FAILURE,
  LOAD_ROOMTYPES_REQUEST,
  LOAD_ROOMTYPES_SUCCESS,
  LOAD_ROOMTYPES_FAILURE,
  GET_ROOMTYPE_BY_ID_REQUEST,
  GET_ROOMTYPE_BY_ID_SUCCESS,
  GET_ROOMTYPE_BY_ID_FAILURE,
  UPDATE_ROOMTYPE_REQUEST,
  UPDATE_ROOMTYPE_SUCCESS,
  UPDATE_ROOMTYPE_FAILURE,
  ADD_ROOMTYPE_REQUEST,
  ADD_ROOMTYPE_SUCCESS,
  ADD_ROOMTYPE_FAILURE,
  DELETE_ROOMTYPE_REQUEST,
  DELETE_ROOMTYPE_SUCCESS,
  DELETE_ROOMTYPE_FAILURE,
  GET_ROOMTYPES_FOR_SELECT_REQUEST,
  GET_ROOMTYPES_FOR_SELECT_SUCCESS,
  GET_ROOMTYPES_FOR_SELECT_FAILURE,
  UPLOAD_ROOMTYPE_IMAGE_REQUEST,
  UPLOAD_ROOMTYPE_IMAGE_SUCCESS,
  UPLOAD_ROOMTYPE_IMAGE_FAILURE,
  ADD_GALLERY_IMAGE_REQUEST,
  ADD_GALLERY_IMAGE_SUCCESS,
  ADD_GALLERY_IMAGE_FAILURE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* getRoomTypes({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "tipo_cuarto?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&orderBy=" +
        payload.orderBy +
        "&order=" +
        payload.order +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_ROOMTYPES_SUCCESS, results, search: payload.search });
  } catch (error) {
    yield put({ type: GET_ROOMTYPES_FAILURE, error });
  }
}

export function* loadRoomTypes({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "tipo_cuarto?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&orderBy=" +
        payload.orderBy +
        "&order=" +
        payload.order +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: LOAD_ROOMTYPES_SUCCESS, results });
  } catch (error) {
    yield put({ type: LOAD_ROOMTYPES_FAILURE, error });
  }
}

export function* getById({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "tipo_cuarto/" + payload.id,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_ROOMTYPE_BY_ID_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_ROOMTYPE_BY_ID_FAILURE, error });
  }
}

export function* updateRoomType({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "tipo_cuarto/" + payload.id,
      payload.room,
      payload.header,
      "PUT"
    );
    yield put({ type: UPDATE_ROOMTYPE_SUCCESS, results });
  } catch (error) {
    yield put({ type: UPDATE_ROOMTYPE_FAILURE, error });
  }
}

export function* addRoomType({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "tipo_cuarto",
      payload.room,
      payload.header,
      "POST"
    );
    yield put({ type: ADD_ROOMTYPE_SUCCESS, results });
  } catch (error) {
    yield put({ type: ADD_ROOMTYPE_FAILURE, error });
  }
}

export function* deleteRoomTYpe({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "tipo_cuarto/" + payload.id,
      null,
      payload.header,
      "DELETE"
    );
    yield put({ type: DELETE_ROOMTYPE_SUCCESS, results });
  } catch (error) {
    yield put({ type: DELETE_ROOMTYPE_FAILURE, error });
  }
}

export function* getRoomTypesForSelect({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "tipo_cuarto/all/" + payload.id,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_ROOMTYPES_FOR_SELECT_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_ROOMTYPES_FOR_SELECT_FAILURE, error });
  }
}

export function* uploadImage({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "tipo_cuarto/imagen/" + payload.id,
      payload.formData,
      payload.header,
      "POST"
    );
    yield put({ type: UPLOAD_ROOMTYPE_IMAGE_SUCCESS, results });
  } catch (error) {
    yield put({ type: UPLOAD_ROOMTYPE_IMAGE_FAILURE, error });
  }
}

export function* uploadGalleryImage({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "imagen_cuarto/" + payload.id,
      payload.formData,
      payload.header,
      "POST"
    );
    yield put({ type: ADD_GALLERY_IMAGE_SUCCESS, results });
  } catch (error) {
    yield put({ type: ADD_GALLERY_IMAGE_FAILURE, error });
  }
}

export default function* tiposCuarto() {
  yield takeLatest(GET_ROOMTYPES_REQUEST, getRoomTypes);
  yield takeLatest(LOAD_ROOMTYPES_REQUEST, loadRoomTypes);
  yield takeLatest(GET_ROOMTYPE_BY_ID_REQUEST, getById);
  yield takeLatest(UPDATE_ROOMTYPE_REQUEST, updateRoomType);
  yield takeLatest(ADD_ROOMTYPE_REQUEST, addRoomType);
  yield takeLatest(DELETE_ROOMTYPE_REQUEST, deleteRoomTYpe);
  yield takeLatest(GET_ROOMTYPES_FOR_SELECT_REQUEST, getRoomTypesForSelect);
  yield takeLatest(UPLOAD_ROOMTYPE_IMAGE_REQUEST, uploadImage);
  yield takeLatest(ADD_GALLERY_IMAGE_REQUEST, uploadGalleryImage);
}
