import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import styles from "./style";
import Habitacion from "./Habitacion";

function Disponibles({ handleNext, handleBack }) {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const disponibles = useSelector(state => state.nuevaReservacion.disponibles);

  const [selected, setSelected] = React.useState([]);

  const handleSelected = nuevo => {
    const index = selected.findIndex(
      item => item.tarifa.id === nuevo.tarifa.id
    );
    if (index !== -1) {
      if (nuevo.cantidad === 0) {
        return setSelected(selected.filter((item, i) => i !== index));
      }
      setSelected(
        selected.map((item, i) => {
          if (i === index) {
            return nuevo;
          }
          return item;
        })
      );
    } else if (nuevo.cantidad > 0) {
      setSelected([...selected, nuevo]);
    }
  };

  const handleSubmit = () => {
    if (selected.length > 0) {
      handleNext(selected);
    } else {
      alert("Selecciona al menos una habitación.");
    }
  };

  return (
    <div className={classes.stepContainer}>
      <div className={classes.header}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant={matches ? "subtitle1" : "h6"}>
          Selecciona las habitaciones.
        </Typography>
      </div>
      <div className={classes.verticalSpacing} />
      {disponibles.map(tipoCuarto => (
        <Habitacion
          key={tipoCuarto.id}
          habitacion={tipoCuarto}
          handleSelected={handleSelected}
          handleNext={handleSubmit}
        />
      ))}
      <div className={classes.verticalSpacing} />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        onClick={() => handleSubmit()}
      >
        Siguiente
      </Button>
    </div>
  );
}

Disponibles.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired
};

export default Disponibles;
