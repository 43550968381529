import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles(theme => ({
  header: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    display: "flex",
    alignContent: "center"
  },
  verticalSpacing: {
    height: theme.spacing(2)
  },
  listStyle: {
    "&:nth-of-type(even)": { backgroundColor: "#f2f2f2" },
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));
