import {
    GET_SERVICES_REQUEST,
    LOAD_SERVICES_REQUEST,
    GET_SERVICE_BY_ID_REQUEST,
    UPDATE_SERVICE_REQUEST,
    ADD_SERVICE_REQUEST,
    DELETE_SERVICE_REQUEST,
    GET_ALL_SERVICES_REQUEST
  } from "../../consts/actionTypes";
  
  export const get = payload => ({
    type: GET_SERVICES_REQUEST,
    payload
  });
  
  export const load = payload => ({
    type: LOAD_SERVICES_REQUEST,
    payload
  });
  
  export const getById = payload => ({
    type: GET_SERVICE_BY_ID_REQUEST,
    payload
  });
  
  export const update = payload => ({
    type: UPDATE_SERVICE_REQUEST,
    payload
  });
  
  export const add = payload => ({
    type: ADD_SERVICE_REQUEST,
    payload
  });
  
  export const del = payload => ({
    type: DELETE_SERVICE_REQUEST,
    payload
  });
  
export const getAll = payload => ({
  type: GET_ALL_SERVICES_REQUEST,
  payload
});