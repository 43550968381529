import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { teal, lightBlue } from "@material-ui/core/colors";

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: lightBlue["400"],
      main: lightBlue["700"],
      dark: lightBlue["900"],
      contrastText: "#fff"
    },
    secondary: {
      light: teal["400"],
      main: teal["700"],
      dark: teal["900"],
      contrastText: "#fff"
    }
  },
  status: {
    success: "#43a047",
    danger: "#dc3545",
    warning: "#ffa000",
    info: "#17a2b8",
    light: "#f8f9fa",
    dark: "#353A4B",
    white: "#fff"
  },
  statusDark: {
    success: "#2e7d32",
    danger: "#c02333",
    warning: "#ff6f00",
    info: "#127a87"
  },
  statusLight: {
    success: "#66bb6a",
    danger: "#e97984",
    warning: "#ffc107",
    info: "#2dbdd2"
  },
  typography: { useNextVariants: true }
});
