import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_PAYMENT_TYPES_REQUEST,
  GET_PAYMENT_TYPES_SUCCESS,
  GET_PAYMENT_TYPES_FAILURE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* getTypes({ payload }) {
  try {
    const results = yield call(apiCall, "tipo_pago", null, payload.header, "GET");
    yield put({ type: GET_PAYMENT_TYPES_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_PAYMENT_TYPES_FAILURE, error });
  }
}
export default function* tiposPago() {
  yield takeLatest(GET_PAYMENT_TYPES_REQUEST, getTypes);
}
