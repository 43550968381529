import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles(theme => ({
  header: {
    display: "flex",
    alignItems: "center"
  },
  headerUser: {
    display: "flex",
    alignItems: "flex-start"
  },
  verticalSpacing: {
    height: theme.spacing(2)
  },
  toolbar: {
    display: "flex",
    paddingLeft: 8
  },
  cargarBox: {
    display: "flex",
    justifyContent: "center"
  },
  empty: {
    padding: 32,
    backgroundColor: "#f5f5f5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 500
  },
  mensaje: {
    minHeight: 500,
    display: "flex",
    flexDirection: "column",
    border: "1px solid #e0e0e0"
  },
  section: {
    padding: "16px 32px",
    borderTop: "1px solid #e0e0e0"
  },
  body: {
    padding: "16px 32px",
    borderTop: "1px solid #e0e0e0",
    flex: 1
  },
  avatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  titleUser: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  list: {
    paddingLeft: 16,
    paddingRight: 8,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 4,
      paddingRight: 4,
    }
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  usuario: {
    paddingLeft: 16,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 4,
    }
  }
}));
