import {
    GET_ROLES_REQUEST,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAILURE,
    USER_LOGOUT_SUCCESS
  } from "../../consts/actionTypes";
  
  const initialState = {
    lista: [],
    isLoading: true
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case GET_ROLES_REQUEST:
        return { ...state, isLoading: true };
      case GET_ROLES_SUCCESS:
        return {
          ...state,
          isLoading: false,
          lista: action.results.data
        };
      case GET_ROLES_FAILURE:
        console.log(action);
        return { ...state, error: action.error, isLoading: false };
      case USER_LOGOUT_SUCCESS:
        return initialState;
      default:
        return { ...state };
    }
  }
  