import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";

import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import ApartmentIcon from "@material-ui/icons/Apartment";
import LanguageIcon from "@material-ui/icons/Language";
import ListAltIcon from "@material-ui/icons/ListAlt";

import styles from "./style";

import { del } from "../../redux/actions/cuartos";

export default function Detalles({ cuarto, handleEdit }) {
  const classes = styles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const status = ["listo", "ocupado", "sucio", "fuera", "limpieza"];

  const isDeleting = useSelector(state => state.cuartos.isDeleting);
  const lastDeleted = useSelector(state => state.cuartos.lastDeleted);
  const [inProcess, setInProcess] = React.useState(false) 

  React.useEffect(() => {
    if (inProcess && lastDeleted === cuarto.id.toString()) {
      history.goBack();
    }
  }, [inProcess, lastDeleted, history, cuarto]);

  const handleDelete = () => {
    setAnchorEl(null);
    dispatch(
      del({
        id: cuarto.id,
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    setInProcess(true)
  };

  if (isDeleting) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={64} />
        <Typography variant="subtitle1">Eliminando cuarto</Typography>
      </div>
    );
  }

  return (
    <CardContent className={classes.content}>
      <div className={classes.header}>
        <Typography variant="h6">
          Habitacion {cuarto.numero}{" "}
          <Chip
            className={classes[status[cuarto.estado.id - 1]]}
            component="span"
            label={cuarto.estado.nombre}
          />
        </Typography>
        <div style={{ flexGrow: 1 }} />
        <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="room-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => handleEdit()}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Editar" />
          </MenuItem>
          <MenuItem onClick={() => handleDelete()}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Eliminar" />
          </MenuItem>
        </Menu>
      </div>
      <div className={classes.verticalSpacing} />
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              alt={`Avatar for${cuarto.numero}`}
              src={cuarto.tipo_cuarto.image_url}
            />
          </ListItemAvatar>
          <ListItemText
            primary="Tipo de cuarto"
            secondary={cuarto.tipo_cuarto.nombre_hab.es}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ApartmentIcon style={{ color: "#263238" }} />
          </ListItemIcon>
          <ListItemText primary="Piso" secondary={cuarto.piso} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <LanguageIcon style={{ color: "#0d47a1" }} />
          </ListItemIcon>
          <ListItemText
            primary="Publicado en web"
            secondary={cuarto.activo ? "Si" : "No"}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText
            primary="Descripción"
            secondary={cuarto.descripcion.es}
          />
        </ListItem>
      </List>
    </CardContent>
  );
}

Detalles.propTypes = {
  cuarto: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired
};
