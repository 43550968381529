import {
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE,
  LOAD_SERVICES_REQUEST,
  LOAD_SERVICES_SUCCESS,
  LOAD_SERVICES_FAILURE,
  GET_SERVICE_BY_ID_REQUEST,
  GET_SERVICE_BY_ID_SUCCESS,
  GET_SERVICE_BY_ID_FAILURE,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAILURE,
  ADD_SERVICE_REQUEST,
  ADD_SERVICE_SUCCESS,
  ADD_SERVICE_FAILURE,
  DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAILURE,
  GET_ALL_SERVICES_REQUEST,
  GET_ALL_SERVICES_SUCCESS,
  GET_ALL_SERVICES_FAILURE,
  USER_LOGOUT_SUCCESS
} from "../../consts/actionTypes";

const initialState = {
  lista: [],
  seleccionado: null,
  total: 1,
  actual: 0,
  search: "",
  isLoading: false,
  isLoadingMore: false,
  isUploading: false,
  isDeleting: false,
  select: null,
  isFetching: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SERVICES_REQUEST:
      return { ...state, isLoading: true };
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lista: action.results.data.data,
        search: action.search,
        page: 1,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case GET_SERVICES_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case LOAD_SERVICES_REQUEST:
      return { ...state, isLoadingMore: true };
    case LOAD_SERVICES_SUCCESS:
      return {
        ...state,
        isLoadingMore: false,
        lista: state.lista.concat(action.results.data.data),
        page: action.results.data.current_page,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case LOAD_SERVICES_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoadingMore: false };
    case GET_SERVICE_BY_ID_REQUEST:
      return { ...state, isLoading: true };
    case GET_SERVICE_BY_ID_SUCCESS:
      return { ...state, isLoading: false, seleccionado: action.results.data };
    case GET_SERVICE_BY_ID_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case UPDATE_SERVICE_REQUEST:
      return { ...state, isLoading: true };
    case UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        seleccionado: action.results.data,
        lista: state.lista.map(servicio => {
          if (servicio.id === action.results.data.id) {
            return action.results.data;
          }
          return servicio;
        })
      };
    case UPDATE_SERVICE_FAILURE:
      return { ...state, error: action.error, isLoading: false };
    case ADD_SERVICE_REQUEST:
      return { ...state, isUploading: true };
    case ADD_SERVICE_SUCCESS:
      return {
        ...state,
        isUploading: false,
        lista: [action.results.data, ...state.lista]
      };
    case ADD_SERVICE_FAILURE:
      return { ...state, error: action.error, isUploading: false };
    case DELETE_SERVICE_REQUEST:
      return { ...state, isDeleting: true };
    case DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        lastDeleted: action.results.data.id,
        lista: state.lista.filter(
          servicio => servicio.id.toString() !== action.results.data.id
        )
      };
    case DELETE_SERVICE_FAILURE:
      return { ...state, error: action.error, isDeleting: false };
    case GET_ALL_SERVICES_REQUEST:
      return { ...state, isFetching: true, select: null };
    case GET_ALL_SERVICES_SUCCESS:
      return { ...state, isFetching: false, select: action.results.data };
    case GET_ALL_SERVICES_FAILURE:
      console.log(action.error);
      return { ...state, isFetching: false, error: action.error };
    case USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return { ...state };
  }
}
