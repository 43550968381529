import React from "react";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import styles from "./style";
import Row from "./Row";

function TablaCuartos({ cuartosReservados, desde, hasta }) {
  const classes = styles();

  return (
    <div style={{ overflowX: "scroll" }}>
      <Table className={classes.nested}>
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: 200 }}>Tipo de cuarto</TableCell>
            <TableCell>Asignado</TableCell>
            <TableCell style={{ minWidth: 200 }}>Tarifa</TableCell>
            <TableCell>Precio/Noche</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cuartosReservados.map(reservado => (
            <Row
              key={reservado.id}
              reservado={reservado}
              desde={desde}
              hasta={hasta}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

TablaCuartos.propTypes = {
  cuartosReservados: PropTypes.array,
  desde: PropTypes.string,
  hasta: PropTypes.string
};

export default TablaCuartos;
