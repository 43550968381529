import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles(theme => ({
  header: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    display: "flex",
    alignContent: "center"
  },
  verticalSpacing: {
    height: theme.spacing(2)
  },
  cargarBox: {
    display: "flex",
    justifyContent: "center"
  },
  toolbar: {
    display: "flex"
  },
  listStyle: {
    "&:nth-of-type(even)": { backgroundColor: "#f2f2f2" },
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },
  empty: {
    padding: 32,
    backgroundColor: "#f5f5f5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 500
  },
  nested: {
    paddingLeft: theme.spacing(4),
    backgroundColor: "#f5f5f5"
  }
}));
