import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import { get } from "../../redux/actions/roles";

export default function RolesSelect({ value, handleChange }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.roles.isLoading);
  const roles = useSelector(state => state.roles.lista);

  React.useEffect(() => {
    if (roles.length < 1) {
      dispatch(
        get({
          header: {
            Authorization:
              localStorage.getItem("token_type") +
              " " +
              localStorage.getItem("access_token")
          }
        })
      );
    }
  });

  return (
    <TextField
      select
      value={value}
      onChange={e => handleChange(e.target.value)}
      variant="outlined"
      fullWidth
      margin="dense"
    >
      <MenuItem value={0} disabled>
        Selecciona el rol.
      </MenuItem>
      {isLoading ? (
        <MenuItem disabled value={value} selected>
          Cargando...
        </MenuItem>
      ) : (
        roles.map(rol => (
          <MenuItem value={rol.id} key={rol.id}>
            {rol.nombre}
          </MenuItem>
        ))
      )}
    </TextField>
  );
}

RolesSelect.propTypes = {
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired
};
