import {
  GET_ROOMS_REQUEST,
  LOAD_ROOMS_REQUEST,
  GET_ROOM_BY_ID_REQUEST,
  UPDATE_ROOM_REQUEST,
  ADD_ROOM_REQUEST,
  DELETE_ROOM_REQUEST,
  ADD_CLOSE_DATE_REQUEST,
  REMOVE_CLOSE_DATE_REQUEST
} from "../../consts/actionTypes";

export const get = payload => ({
  type: GET_ROOMS_REQUEST,
  payload
});

export const load = payload => ({
  type: LOAD_ROOMS_REQUEST,
  payload
});

export const getById = payload => ({
  type: GET_ROOM_BY_ID_REQUEST,
  payload
});

export const update = payload => ({
  type: UPDATE_ROOM_REQUEST,
  payload
});

export const add = payload => ({
  type: ADD_ROOM_REQUEST,
  payload
});

export const del = payload => ({
  type: DELETE_ROOM_REQUEST,
  payload
});

export const addDate = payload => ({
  type: ADD_CLOSE_DATE_REQUEST,
  payload
});

export const removeDate = payload => ({
  type: REMOVE_CLOSE_DATE_REQUEST,
  payload
});
