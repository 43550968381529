import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";

import styles from "./style";
import SearchIcon from "@material-ui/icons/Search";

function SearchTextField({ search, handleTextChange }) {
  const classes = styles();

  const [focus, setFocus] = React.useState(0);

  return (
    <Paper
      className={focus ? classes.inputFocus : classes.input}
      elevation={focus}
    >
      <InputBase
        placeholder="Buscar"
        style={{ marginLeft: 8, flex: 1 }}
        value={search}
        onChange={({ target }) => handleTextChange(target.value)}
        onFocus={() => setFocus(1)}
        onBlur={() => setFocus(0)}
      />
      <SearchIcon />
    </Paper>
  );
}

SearchTextField.propTypes = {
  search: PropTypes.string.isRequired,
  handleTextChange: PropTypes.func.isRequired
};

export default SearchTextField;
