import {
  GET_PENDING_RESERVATION_REQUEST,
  LOAD_PENDING_RESERVATION_REQUEST,
  GET_PENDING_RESERVATION_BY_ID_REQUEST,
  CONFIRM_RESERVATION_REQUEST,
  CANCEL_PENDING_RESERVATION_REQUEST,
  CLEAR_PENDING_RESERVATION_SELECTED
} from "../../consts/actionTypes";

export const get = payload => ({
  type: GET_PENDING_RESERVATION_REQUEST,
  payload
});

export const load = payload => ({
  type: LOAD_PENDING_RESERVATION_REQUEST,
  payload
});

export const getById = payload => ({
  type: GET_PENDING_RESERVATION_BY_ID_REQUEST,
  payload
});

export const confirm = payload => ({
  type: CONFIRM_RESERVATION_REQUEST,
  payload
});

export const cancel = payload => ({
  type: CANCEL_PENDING_RESERVATION_REQUEST,
  payload
});

export const clear = payload => ({
  type: CLEAR_PENDING_RESERVATION_SELECTED,
  payload
});
