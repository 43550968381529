import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles(theme => ({
  input: {
    backgroundColor: "#e0e0e0",
    display: "flex",
    minWidth: "70vw",
    [theme.breakpoints.up("sm")]: {
      minWidth: "auto",
      width: 300,
      marginTop: 0
    },
    alignItems: "center",
    padding: "4px 10px",
    borderRadius: "20px"
  },
  inputFocus: {
    display: "flex",
    minWidth: "70vw",
    [theme.breakpoints.up("sm")]: {
      minWidth: "auto",
      width: 300,
      marginTop: 0
    },
    alignItems: "center",
    padding: "4px 10px",
    borderRadius: "20px"
  }
}));
