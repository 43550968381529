import React from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";

import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";

import { getById } from "../../redux/actions/tiposCuarto";

import styles from "./style";
import Detalles from "./Detalles";
import Formulario from "./Formulario";
import FormularioImagen from "./FormularioImagen";
import Galeria from "./Galeria";

function TipoCuartoDetalle() {
  const classes = styles();
  const dispatch = useDispatch();
  const seleccionado = useSelector(state => state.tiposCuarto.seleccionado);
  const isLoading = useSelector(state => state.tiposCuarto.isLoading);

  const [editar, setEditar] = React.useState(false);
  const [imagen, setImagen] = React.useState(false);

  const history = useHistory();

  React.useEffect(() => {
    const { id } = queryString.parse(window.location.search);

    if (!seleccionado || seleccionado.id.toString() !== id) {
      dispatch(
        getById({
          id: id,
          header: {
            Authorization:
              localStorage.getItem("token_type") +
              " " +
              localStorage.getItem("access_token")
          }
        })
      );
    }
  });

  if (isLoading) {
    return (
      <div className={classes.loadingBox}>
        <CircularProgress size={64} />
        <Typography variant="subtitle1">Cargando...</Typography>
      </div>
    );
  }

  if (seleccionado) {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => history.goBack()}
          >
            Tipos de Habitación
          </Link>
          <Typography color="textPrimary">Detalles</Typography>
        </Breadcrumbs>
        <div className={classes.verticalSpacing} />
        <Card className={classes.card}>
          <CardMedia
            className={classes.imagen}
            image={seleccionado.image_url}
            title="Imagen del cuarto"
          >
            <IconButton
              className={classes.editarFoto}
              onClick={() => setImagen(true)}
            >
              <AddAPhotoIcon color="inherit" />
            </IconButton>
          </CardMedia>
          <div className={classes.details}>
            {editar ? (
              <Formulario
                cuarto={seleccionado}
                handleEdit={() => setEditar(false)}
              />
            ) : (
              <Detalles
                cuarto={seleccionado}
                handleEdit={() => setEditar(true)}
              />
            )}
          </div>
        </Card>
        <FormularioImagen
          open={imagen}
          id={seleccionado.id}
          onClose={() => setImagen(false)}
          current_url={seleccionado.image_url}
        />
        <div className={classes.verticalSpacing} />
        <Galeria imagenes={seleccionado.imagenes} />
      </>
    );
  }

  return null;
}

export default TipoCuartoDetalle;
