import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles(theme => ({
  header: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    display: "flex",
    alignContent: "center"
  },
  verticalSpacing: {
    height: theme.spacing(2)
  },
  stepContainer: {
    paddingLeft: 16,
    paddingRight: 8,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  fechaButton: {
    cursor: "pointer",
    padding: 8,
    "&:hover": {
      backgroundColor: "#eee"
    }
  },
  loading: {
    minHeight: 300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end"
  },
  //Habitacion
  avatar: {
    width: 100,
    height: 100,
    [theme.breakpoints.down("xs")]: {
      width: 60,
      height: 60
    }
  },
  expansion: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center"
  },
  habColumn: {
    flex: 1,
    padding: 8
  },
  features: {
    display: "flex",
    alignItems: "center"
  },
  tarifas: {
    overflowX: "scroll",
    backgroundColor: "#f5f5f5"
  },
  reembolsableBtn: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eee"
    }
  },
  habActions: {
    display: "flex",
    justifyContent: "flex-end"
  },
  input: {
    marginTop: 0
  }
}));
