import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_RESERVATIONS_REQUEST,
  GET_RESERVATIONS_SUCCESS,
  GET_RESERVATIONS_FAILURE,
  LOAD_RESERVATIONS_REQUEST,
  LOAD_RESERVATIONS_SUCCESS,
  LOAD_RESERVATIONS_FAILURE,
  GET_RESERVATION_BY_ID_REQUEST,
  GET_RESERVATION_BY_ID_SUCCESS,
  GET_RESERVATION_BY_ID_FAILURE,
  UPDATE_GUEST_REQUEST,
  UPDATE_GUEST_SUCCESS,
  UPDATE_GUEST_FAILURE,
  UPDATE_DATES_REQUEST,
  UPDATE_DATES_SUCCESS,
  UPDATE_DATES_FAILURE,
  CHECK_IN_REQUEST,
  CHECK_IN_SUCCESS,
  CHECK_IN_FAILURE,
  GET_FULL_AVAILABILITY_REQUEST,
  GET_FULL_AVAILABILITY_SUCCESS,
  GET_FULL_AVAILABILITY_FAILURE,
  UPDATE_RESERVATION_ROOMS_REQUEST,
  UPDATE_RESERVATION_ROOMS_SUCCESS,
  UPDATE_RESERVATION_ROOMS_FAILURE,
  UPDATE_RESERVATION_COMMENT_REQUEST,
  UPDATE_RESERVATION_COMMENT_SUCCESS,
  UPDATE_RESERVATION_COMMENT_FAILURE,
  UPDATE_CARD_REQUEST,
  UPDATE_CARD_SUCCESS,
  UPDATE_CARD_FAILURE,
  DELETE_CARD_REQUEST,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_FAILURE,
  CHECK_OUT_REQUEST,
  CHECK_OUT_SUCCESS,
  CHECK_OUT_FAILURE,
  ADD_PRODUCT_TO_RESERVATION_REQUEST,
  ADD_PRODUCT_TO_RESERVATION_SUCCESS,
  ADD_PRODUCT_TO_RESERVATION_FAILURE,
  REMOVE_PRODUCT_TO_RESERVATION_REQUEST,
  REMOVE_PRODUCT_TO_RESERVATION_SUCCESS,
  REMOVE_PRODUCT_TO_RESERVATION_FAILURE,
  ADD_SERVICE_TO_RESERVATION_REQUEST,
  ADD_SERVICE_TO_RESERVATION_SUCCESS,
  ADD_SERVICE_TO_RESERVATION_FAILURE,
  REMOVE_SERVICE_TO_RESERVATION_REQUEST,
  REMOVE_SERVICE_TO_RESERVATION_SUCCESS,
  REMOVE_SERVICE_TO_RESERVATION_FAILURE,
  ADD_PAYMENT_REQUEST,
  ADD_PAYMENT_SUCCESS,
  ADD_PAYMENT_FAILURE,
  REMOVE_PAYMENT_REQUEST,
  REMOVE_PAYMENT_SUCCESS,
  REMOVE_PAYMENT_FAILURE,
  ADD_EXTRA_REQUEST,
  ADD_EXTRA_SUCCESS,
  ADD_EXTRA_FAILURE,
  REMOVE_EXTRA_REQUEST,
  REMOVE_EXTRA_SUCCESS,
  REMOVE_EXTRA_FAILURE,
  CANCEL_RESERVATION_REQUEST,
  CANCEL_RESERVATION_SUCCESS,
  CANCEL_RESERVATION_FAILURE,
  FORCE_CANCEL_RESERVATION_REQUEST,
  FORCE_CANCEL_RESERVATION_SUCCESS,
  FORCE_CANCEL_RESERVATION_FAILURE,
  NUEVO_MENSAJE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* getReservations({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({
      type: GET_RESERVATIONS_SUCCESS,
      results,
      search: payload.search
    });
  } catch (error) {
    yield put({ type: GET_RESERVATIONS_FAILURE, error });
  }
}

export function* loadReservations({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion?page=" +
        payload.page +
        "&hotel_id=" +
        payload.hotel_id +
        "&search=" +
        payload.search,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: LOAD_RESERVATIONS_SUCCESS, results });
  } catch (error) {
    yield put({ type: LOAD_RESERVATIONS_FAILURE, error });
  }
}

export function* getById({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/" + payload.code,
      null,
      payload.header,
      "GET"
    );
    yield put({ type: GET_RESERVATION_BY_ID_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_RESERVATION_BY_ID_FAILURE, error });
  }
}

export function* updateGuest({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "huesped/" + payload.id,
      payload.guest,
      payload.header,
      "PUT"
    );
    yield put({ type: UPDATE_GUEST_SUCCESS, results, res: payload.res });
    yield put({
      type: NUEVO_MENSAJE,
      mensaje: "Datos del huesped actualizados.",
      tipo: "success"
    });
  } catch (error) {
    yield put({ type: UPDATE_GUEST_FAILURE, error });
  }
}

export function* updateDates({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "editar_fechas/" + payload.id,
      {
        desde: payload.desde,
        hasta: payload.hasta,
        reasignar: payload.reasignar
      },
      payload.header,
      "PUT"
    );
    yield put({ type: UPDATE_DATES_SUCCESS, results, res: payload.res });
    yield put({
      type: NUEVO_MENSAJE,
      mensaje: "Fechas actualizadas.",
      tipo: "success"
    });
  } catch (error) {
    yield put({ type: UPDATE_DATES_FAILURE, error });
  }
}

export function* checkIn({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/check_in",
      {
        reservacion_id: payload.reservacion_id
      },
      payload.header,
      "POST"
    );
    yield put({ type: CHECK_IN_SUCCESS, results });
  } catch (error) {
    yield put({ type: CHECK_IN_FAILURE, error });
  }
}

export function* checkOut({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/check_out",
      {
        reservacion_id: payload.reservacion_id
      },
      payload.header,
      "POST"
    );
    yield put({ type: CHECK_OUT_SUCCESS, results });
  } catch (error) {
    yield put({ type: CHECK_OUT_FAILURE, error });
  }
}

export function* cancel({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/cancelar",
      {
        reservacion_id: payload.reservacion_id
      },
      payload.header,
      "POST"
    );
    yield put({ type: CANCEL_RESERVATION_SUCCESS, results });
  } catch (error) {
    yield put({ type: CANCEL_RESERVATION_FAILURE, error });
  }
}

export function* forceCancel({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/forzar_cancelacion",
      {
        reservacion_id: payload.reservacion_id
      },
      payload.header,
      "POST"
    );
    yield put({ type: FORCE_CANCEL_RESERVATION_SUCCESS, results });
  } catch (error) {
    yield put({ type: FORCE_CANCEL_RESERVATION_FAILURE, error });
  }
}

export function* fullAvailability({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "disponibilidad_completa",
      {
        hotel_id: payload.hotel_id,
        desde: payload.desde,
        hasta: payload.hasta,
        cuarto_reservado: payload.cuarto_reservado
      },
      payload.header,
      "POST"
    );
    yield put({ type: GET_FULL_AVAILABILITY_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_FULL_AVAILABILITY_FAILURE, error });
  }
}

export function* updateRooms({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/reasignar",
      {
        desde: payload.desde,
        hasta: payload.hasta,
        cuarto_reservado: payload.cuarto_reservado,
        cuarto: payload.cuarto,
        tarifa: payload.tarifa
      },
      payload.header,
      "POST"
    );
    yield put({ type: UPDATE_RESERVATION_ROOMS_SUCCESS, results });
    yield put({
      type: NUEVO_MENSAJE,
      mensaje: "Cambios guardados.",
      tipo: "success"
    });
  } catch (error) {
    yield put({ type: UPDATE_RESERVATION_ROOMS_FAILURE, error });
  }
}

export function* updateComment({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/comentarios",
      {
        id: payload.id,
        comentarios: payload.comentarios
      },
      payload.header,
      "PUT"
    );
    yield put({ type: UPDATE_RESERVATION_COMMENT_SUCCESS, results });
    yield put({
      type: NUEVO_MENSAJE,
      mensaje: "Notas actualizadas.",
      tipo: "success"
    });
  } catch (error) {
    yield put({ type: UPDATE_RESERVATION_COMMENT_FAILURE, error });
  }
}

export function* updateCard({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/tarjeta",
      {
        id: payload.id,
        reservacion_id: payload.reservacion_id,
        numero: payload.numero,
        titular: payload.titular,
        mes: payload.mes,
        anio: payload.anio,
        cvv: payload.cvv,
        tipo: payload.tipo
      },
      payload.header,
      "PUT"
    );
    yield put({ type: UPDATE_CARD_SUCCESS, results });
    yield put({
      type: NUEVO_MENSAJE,
      mensaje: "Tarjeta actualizada.",
      tipo: "success"
    });
  } catch (error) {
    yield put({ type: UPDATE_CARD_FAILURE, error });
  }
}

export function* deleteCard({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/tarjeta/" + payload.id,
      null,
      payload.header,
      "DELETE"
    );
    yield put({ type: DELETE_CARD_SUCCESS, results });
    yield put({
      type: NUEVO_MENSAJE,
      mensaje: "Metodo de pago eliminado.",
      tipo: "success"
    });
  } catch (error) {
    yield put({ type: DELETE_CARD_FAILURE, error });
  }
}

export function* addProduct({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/producto",
      {
        reservacion_id: payload.reservacion_id,
        producto_id: payload.producto_id,
        cantidad: payload.cantidad
      },
      payload.header,
      "POST"
    );
    yield put({ type: ADD_PRODUCT_TO_RESERVATION_SUCCESS, results });
    yield put({
      type: NUEVO_MENSAJE,
      mensaje: "Producto añadido a la cuenta.",
      tipo: "success"
    });
  } catch (error) {
    yield put({ type: ADD_PRODUCT_TO_RESERVATION_FAILURE, error });
  }
}

export function* removeProduct({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/producto/" + payload.id,
      null,
      payload.header,
      "DELETE"
    );
    yield put({ type: REMOVE_PRODUCT_TO_RESERVATION_SUCCESS, results });
  } catch (error) {
    yield put({ type: REMOVE_PRODUCT_TO_RESERVATION_FAILURE, error });
  }
}

export function* addService({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/servicio",
      {
        reservacion_id: payload.reservacion_id,
        servicio_id: payload.servicio_id
      },
      payload.header,
      "POST"
    );
    yield put({ type: ADD_SERVICE_TO_RESERVATION_SUCCESS, results });
    yield put({
      type: NUEVO_MENSAJE,
      mensaje: "Servicio añadido a la cuenta.",
      tipo: "success"
    });
  } catch (error) {
    yield put({ type: ADD_SERVICE_TO_RESERVATION_FAILURE, error });
  }
}

export function* removeService({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "reservacion/servicio/" + payload.id,
      null,
      payload.header,
      "DELETE"
    );
    yield put({ type: REMOVE_SERVICE_TO_RESERVATION_SUCCESS, results });
  } catch (error) {
    yield put({ type: REMOVE_SERVICE_TO_RESERVATION_FAILURE, error });
  }
}

export function* addPayment({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "pago",
      {
        reservacion_id: payload.reservacion_id,
        valor: payload.valor,
        tipo_id: payload.tipo_id,
        concepto: payload.concepto
      },
      payload.header,
      "POST"
    );
    yield put({ type: ADD_PAYMENT_SUCCESS, results });
    yield put({
      type: NUEVO_MENSAJE,
      mensaje: "Pago registrado.",
      tipo: "success"
    });
  } catch (error) {
    yield put({ type: ADD_PAYMENT_FAILURE, error });
  }
}

export function* removePayment({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "pago/" + payload.id,
      null,
      payload.header,
      "DELETE"
    );
    yield put({ type: REMOVE_PAYMENT_SUCCESS, results });
  } catch (error) {
    yield put({ type: REMOVE_PAYMENT_FAILURE, error });
  }
}

export function* addExtra({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "ajuste",
      {
        reservacion_id: payload.reservacion_id,
        valor: payload.valor,
        descripcion: payload.descripcion
      },
      payload.header,
      "POST"
    );
    yield put({ type: ADD_EXTRA_SUCCESS, results });
    yield put({
      type: NUEVO_MENSAJE,
      mensaje: "Se registro el ajuste.",
      tipo: "success"
    });
  } catch (error) {
    yield put({ type: ADD_EXTRA_FAILURE, error });
  }
}

export function* removeExtra({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "ajuste/" + payload.id,
      null,
      payload.header,
      "DELETE"
    );
    yield put({ type: REMOVE_EXTRA_SUCCESS, results });
  } catch (error) {
    yield put({ type: REMOVE_EXTRA_FAILURE, error });
  }
}

export default function* reservaciones() {
  yield takeLatest(GET_RESERVATIONS_REQUEST, getReservations);
  yield takeLatest(LOAD_RESERVATIONS_REQUEST, loadReservations);
  yield takeLatest(GET_RESERVATION_BY_ID_REQUEST, getById);
  yield takeLatest(UPDATE_GUEST_REQUEST, updateGuest);
  yield takeLatest(UPDATE_DATES_REQUEST, updateDates);
  yield takeLatest(CHECK_IN_REQUEST, checkIn);
  yield takeLatest(CHECK_OUT_REQUEST, checkOut);
  yield takeLatest(CANCEL_RESERVATION_REQUEST, cancel);
  yield takeLatest(FORCE_CANCEL_RESERVATION_REQUEST, forceCancel);
  yield takeLatest(GET_FULL_AVAILABILITY_REQUEST, fullAvailability);
  yield takeLatest(UPDATE_RESERVATION_ROOMS_REQUEST, updateRooms);
  yield takeLatest(UPDATE_RESERVATION_COMMENT_REQUEST, updateComment);
  yield takeLatest(UPDATE_CARD_REQUEST, updateCard);
  yield takeLatest(DELETE_CARD_REQUEST, deleteCard);
  yield takeLatest(ADD_PRODUCT_TO_RESERVATION_REQUEST, addProduct);
  yield takeLatest(REMOVE_PRODUCT_TO_RESERVATION_REQUEST, removeProduct);
  yield takeLatest(ADD_SERVICE_TO_RESERVATION_REQUEST, addService);
  yield takeLatest(REMOVE_SERVICE_TO_RESERVATION_REQUEST, removeService);
  yield takeLatest(ADD_PAYMENT_REQUEST, addPayment);
  yield takeLatest(REMOVE_PAYMENT_REQUEST, removePayment);
  yield takeLatest(ADD_EXTRA_REQUEST, addExtra);
  yield takeLatest(REMOVE_EXTRA_REQUEST, removeExtra);
}
