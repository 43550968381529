import {
  GET_RESERVATIONS_REQUEST,
  LOAD_RESERVATIONS_REQUEST,
  GET_RESERVATION_BY_ID_REQUEST,
  CLEAR_RESERVATION_SELECTED,
  UPDATE_GUEST_REQUEST,
  UPDATE_DATES_REQUEST,
  CLEAR_RESERVATION_ERRORS,
  UPDATE_RESERVATION_COMMENT_REQUEST,
  GET_FULL_AVAILABILITY_REQUEST,
  UPDATE_RESERVATION_ROOMS_REQUEST,
  UPDATE_CARD_REQUEST,
  DELETE_CARD_REQUEST,
  CHECK_IN_REQUEST,
  CHECK_OUT_REQUEST,
  ADD_PRODUCT_TO_RESERVATION_REQUEST,
  REMOVE_PRODUCT_TO_RESERVATION_REQUEST,
  ADD_SERVICE_TO_RESERVATION_REQUEST,
  REMOVE_SERVICE_TO_RESERVATION_REQUEST,
  ADD_PAYMENT_REQUEST,
  REMOVE_PAYMENT_REQUEST,
  ADD_EXTRA_REQUEST,
  REMOVE_EXTRA_REQUEST,
  CANCEL_RESERVATION_REQUEST,
  FORCE_CANCEL_RESERVATION_REQUEST
} from "../../consts/actionTypes";

export const get = payload => ({
  type: GET_RESERVATIONS_REQUEST,
  payload
});

export const load = payload => ({
  type: LOAD_RESERVATIONS_REQUEST,
  payload
});

export const getById = payload => ({
  type: GET_RESERVATION_BY_ID_REQUEST,
  payload
});

export const clear = payload => ({
  type: CLEAR_RESERVATION_SELECTED,
  payload
});

export const updateGuest = payload => ({
  type: UPDATE_GUEST_REQUEST,
  payload
});

export const updateDates = payload => ({
  type: UPDATE_DATES_REQUEST,
  payload
});

export const clearErrors = payload => ({
  type: CLEAR_RESERVATION_ERRORS,
  payload
});

export const updateComment = payload => ({
  type: UPDATE_RESERVATION_COMMENT_REQUEST,
  payload
});

export const updateCard = payload => ({
  type: UPDATE_CARD_REQUEST,
  payload
});

export const deleteCard = payload => ({
  type: DELETE_CARD_REQUEST,
  payload
});

export const fullAvailability = payload => ({
  type: GET_FULL_AVAILABILITY_REQUEST,
  payload
});

export const updateRooms = payload => ({
  type: UPDATE_RESERVATION_ROOMS_REQUEST,
  payload
});

export const checkIn = payload => ({
  type: CHECK_IN_REQUEST,
  payload
});

export const checkOut = payload => ({
  type: CHECK_OUT_REQUEST,
  payload
});

export const cancel = payload => ({
  type: CANCEL_RESERVATION_REQUEST,
  payload
});

export const forceCancel = payload => ({
  type: FORCE_CANCEL_RESERVATION_REQUEST,
  payload
});

export const addProduct = payload => ({
  type: ADD_PRODUCT_TO_RESERVATION_REQUEST,
  payload
});

export const removeProduct = payload => ({
  type: REMOVE_PRODUCT_TO_RESERVATION_REQUEST,
  payload
});

export const addService = payload => ({
  type: ADD_SERVICE_TO_RESERVATION_REQUEST,
  payload
});

export const removeService = payload => ({
  type: REMOVE_SERVICE_TO_RESERVATION_REQUEST,
  payload
});

export const addPayment = payload => ({
  type: ADD_PAYMENT_REQUEST,
  payload
});

export const removePayment = payload => ({
  type: REMOVE_PAYMENT_REQUEST,
  payload
});

export const addExtra = payload => ({
  type: ADD_EXTRA_REQUEST,
  payload
});

export const removeExtra = payload => ({
  type: REMOVE_EXTRA_REQUEST,
  payload
});
