import {
  GET_PAYMENT_TYPES_REQUEST,
  GET_PAYMENT_TYPES_SUCCESS,
  GET_PAYMENT_TYPES_FAILURE,
  USER_LOGOUT_SUCCESS
} from "../../consts/actionTypes";

const initialState = {
  lista: [],
  isLoading: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENT_TYPES_REQUEST:
      return { ...state, isLoading: true };
    case GET_PAYMENT_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lista: action.results.data
      };
    case GET_PAYMENT_TYPES_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return { ...state };
  }
}
