import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import moment from "moment";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";

import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

import InboxIcon from "@material-ui/icons/Inbox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import BlockIcon from "@material-ui/icons/Block";
import DateRangeIcon from "@material-ui/icons/DateRange";
import PersonIcon from "@material-ui/icons/Person";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import ListIcon from "@material-ui/icons/List";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import FlipIcon from "@material-ui/icons/Flip";
import HotelIcon from "@material-ui/icons/Hotel";
import LabelIcon from "@material-ui/icons/Label";

import styles from "./style";

import { clear, getById } from "../../redux/actions/reservaciones";

function Detalles({ code }) {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();
  const seleccionado = useSelector(state => state.pendientes.seleccionado);
  const isSearching = useSelector(state => state.pendientes.isSearching);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [Hdetails, setHdetails] = React.useState(false);
  const [PMdetails, setPMdetails] = React.useState(false);
  const [Rdetails, setRdetails] = React.useState(false);
  const [Cdetails, setCdetails] = React.useState(false);
  const [cardFocus, setCardFocus] = React.useState("number");

  React.useEffect(() => {
    if (!isSearching && code && (!seleccionado || code !== seleccionado.code)) {
      dispatch(
        getById({
          code: code,
          header: {
            Authorization:
              localStorage.getItem("token_type") +
              " " +
              localStorage.getItem("access_token")
          }
        })
      );
    }
  });

  if (isSearching) {
    return (
      <div className={classes.empty} style={{ flexDirection: "column" }}>
        <CircularProgress />
        <div className={classes.verticalSpacing} />
        <Typography>Obteniendo información...</Typography>
      </div>
    );
  }

  if (Boolean(seleccionado)) {
    const status = state => {
      switch (state) {
        case 1:
          return "#0091ea";
        default:
          return "#bf360c";
      }
    };

    return (
      <>
        <AppBar position="static" color="default">
          <Toolbar>
            <IconButton
              onClick={() => {
                history.push("/pendientes");
                dispatch(clear());
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">{seleccionado.code}</Typography>
            <div style={{ flexGrow: 1 }} />
            <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="room-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem>
                <ListItemIcon>
                  <BlockIcon fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText primary="Cancelar" />
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <div className={classes.verticalSpacing} />
        <div className={classes.header}>
          <div style={{ flexGrow: 1 }} />
          <Chip
            label={seleccionado.estado_reservacion.nombre}
            style={{
              backgroundColor: status(seleccionado.estado),
              color: "#fff"
            }}
          />
        </div>
        <List>
          <ListItem>
            <ListItemIcon>
              <DateRangeIcon style={{ color: "#0288d1" }} />
            </ListItemIcon>
            <ListItemText
              primary="Check-in"
              secondary={moment(seleccionado.desde).format(
                "dddd, DD MMMM YYYY"
              )}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DateRangeIcon style={{ color: "#bf360c" }} />
            </ListItemIcon>
            <ListItemText
              primary="Check-out"
              secondary={moment(seleccionado.hasta).format(
                "dddd, DD MMMM YYYY"
              )}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PersonIcon style={{ color: "#000" }} />
            </ListItemIcon>
            <ListItemText
              primary="Huesped"
              secondary={seleccionado.huesped.nombre_completo}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText
              primary="Email"
              secondary={seleccionado.huesped.email}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText
              primary="Teléfono"
              secondary={seleccionado.huesped.telefono}
            />
          </ListItem>
          <ListItem button onClick={() => setHdetails(!Hdetails)}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Datos de facturacón" />
            {Hdetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={Hdetails} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem className={classes.nested}>
                <ListItemText
                  primary="Facturar a"
                  secondary={seleccionado.huesped.compania || "-"}
                />
              </ListItem>
              <ListItem className={classes.nested}>
                <ListItemText
                  primary="RFC"
                  secondary={seleccionado.huesped.rfc || "-"}
                />
              </ListItem>
              <ListItem className={classes.nested}>
                <ListItemText
                  primary="Dirección"
                  secondary={
                    (seleccionado.huesped.direccion || "-") +
                    " " +
                    (seleccionado.huesped.direccion2 || "")
                  }
                />
              </ListItem>
              <ListItem className={classes.nested}>
                <ListItemText
                  primary="C.P"
                  secondary={seleccionado.huesped.codigo_postal || "-"}
                />
              </ListItem>
              <ListItem className={classes.nested}>
                <ListItemText
                  primary="Ciudad"
                  secondary={seleccionado.huesped.ciudad || "-"}
                />
              </ListItem>
              <ListItem className={classes.nested}>
                <ListItemText
                  primary="Estado"
                  secondary={seleccionado.huesped.estado || "-"}
                />
              </ListItem>
              <ListItem className={classes.nested}>
                <ListItemText
                  primary="País"
                  secondary={seleccionado.huesped.pais || "-"}
                />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => setPMdetails(!PMdetails)}>
            <ListItemIcon>
              <CreditCardIcon />
            </ListItemIcon>
            <ListItemText primary="Método de pago" />
            {PMdetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={PMdetails} timeout="auto" unmountOnExit>
            <List>
              {seleccionado.tarjeta ? (
                <ListItem
                  className={classes.nested}
                  style={{ display: "block" }}
                >
                  <div className={classes.header} style={{ flex: 1 }}>
                    {seleccionado.tarjeta.tipo !== "amex" && (
                      <IconButton
                        onClick={() =>
                          cardFocus === "number"
                            ? setCardFocus("cvc")
                            : setCardFocus("number")
                        }
                      >
                        <FlipIcon />
                      </IconButton>
                    )}
                  </div>
                  <Cards
                    name={seleccionado.tarjeta.titular}
                    number={seleccionado.tarjeta.numero}
                    expiry={
                      seleccionado.tarjeta.mes + seleccionado.tarjeta.anio
                    }
                    cvc={seleccionado.tarjeta.cvv}
                    acceptedCards={["visa", "mastercard", "amex"]}
                    placeholders={{
                      name: "TU NOMBRE"
                    }}
                    locale={{ valid: "Vence" }}
                    focused={cardFocus}
                  />
                </ListItem>
              ) : (
                <ListItem className={classes.nested}>
                  <ListItemText primary="Sin método de pago." />
                </ListItem>
              )}
            </List>
          </Collapse>
          <ListItem button onClick={() => setRdetails(!Rdetails)}>
            <ListItemIcon>
              <HotelIcon />
            </ListItemIcon>
            <ListItemText primary="Cuartos asignados" />
            {Rdetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          {/*<Collapse in={Rdetails} timeout="auto" unmountOnExit>
            <TablaCuartos
              cuartosReservados={seleccionado.cuartos_reservados}
              desde={seleccionado.desde}
              hasta={seleccionado.hasta}
            />
          </Collapse>*/}
          <ListItem button onClick={() => setCdetails(!Cdetails)}>
            <ListItemIcon>
              <LabelIcon />
            </ListItemIcon>
            <ListItemText primary="Notas de reservacion" />
            {Cdetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          {/*<Collapse in={Cdetails} timeout="auto" unmountOnExit>
            <Notas
              reservacion_id={seleccionado.id}
              comentarios={seleccionado.comentarios}
            />
          </Collapse>*/}
        </List>
      </>
    );
  }

  return (
    <div className={classes.empty}>
      <InboxIcon fontSize="large" style={{ marginRight: 8 }} />
      <Typography variant="h6">
        Seleccione una reservación para ver su información
      </Typography>
    </div>
  );
}

Detalles.propTypes = {
  code: PropTypes.string
};

export default Detalles;
