import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import HotelIcon from "@material-ui/icons/Hotel";
import PeopleIcon from "@material-ui/icons/People";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import styles from "./style";
import { moneyFormat } from "../../functions";

function ItemTarifa({ tarifa, index, handleSelect }) {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const disponible = hasta => {
    const today = moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    });
    const date = moment(hasta).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    });
    return date.diff(today, "d") > 0;
  };

  return (
    <>
      {index > 0 && <Divider variant="inset" />}
      <ListItem
        button
        onClick={() => handleSelect(tarifa.id)}
        component="li"
        className={classes.listStyle}
      >
        <ListItemIcon>
          {!tarifa.hasta || disponible(tarifa.hasta) ? (
            <CheckBoxIcon color="secondary" />
          ) : (
            <CancelPresentationIcon color="error" />
          )}
        </ListItemIcon>
        <ListItemAvatar>
          <Avatar
            alt={`Avatar for${tarifa.etiqueta}`}
            src={tarifa.tipo_cuarto.image_url}
            className={classes.avatar}
          />
        </ListItemAvatar>
        <ListItemText
          primary={tarifa.etiqueta}
          primaryTypographyProps={{ variant: matches ? "subtitle1" : "h6" }}
          secondary={
            <React.Fragment>
              <Chip
                icon={<HotelIcon style={{ color: "#fff" }} />}
                component="span"
                label={moneyFormat(tarifa.precio)}
                style={{ backgroundColor: "#1b5e20", color: "#fff" }}
              />
              <Chip
                icon={<PeopleIcon style={{ color: "#fff" }} />}
                component="span"
                label={tarifa.huespedes}
                style={{ backgroundColor: "#37474f", color: "#fff" }}
              />
              <Chip
                icon={<SwapHorizIcon style={{ color: "#fff" }} />}
                component="span"
                label={tarifa.reembolsable ? "Si" : "No"}
                style={{ backgroundColor: "#0097a7", color: "#fff" }}
              />
            </React.Fragment>
          }
          secondaryTypographyProps={{ className: classes.chips }}
        />
        <ListItemIcon>
          <NavigateNextIcon />
        </ListItemIcon>
      </ListItem>
    </>
  );
}

ItemTarifa.propTypes = {
  tarifa: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired
};

export default ItemTarifa;
