import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ImageIcon from "@material-ui/icons/Image";

import { upload, finish } from "../../redux/actions/tiposCuarto";
import styles from "./style";

function FormularioImagen({ open, id, onClose, current_url }) {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const inputRef = React.useRef(null);
  const dispatch = useDispatch();
  const isUploadingImage = useSelector(
    state => state.tiposCuarto.isUploadingImage
  );
  const lastUploaded = useSelector(state => state.tiposCuarto.lastUploaded);

  const [image, setImage] = React.useState(null);
  const [size, setSize] = React.useState(null);
  const [file, setFile] = React.useState(null);

  React.useEffect(() => {
    if (current_url === lastUploaded) {
      setImage(null)
      setSize(null)
      setFile(null)
      dispatch(finish())
      onClose();
    }
  }, [current_url, lastUploaded, dispatch, onClose]);

  const onDrop = React.useCallback(acceptedFiles => {
    setImage(URL.createObjectURL(acceptedFiles[0]));
    setSize(acceptedFiles[0].size);
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpdate = e => {
    e.preventDefault();
    if (size > 5000000) {
      alert("Las imagenes deben pesar menos de 5MB.");
      return;
    }
    var formData = new FormData();
    formData.append("image", file);
    dispatch(
      upload({
        id: id,
        formData: formData,
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token"),
          "Content-Type": "multipart/form-data"
        }
      })
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={matches}
      fullWidth
      maxWidth="sm"
      scroll="paper"
    >
      <DialogTitle>{"Cambiar Imagen"}</DialogTitle>
      <DialogContent>
        {isUploadingImage ? (
          <div className={classes.loading}>
            <CircularProgress />
            <Typography variant="subtitle1">Cambiando imagen...</Typography>
          </div>
        ) : (
          <form onSubmit={handleUpdate}>
            <div {...getRootProps({ className: classes.dropzone })}>
              <input
                {...getInputProps({
                  multiple: false,
                  accept: "image/*"
                })}
                className={classes.preview}
                required
              />
              <>
                <ImageIcon fontSize="large" style={{ marginRight: 8 }} />
                {isDragActive ? (
                  <p className={classes.text}>Suelta el archivo aqui...</p>
                ) : (
                  <p className={classes.text}>
                    {image
                      ? "Seleccionar otra imagen"
                      : "Arrastra y suelta el archivo aqui, o da clic para seleccionar el archivo"}
                  </p>
                )}
              </>
            </div>
            {image && <img src={image} alt="" className={classes.preview} />}
            <button hidden type="submit" ref={inputRef} />
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default">
          Cerrar
        </Button>
        <Button color="primary" onClick={() => inputRef.current.click()}>
          Guardar{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FormularioImagen.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  current_url: PropTypes.string.isRequired
};

export default FormularioImagen;
