import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import routes from "../../consts/routes";
// Material UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// Iconos
import HomeIcon from "@material-ui/icons/Home";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import HotelIcon from "@material-ui/icons/Hotel";
import HistoryIcon from "@material-ui/icons/History";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import TimerIcon from "@material-ui/icons/Timer";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import MessageIcon from "@material-ui/icons/Message";
import PeopleIcon from "@material-ui/icons/People";

function Menu() {
  const [selected, setSelected] = React.useState(0);
  const rol = useSelector(state => state.user.user_rol.nombre);

  let menu = [
    { tag: "Inicio", icon: <HomeIcon />, route: routes.main },
    {
      tag: "Tipos de cuarto",
      icon: <CreateNewFolderIcon />,
      route: routes.tiposCuarto
    },
    { tag: "Habitaciones", icon: <HotelIcon />, route: routes.cuartos },
    { tag: "Tarifas", icon: <MonetizationOnIcon />, route: routes.tarifas },
    { tag: "Pendientes", icon: <TimerIcon />, route: routes.pendientes },
    {
      tag: "Reservaciones",
      icon: <CalendarTodayIcon />,
      route: routes.reservaciones
    },
    { tag: "Historial", icon: <HistoryIcon />, route: routes.historial },
    { tag: "Servicios", icon: <RoomServiceIcon />, route: routes.servicios },
    { tag: "Productos", icon: <ShoppingCartIcon />, route: routes.productos },
    { tag: "Mensajes", icon: <MessageIcon />, route: routes.mensajes }
  ];
  if (rol === "superadmin" || rol === "admin") {
    menu.push({
      tag: "Usuarios",
      icon: <PeopleIcon />,
      route: routes.usuarios
    });
  }

  return (
    <List>
      {menu.map((item, index) => (
        <ListItem
          button
          key={item.tag}
          selected={index === selected}
          component={Link}
          to={item.route}
          onClick={() => setSelected(index)}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.tag} />
        </ListItem>
      ))}
    </List>
  );
}

export default Menu;
