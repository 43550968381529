import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";

import EditIcon from "@material-ui/icons/Edit";

import { moneyFormat } from "../../../functions";

import {
  fullAvailability,
  updateRooms
} from "../../../redux/actions/reservaciones";

function Row({ reservado, desde, hasta }) {
  const dispatch = useDispatch();
  const hotel_id = useSelector(state => state.user.hotel_id);
  const disponibilidad = useSelector(
    state => state.reservaciones.disponibilidad
  );
  const isFetching = useSelector(state => state.reservaciones.isFetching);

  const [edit, setEdit] = React.useState(false);

  const [tipoCuarto, setTipoCuarto] = React.useState(
    reservado.cuarto.tipo_de_cuarto
  );
  const [cuarto, setCuarto] = React.useState(reservado.cuarto_id);
  const [tarifa, setTarifa] = React.useState(reservado.precio_id);

  const handleFetch = cuarto_reservado => {
    dispatch(
      fullAvailability({
        hotel_id: hotel_id,
        desde: desde,
        hasta: hasta,
        cuarto_reservado: cuarto_reservado,
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
  };

  const updateRoom = cuarto_reservado => {
    dispatch(
      updateRooms({
        desde: desde,
        hasta: hasta,
        cuarto_reservado: cuarto_reservado,
        cuarto: cuarto,
        tarifa: tarifa,
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
  };

  if (edit && isFetching) {
    return (
      <TableRow>
        <TableCell colSpan={5} align="center">
          Cargando...
        </TableCell>
      </TableRow>
    );
  }

  if (edit) {
    return (
      <TableRow>
        <TableCell>
          <TextField
            select
            value={tipoCuarto}
            onChange={e => setTipoCuarto(e.target.value)}
          >
            {disponibilidad.map(tipo => (
              <MenuItem key={tipo.id} value={tipo.id}>
                {tipo.nombre_hab.es}
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
        <TableCell>
          <TextField
            select
            value={cuarto}
            onChange={e => setCuarto(e.target.value)}
          >
            {disponibilidad
              .find(item => item.id === tipoCuarto)
              .cuartos.map(cuarto => (
                <MenuItem key={cuarto.id} value={cuarto.id}>
                  {cuarto.numero}
                </MenuItem>
              ))}
          </TextField>
        </TableCell>
        <TableCell>
          <TextField
            select
            value={tarifa}
            onChange={e => setTarifa(e.target.value)}
          >
            {disponibilidad
              .find(item => item.id === tipoCuarto)
              .tarifas.map(tarifa => (
                <MenuItem key={tarifa.id} value={tarifa.id}>
                  {tarifa.etiqueta}
                </MenuItem>
              ))}
          </TextField>
        </TableCell>
        <TableCell>
          {Precio(
            disponibilidad.find(item => item.id === tipoCuarto).tarifas,
            tarifa
          )}
        </TableCell>
        <TableCell>
          <Button onClick={() => setEdit(false)} color="default">
            Cancelar
          </Button>
          <Button
            onClick={() => updateRoom(reservado.id)}
            variant="contained"
            color="primary"
          >
            Guardar
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell>{reservado.cuarto.tipo_cuarto.nombre_hab.es}</TableCell>
      <TableCell>{reservado.cuarto.numero}</TableCell>
      <TableCell>{reservado.tarifa.etiqueta}</TableCell>
      <TableCell>{moneyFormat(reservado.tarifa.precio)}</TableCell>
      <TableCell>
        <IconButton
          onClick={() => {
            setEdit(true);
            handleFetch(reservado.id);
          }}
          color="primary"
          size="small"
        >
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function Precio(tarifas, selected) {
  try {
    return moneyFormat(tarifas.find(tar => tar.id === selected).precio);
  } catch {
    return "-";
  }
}

Row.propTypes = {
  reservado: PropTypes.object.isRequired,
  desde: PropTypes.string.isRequired,
  hasta: PropTypes.string.isRequired
};

export default Row;
