import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import NumberTextField from "../../../components/NumberTextField";
import styles from "./style";
import { addExtra } from "../../../redux/actions/reservaciones";

function Ajustes({ open, handleClose, reservacion_id }) {
  const classes = styles();
  const dispatch = useDispatch();
  const inputRef = React.useRef(null);
  const isUpdating = useSelector(state => state.reservaciones.isUpdating);

  const [valor, setValor] = React.useState(0);
  const [descripcion, setDescripcion] = React.useState("");
  const [upload, setUpload] = React.useState(false);

  React.useEffect(() => {
    if (!open && (valor !== 0 || descripcion !== "" || upload)) {
      setValor(0);
      setDescripcion("");
      setUpload(false);
    }
  }, [open, valor, descripcion, upload]);

  React.useEffect(() => {
    if (upload && !isUpdating) {
      handleClose();
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    if (valor === 0) {
      alert("Indique el valor del ajuste.");
      return;
    }
    await dispatch(
      addExtra({
        reservacion_id: reservacion_id,
        valor: valor,
        descripcion: descripcion,
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    setUpload(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"xs"}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Realizar un ajuste.</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} autoComplete="off">
          <NumberTextField
            label="Cantidad"
            value={valor}
            onChange={value => setValor(value)}
            maxLength={10}
            required
          />
          <div className={classes.verticalSpacing} />
          <TextField
            multiline
            value={descripcion}
            onChange={e => setDescripcion(e.target.value)}
            label="Descripción"
            variant="outlined"
            fullWidth
          />
          <button hidden type="submit" ref={inputRef} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
        <Button
          onClick={() => inputRef.current.click()}
          color="primary"
          disabled={isUpdating}
          endIcon={isUpdating && <CircularProgress size={12} />}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Ajustes.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reservacion_id: PropTypes.number
};

export default Ajustes;
