import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import Fade from "@material-ui/core/Fade";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import moment from "moment";
import MomentUtils from "@date-io/moment";

import { add } from "../../redux/actions/tarifas";

import styles from "./style";
import RoomTypesSelect from "../../components/RoomTypesSelect";

export default function Formulario() {
  const classes = styles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const isUploading = useSelector(state => state.tarifas.isUploading);

  const [tipo_de_cuarto, setTipoCuarto] = React.useState(0);
  const [precio, setPrecio] = React.useState(0);
  const [etiqueta, setEtiqueta] = React.useState("");
  const [huespedes, setHuespedes] = React.useState(2);
  const [reembolsable, setReembolsable] = React.useState(true);
  const [desde, setDesde] = React.useState(moment());
  const [desdeError, setDesdeError] = React.useState(null);
  const [finaliza, setFinaliza] = React.useState(false);
  const [hasta, setHasta] = React.useState(moment());
  const [hastaError, setHastaError] = React.useState(null);
  const [isCompleted, setIsCompleted] = React.useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    if (desdeError) {
      alert("Formato de fecha invalido.");
      return;
    }
    if (hastaError) {
      alert("Formato de fecha invalido.");
      return;
    }
    if (tipo_de_cuarto === 0) {
      alert("Selecciona el tipo de habitación");
      return;
    }
    dispatch(
      add({
        rate: {
          tipo_de_cuarto: tipo_de_cuarto,
          precio: precio,
          etiqueta: etiqueta,
          huespedes: huespedes,
          reembolsable: reembolsable,
          desde: desde.format("YYYY-MM-DD"),
          hasta: finaliza ? hasta.format("YYYY-MM-DD") : null
        },
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    setIsCompleted(true);
  };

  React.useEffect(() => {
    if (!isUploading && isCompleted) {
      history.push("/tarifas");
    }
  }, [isCompleted, isUploading, history]);

  if (isUploading) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={64} />
        <Typography variant="subtitle1">Enviando informacón</Typography>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Tipo de habitación{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <RoomTypesSelect
            value={tipo_de_cuarto}
            handleChange={value => setTipoCuarto(value)}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Etiqueta{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <TextField
            id="input-etiqueta"
            autoFocus
            fullWidth
            value={etiqueta}
            onChange={({ target }) => setEtiqueta(target.value)}
            variant="outlined"
            margin="dense"
            inputProps={{ maxLength: 128 }}
            required
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Precio{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <TextField
            id="input-precio"
            autoFocus
            fullWidth
            value={precio}
            onChange={({ target }) => setPrecio(target.value)}
            variant="outlined"
            margin="dense"
            inputProps={{ type: "number", max: 99999999 }}
            required
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Huespedes para esta tarifa{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <TextField
            id="input-huespedes"
            autoFocus
            fullWidth
            value={huespedes}
            onChange={({ target }) => setHuespedes(target.value)}
            variant="outlined"
            margin="dense"
            inputProps={{ type: "number", max: 12 }}
            required
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Reembolsable{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <Switch
            checked={reembolsable}
            onChange={event => setReembolsable(event.target.checked)}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">
            Avtiva desde{" "}
            <Typography color="error" component="span">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <KeyboardDatePicker
              disableToolbar={!matches}
              autoOk={!matches}
              variant={matches ? "dialog" : "inline"}
              inputVariant="outlined"
              format="DD/MM/YYYY"
              margin="dense"
              id="date-picker-desde"
              value={desde}
              onChange={date => setDesde(date)}
              invalidDateMessage={"Formato de fecha invalido."}
              KeyboardButtonProps={{
                "aria-label": "Cambiar fecha"
              }}
              onError={error => setDesdeError(error)}
              required
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body1">Establecer finalización</Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <Switch
            checked={finaliza}
            onChange={event => setFinaliza(event.target.checked)}
          />
        </Grid>
      </Grid>
      <Fade in={finaliza} exit={!finaliza}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Hasta{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
              <KeyboardDatePicker
                disableToolbar={!matches}
                autoOk={!matches}
                variant={matches ? "dialog" : "inline"}
                inputVariant="outlined"
                format="DD/MM/YYYY"
                margin="dense"
                id="date-picker-hasta"
                value={hasta}
                onChange={date => setHasta(date)}
                invalidDateMessage={"Formato de fecha invalido."}
                KeyboardButtonProps={{
                  "aria-label": "Cambiar fecha"
                }}
                onError={error => setHastaError(error)}
                required
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Fade>
      <div className={classes.verticalSpacing} />
      <div className={classes.actions}>
        <Button variant="contained" color="secondary" type="submit">
          Guardar
        </Button>
      </div>
    </form>
  );
}
