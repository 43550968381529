import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import styles from "./style";
import { moneyFormat } from "../../functions";

import { del } from "../../redux/actions/productos";

export default function Detalles({ producto, handleEdit }) {
  const classes = styles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const isDeleting = useSelector(state => state.productos.isDeleting);
  const lastDeleted = useSelector(state => state.productos.lastDeleted);
  const [inProcess, setInProcess] = React.useState(false);

  React.useEffect(() => {
    if (inProcess && lastDeleted === producto.id.toString()) {
      history.goBack();
    }
  }, [inProcess, lastDeleted, history, producto]);

  const handleDelete = () => {
    setAnchorEl(null);
    dispatch(
      del({
        id: producto.id,
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    setInProcess(true);
  };

  if (isDeleting) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={64} />
        <Typography variant="subtitle1">Eliminando producto...</Typography>
      </div>
    );
  }

  return (
    <CardContent className={classes.content}>
      <div className={classes.header}>
        <Typography variant="h6">Detalles del producto.</Typography>
        <div style={{ flexGrow: 1 }} />
        <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="room-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => handleEdit()}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Editar" />
          </MenuItem>
          <MenuItem onClick={() => handleDelete()}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Eliminar" />
          </MenuItem>
        </Menu>
      </div>
      <List>
        <ListItem>
          <ListItemIcon>
            <LocalOfferIcon />
          </ListItemIcon>
          <ListItemText primary="Nombre" secondary={producto.nombre} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText
            primary="Precio"
            secondary={moneyFormat(producto.precio)}
          />
        </ListItem>
      </List>
    </CardContent>
  );
}

Detalles.propTypes = {
  producto: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired
};
