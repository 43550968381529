import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_ROLES_REQUEST,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* getRoles({ payload }) {
  try {
    const results = yield call(apiCall, "roles", null, payload.header, "GET");
    yield put({ type: GET_ROLES_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_ROLES_FAILURE, error });
  }
}
export default function* roles() {
  yield takeLatest(GET_ROLES_REQUEST, getRoles);
}
