import React from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";

import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from "@react-pdf/renderer";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";

import { get } from "../../redux/actions/contrato";
import { moneyFormat } from "../../functions";

const styles = StyleSheet.create({
  viewer: {
    width: "100%",
    height: "90vh"
  },
  page: {
    padding: "32px 64px"
  },
  header: {
    flexDirection: "row",
    alignItems: "center"
  },
  logo: {
    flex: 1
  },
  imagen: {
    width: 150
  },
  titulo: {
    flex: 1
  },
  tituloText: {
    fontSize: 16,
    textAlign: "right"
  },
  codeText: {
    fontSize: 12,
    textAlign: "right",
    marginTop: 16
  },
  content: {
    marginTop: 16,
    marginBottom: 8
  },
  terminos: {
    borderColor: "#000",
    borderWidth: 1,
    borderStyle: "solid",
    padding: "8px 16px",
    fontSize: 12,
    textAlign: "justify"
  },
  regla: {
    fontSize: 12,
    marginTop: 8,
    textAlign: "justify",
    fontWeight: "700"
  },
  footer: {
    alignItems: "center",
    marginTop: 32
  },
  footerText: {
    fontSize: 12
  },
  hour: {
    borderColor: "#000",
    borderWidth: 1,
    borderStyle: "solid",
    padding: "8px 16px",
    marginTop: 8
  },
  linea: {
    marginTop: 24
  },
  firma: {
    marginTop: 4,
    fontSize: 12
  },
  row: {
    flexDirection: "row",
    marginTop: 8,
    marginBottom: 8
  },
  bodyText: {
    textAlign: "center",
    fontSize: 12,
    marginTop: 4
  },
  boldDataText: {
    textAlign: "center",
    fontSize: 12,
    fontFamily: "Helvetica-Bold"
  },
  line: {
    height: 1,
    width: "100%",
    backgroundColor: "#000"
  }
});

const idioma = {
  es: {
    titulo: "TARJETA DE REGISTRO",
    codigo: "Código de reservación: ",
    terminos:
      "El huésped y sus acompañantes aceptan los términos de las reglas de la casa, estas reglas se encuentran visibles dentro de la habitación y en la administración, el buen uso y devolución o en su caso el pago del daño por perdida o rotura del mobiliario. También el equipo que se encuentre en el inventario anexo al reglamento, renunciando a cualquier acción y/o defensa que se oponga a este convenio entre las partes. El hotel no se hace responsable por valores (joyas, dinero en efectivo, documentos, etc.) dejados en las habitaciones.",
    regla1:
      "NO SE PERMITE FUMAR DENTRO DE LAS HABITACIONES, EN CASO OMISO HABRÁ PENALIZACIÓN DEL 50% SOBRE EL COSTO DE LA TARIFA RACK.",
    regla2:
      "EN CASO DE EXTRAVIO O NO DEVOLUCIÓN DE LLAVE ELECTRONICA HABRÁ UN CARGO DE $50 PESOS. M.N.",
    footer: "Me comprometo a desocupar esta habitación a las",
    firma: "FIRMA DEL HUESPED",
    habitacion: "Habitación",
    habitaciones: "Habitaciones",
    tarifa: "Tarifa",
    nombre: "Nombre",
    huespedes: "Huespedes",
    telefono: "Teléfono",
    email: "Correo electrónico",
    facturar: "Facturar a",
    direccion: "Dirección",
    ciudad: "Ciudad",
    estado: "Estado",
    pais: "País",
    codigo_postal: "C.P.",
    rfc: "R.F.C.",
    llegada: "Llegada",
    salida: "Salida"
  },
  en: {
    titulo: "REGISTRATION CARD",
    codigo: "Reservation code: ",
    terminos:
      "The guest and his companions accept the terms of the house rules, these rules are visible in the room and in the administration office, the proper use and return or if the payment of damages for lost or broken furniture. As well the equipment housed in the inventory attached to the regulation, waiving any action and / or defense objects to this agreement between the parties. The hotel is not responsible for values ​​(jewelry, cash, documents, etc.) left in the rooms.",
    regla1:
      "SMOKING IS NOT ALLOWED INSIDE ROOMS, IN CASE OF DISREGARD THIS ADVICE, 50% OF RACK RATE WILL BE CHARGE.",
    regla2:
      "IN CASE OF LOSS OR NOT RETURNING ELECTRONIC KEY, WILL BE A CHARGE OF $50 PESOS M.N",
    footer: "I hereby agree to vacate my room at",
    firma: "SIGNATURE",
    habitacion: "Room",
    habitaciones: "Rooms",
    tarifa: "Rate",
    nombre: "Name",
    huespedes: "Guests",
    telefono: "Phone No.",
    email: "Email",
    facturar: "Company",
    direccion: "Address",
    ciudad: "City",
    estado: "State",
    pais: "Country",
    codigo_postal: "ZIP",
    rfc: "R.F.C.",
    llegada: "Arrival",
    salida: "Departure"
  }
};

function tarifa(reservados) {
  let total = 0;
  reservados.forEach(element => {
    total += parseFloat(element.precio);
  });
  return moneyFormat(total);
}

function huespedes(reservados) {
  let cant = 0;
  reservados.forEach(element => {
    cant += parseFloat(element.huespedes);
  });
  return cant;
}

function Contrato({ location }) {
  const { code } = queryString.parse(window.location.search);
  const [lng, setLng] = React.useState("es");
  const dispatch = useDispatch();
  const reservacion = useSelector(state => state.contrato.reservacion);
  const isLoading = useSelector(state => state.contrato.isLoading);

  React.useEffect(() => {
    dispatch(
      get({
        code: code,
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
  }, [code, dispatch]);

  if (!code) {
    return (
      <Paper
        style={{
          padding: "16px 32px",
          display: "flex"
        }}
      >
        <div style={{ paddingLeft: 16 }}>
          <Typography color="error" variant="h6">Error!.</Typography>
          <Typography variant="subtitle1">No se ha proporcionado una reservación.</Typography>
        </div>
      </Paper>
    );
  }

  if (isLoading || !reservacion) {
    return (
      <Paper
        style={{
          padding: "16px 32px",
          display: "flex"
        }}
      >
        <CircularProgress style={{ color: "#000" }} size={56} />
        <div style={{ paddingLeft: 16 }}>
          <Typography variant="h6">Espera un momento.</Typography>
          <Typography variant="subtitle1">Generando documento...</Typography>
        </div>
      </Paper>
    );
  }
  return (
    <>
      <Button
        style={{ marginBottom: 16 }}
        variant="outlined"
        color="secondary"
        onClick={() => (lng === "en" ? setLng("es") : setLng("en"))}
      >
        Cambiar idioma{" "}
        <Avatar
          style={{ width: 24, height: 24, marginLeft: 4 }}
          alt=""
          src={
            lng === "en"
              ? require("../../images/mexico.png")
              : require("../../images/usa.png")
          }
        />
      </Button>
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.header}>
              <View style={styles.logo}>
                <Image
                  style={styles.imagen}
                  src={require("../../images/logo.png")}
                />
              </View>
              <View style={styles.titulo}>
                <Text style={styles.tituloText}>{idioma[lng].titulo}</Text>
                <Text style={styles.codeText}>
                  {idioma[lng].codigo}
                  <Text style={styles.boldDataText}>{reservacion.code}</Text>
                </Text>
              </View>
            </View>
            <View style={styles.content}>
              <View style={styles.row}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.boldDataText}>
                    {tarifa(reservacion.cuartos_reservados)}
                  </Text>
                  <View style={styles.line} />
                  <Text style={styles.bodyText}>{idioma[lng].tarifa}</Text>
                </View>
                <View style={{ flex: 2 }} />
                <View style={{ flex: 1 }}>
                  <Text style={styles.boldDataText}>
                    {reservacion.cuartos_reservados.map((item, index) => {
                      return index > 0
                        ? ", " + item.cuarto.numero
                        : item.cuarto.numero;
                    })}
                  </Text>
                  <View style={styles.line} />
                  <Text style={styles.bodyText}>
                    {reservacion.cuartos_reservados.length > 1
                      ? idioma[lng].habitaciones
                      : idioma[lng].habitacion}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={{ flex: 2 }}>
                  <Text style={styles.boldDataText}>
                    {reservacion.huesped.nombre_completo}
                  </Text>
                  <View style={styles.line} />
                  <Text style={styles.bodyText}>{idioma[lng].nombre}</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.boldDataText}>
                    {huespedes(reservacion.cuartos_reservados)}
                  </Text>
                  <View style={styles.line} />
                  <Text style={styles.bodyText}>{idioma[lng].huespedes}</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={{ flex: 2 }}>
                  <Text style={styles.boldDataText}>
                    {reservacion.huesped.email}
                  </Text>
                  <View style={styles.line} />
                  <Text style={styles.bodyText}>{idioma[lng].email}</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.boldDataText}>
                    {reservacion.huesped.telefono}
                  </Text>
                  <View style={styles.line} />
                  <Text style={styles.bodyText}>{idioma[lng].telefono}</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={{ flex: 2 }}>
                  <Text style={styles.boldDataText}>
                    {reservacion.huesped.compania}
                  </Text>
                  <View style={styles.line} />
                  <Text style={styles.bodyText}>{idioma[lng].facturar}</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.boldDataText}>
                    {reservacion.huesped.rfc}
                  </Text>
                  <View style={styles.line} />
                  <Text style={styles.bodyText}>{idioma[lng].rfc}</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.boldDataText}>
                    {(reservacion.huesped.direccion
                      ? reservacion.huesped.direccion
                      : "") +
                      (reservacion.huesped.direccion2
                        ? " " + reservacion.huesped.direccion2
                        : "")}
                  </Text>
                  <View style={styles.line} />
                  <View style={{ flex: 2 }}>
                    <Text style={styles.bodyText}>{idioma[lng].direccion}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.row}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.boldDataText}>
                    {reservacion.huesped.ciudad}
                  </Text>
                  <View style={styles.line} />
                  <Text style={styles.bodyText}>{idioma[lng].ciudad}</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.boldDataText}>
                    {reservacion.huesped.estado}
                  </Text>
                  <View style={styles.line} />
                  <Text style={styles.bodyText}>{idioma[lng].estado}</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.boldDataText}>
                    {reservacion.huesped.pais}
                  </Text>
                  <View style={styles.line} />
                  <Text style={styles.bodyText}>{idioma[lng].pais}</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.boldDataText}>
                    {reservacion.huesped.codigo_postal}
                  </Text>
                  <View style={styles.line} />
                  <Text style={styles.bodyText}>
                    {idioma[lng].codigo_postal}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.terminos}>
              <Text>{idioma[lng].terminos}</Text>
            </View>
            <View>
              <Text style={styles.regla}>{idioma[lng].regla1}</Text>
              <Text style={styles.regla}>{idioma[lng].regla2}</Text>
            </View>
            <View style={styles.footer}>
              <Text style={styles.footerText}>{idioma[lng].footer}</Text>
              <View style={styles.hour}>
                <Text>12:00 p.m</Text>
              </View>
              <Text style={styles.linea}>__________________________</Text>
              <Text style={styles.firma}>{idioma[lng].firma}</Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
}

export default Contrato;
