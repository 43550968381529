import {
  GET_MESSSGES_REQUEST,
  GET_MESSSGES_SUCCESS,
  GET_MESSSGES_FAILURE,
  LOAD_MESSSGES_REQUEST,
  LOAD_MESSSGES_SUCCESS,
  LOAD_MESSSGES_FAILURE,
  UPDATE_MESSAGE_REQUEST,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_FAILURE,
  SELECT_MESSAGE,
  CLEAR_MESSAGE,
  USER_LOGOUT_SUCCESS
} from "../../consts/actionTypes";

const initialState = {
  lista: [],
  seleccionado: null,
  total: 1,
  actual: 0,
  search: "",
  isLoading: false,
  isLoadingMore: false,
  isUpdating: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_MESSSGES_REQUEST:
      return { ...state, isLoading: true };
    case GET_MESSSGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lista: action.results.data.data,
        search: action.search,
        page: 1,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case GET_MESSSGES_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case LOAD_MESSSGES_REQUEST:
      return { ...state, isLoadingMore: true };
    case LOAD_MESSSGES_SUCCESS:
      return {
        ...state,
        isLoadingMore: false,
        lista: state.lista.concat(action.results.data.data),
        page: action.results.data.current_page,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case LOAD_MESSSGES_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoadingMore: false };
    case UPDATE_MESSAGE_REQUEST:
      return { ...state, isUpdating: true };
    case UPDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        seleccionado: action.results.data,
        lista: state.lista.map(mensaje => {
          if (mensaje.id === action.results.data.id) {
            return action.results.data;
          }
          return mensaje;
        })
      };
    case UPDATE_MESSAGE_FAILURE:
      return { ...state, error: action.error, isUpdating: false };
    case SELECT_MESSAGE:
      return {
        ...state,
        seleccionado: action.mensaje
      };
    case CLEAR_MESSAGE: {
      return {
        ...state,
        seleccionado: null
      };
    }
    case USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return { ...state };
  }
}
