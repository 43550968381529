import React from "react";
import { useDispatch, useSelector } from "react-redux";
// Material-UI
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
// Iconoa
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
// Acciones
import { login, loginWithToken } from "../../redux/actions/user";

import Background from "../../images/background.jpg";
import styles from "./style";
import Error from "./Error";

function Login() {
  const classes = styles();
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.user.isLoading);
  const error = useSelector(state => state.user.error);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const token = Boolean(localStorage.getItem("access_token"));

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(login({ email: email, password: password }));
  };

  if (token) {
    dispatch(
      loginWithToken({
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token")
      })
    );
  }

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.root}
        style={{
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" +
            Background +
            ")",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
      >
        <Grid item xs={8} sm={6} md={5} lg={3}>
          <Paper className={classes.paper}>
            <Typography variant="h5" align="center" gutterBottom>
              Iniciar sesión
            </Typography>
            {isLoading ? (
              <div className={classes.loadingBox}>
                <CircularProgress size={64} />
              </div>
            ) : (
              <>
                <div className={classes.verticalSpacing} />
                <form className={classes.form} onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    className={classes.input}
                    type="email"
                    variant="outlined"
                    placeholder="Correo electrónico"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    fullWidth
                    className={classes.input}
                    type="password"
                    variant="outlined"
                    placeholder="Contraseña"
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  {error && <Error />}
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      type="submit"
                    >
                      Acceder
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;
