import {
  GET_ROOMS_REQUEST,
  GET_ROOMS_SUCCESS,
  GET_ROOMS_FAILURE,
  LOAD_ROOMS_REQUEST,
  LOAD_ROOMS_SUCCESS,
  LOAD_ROOMS_FAILURE,
  GET_ROOM_BY_ID_REQUEST,
  GET_ROOM_BY_ID_SUCCESS,
  GET_ROOM_BY_ID_FAILURE,
  UPDATE_ROOM_REQUEST,
  UPDATE_ROOM_SUCCESS,
  UPDATE_ROOM_FAILURE,
  ADD_ROOM_REQUEST,
  ADD_ROOM_SUCCESS,
  ADD_ROOM_FAILURE,
  DELETE_ROOM_REQUEST,
  DELETE_ROOM_SUCCESS,
  DELETE_ROOM_FAILURE,
  ADD_CLOSE_DATE_REQUEST,
  ADD_CLOSE_DATE_SUCCESS,
  ADD_CLOSE_DATE_FAILURE,
  REMOVE_CLOSE_DATE_REQUEST,
  REMOVE_CLOSE_DATE_SUCCESS,
  REMOVE_CLOSE_DATE_FAILURE,
  USER_LOGOUT_SUCCESS
} from "../../consts/actionTypes";

const initialState = {
  lista: [],
  seleccionado: null,
  total: 1,
  actual: 0,
  search: "",
  isLoading: true,
  isLoadingMore: false,
  isUploading: false,
  isDeleting: false,
  isClosingDates: false,
  isRemovingDates: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ROOMS_REQUEST:
      return { ...state, isLoading: true };
    case GET_ROOMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lista: action.results.data.data,
        search: action.search,
        page: 1,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case GET_ROOMS_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case LOAD_ROOMS_REQUEST:
      return { ...state, isLoadingMore: true };
    case LOAD_ROOMS_SUCCESS:
      return {
        ...state,
        isLoadingMore: false,
        lista: state.lista.concat(action.results.data.data),
        page: action.results.data.current_page,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case LOAD_ROOMS_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoadingMore: false };
    case GET_ROOM_BY_ID_REQUEST:
      return { ...state, isLoading: true };
    case GET_ROOM_BY_ID_SUCCESS:
      return { ...state, isLoading: false, seleccionado: action.results.data };
    case GET_ROOM_BY_ID_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case UPDATE_ROOM_REQUEST:
      return { ...state, isLoading: true };
    case UPDATE_ROOM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        seleccionado: action.results.data,
        lista: state.lista.map(cuarto => {
          if (cuarto.id === action.results.data.id) {
            return action.results.data;
          }
          return cuarto;
        })
      };
    case UPDATE_ROOM_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case ADD_ROOM_REQUEST:
      return { ...state, isUploading: true };
    case ADD_ROOM_SUCCESS:
      return {
        ...state,
        isUploading: false,
        lista: [action.results.data, ...state.lista]
      };
    case ADD_ROOM_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isUploading: false };
    case DELETE_ROOM_REQUEST:
      return { ...state, isDeleting: true };
    case DELETE_ROOM_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        lastDeleted: action.results.data.id,
        lista: state.lista.filter(
          cuarto => cuarto.id.toString() !== action.results.data.id
        )
      };
    case DELETE_ROOM_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isDeleting: false };
    case ADD_CLOSE_DATE_REQUEST:
      return { ...state, isClosingDates: true };
    case ADD_CLOSE_DATE_SUCCESS:
      return {
        ...state,
        isClosingDates: false,
        seleccionado: {
          ...state.seleccionado,
          fechas_cerradas: [
            action.results.data,
            ...state.seleccionado.fechas_cerradas
          ]
        },
        lista: state.lista.map(cuarto => {
          if (cuarto.id === action.results.data.cuarto_id) {
            return {
              ...cuarto,
              fechas_cerradas: [action.results.data, ...cuarto.fechas_cerradas]
            };
          }
          return cuarto;
        })
      };
    case ADD_CLOSE_DATE_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isClosingDates: false };
    case REMOVE_CLOSE_DATE_REQUEST:
      return { ...state, isRemovingDates: true };
    case REMOVE_CLOSE_DATE_SUCCESS:
      return {
        ...state,
        isRemovingDates: false,
        seleccionado: {
          ...state.seleccionado,
          fechas_cerradas: state.seleccionado.fechas_cerradas.filter(
            fecha => fecha.id.toString() !== action.results.data.id
          )
        },
        lista: state.lista.map(cuarto => {
          if (cuarto.id === action.results.data.cuarto_id) {
            return {
              ...cuarto,
              fechas_cerradas: cuarto.fechas_cerradas.filter(
                fecha => fecha.id.toString() !== action.results.data.id
              )
            };
          }
          return cuarto;
        })
      };
    case REMOVE_CLOSE_DATE_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isRemovingDates: false };
    case USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return { ...state };
  }
}
