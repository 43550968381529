import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import styles from "./style";

function ItemCuarto({ cuarto, index, handleSelect }) {
  const classes = styles();
  const status = ["listo", "ocupado", "sucio", "fuera", "limpieza"];

  return (
    <>
      {index > 0 && <Divider variant="inset" />}
      <ListItem
        button
        onClick={() => handleSelect(cuarto.id)}
        component="li"
        className={classes.listStyle}
      >
        <ListItemIcon>
          {cuarto.activo ? (
            <CheckBoxIcon color="secondary" />
          ) : (
            <CheckBoxOutlineBlankIcon />
          )}
        </ListItemIcon>
        <ListItemAvatar>
          <Avatar
            alt={`Avatar for${cuarto.numero}`}
            src={cuarto.tipo_cuarto.image_url}
            className={classes.avatar}
          />
        </ListItemAvatar>
        <ListItemText
          primary={cuarto.numero}
          secondary={
            <React.Fragment>
              <Typography component="span" variant="body2">
                {cuarto.tipo_cuarto.nombre_hab.es}
              </Typography>{" "}
              <Chip
                className={clsx(classes[status[cuarto.estado.id - 1]])}
                component="span"
                label={cuarto.estado.nombre}
              />
            </React.Fragment>
          }
        />
        <ListItemIcon>
          <NavigateNextIcon />
        </ListItemIcon>
      </ListItem>
    </>
  );
}

ItemCuarto.propTypes = {
  cuarto: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired
};

export default ItemCuarto;
