import React from "react";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import styles from "./style";
import NumberTextField from "../../components/NumberTextField";

function Datos({ huesped, handleNext, handleBack }) {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const [nombre, setNombre] = React.useState(
    huesped ? huesped.nombre || "" : ""
  );
  const [apellido, setApellido] = React.useState(
    huesped ? huesped.apellido || "" : ""
  );
  const [email, setEmail] = React.useState(huesped ? huesped.email || "" : "");
  const [telefono, setTelefono] = React.useState(
    huesped ? huesped.telefono || "" : ""
  );
  const [compania, setCompania] = React.useState(
    huesped ? huesped.compania || "" : ""
  );
  const [rfc, setRfc] = React.useState(huesped ? huesped.rfc || "" : "");
  const [direccion, setDireccion] = React.useState(
    huesped ? huesped.direccion || "" : ""
  );
  const [direccion2, setDireccion2] = React.useState(
    huesped ? huesped.direccion2 || "" : ""
  );
  const [codigo_postal, setCodigoPostal] = React.useState(
    huesped ? huesped.codigo_postal || "" : ""
  );
  const [ciudad, setCiudad] = React.useState(
    huesped ? huesped.ciudad || "" : ""
  );
  const [estado, setEstado] = React.useState(
    huesped ? huesped.estado || "" : ""
  );
  const [pais, setPais] = React.useState(huesped ? huesped.pais || "" : "");

  const handleSubmit = e => {
    e.preventDefault();
    handleNext({
      nombre: nombre,
      apellido: apellido,
      email: email,
      telefono: telefono,
      compania: compania,
      direccion: direccion,
      direccion2: direccion2,
      codigo_postal: codigo_postal,
      ciudad: ciudad,
      estado: estado,
      pais: pais,
      rfc: rfc
    });
  };

  return (
    <div className={classes.stepContainer}>
      <div className={classes.header}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant={matches ? "subtitle1" : "h6"}>
          Información del cliente
        </Typography>
      </div>
      <div className={classes.verticalSpacing} />
      <form onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              Nombre{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
            <TextField
              autoFocus
              id="input-numero"
              fullWidth
              variant="outlined"
              value={nombre}
              onChange={({ target }) => setNombre(target.value.toUpperCase())}
              inputProps={{
                type: "text",
                maxLength: 64
              }}
              margin="dense"
              className={classes.input}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              Apellido{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
            <TextField
              id="input-apellido"
              fullWidth
              variant="outlined"
              value={apellido}
              onChange={({ target }) => setApellido(target.value.toUpperCase())}
              inputProps={{
                type: "text",
                maxLength: 64
              }}
              margin="dense"
              className={classes.input}
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              Correo electrónico{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
            <TextField
              id="input-email"
              fullWidth
              variant="outlined"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              inputProps={{
                type: "email",
                maxLength: 254
              }}
              margin="dense"
              className={classes.input}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              Teléfono{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
            <NumberTextField
              id="input-telefono"
              fullWidth
              variant="outlined"
              value={telefono}
              onChange={value => setTelefono(value)}
              maxLength={16}
              margin="dense"
              className={classes.input}
              required
            />
          </Grid>
        </Grid>
        <div className={classes.verticalSpacing} />
        <Typography variant="h6" gutterBottom>
          Datos de facturación (Opcional)
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">Facturar a</Typography>
            <TextField
              id="input-compania"
              fullWidth
              variant="outlined"
              value={compania}
              onChange={({ target }) => setCompania(target.value)}
              inputProps={{
                type: "text",
                maxLength: 128
              }}
              margin="dense"
              className={classes.input}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">RFC</Typography>
            <TextField
              id="input-rfc"
              fullWidth
              variant="outlined"
              value={rfc}
              onChange={({ target }) => setRfc(target.value)}
              inputProps={{
                type: "text",
                maxLength: 24
              }}
              margin="dense"
              className={classes.input}
            />
          </Grid>
        </Grid>
        <div>
          <Typography variant="body1">Dirección</Typography>
          <TextField
            id="input-direccion"
            fullWidth
            variant="outlined"
            value={direccion}
            onChange={({ target }) => setDireccion(target.value.toUpperCase())}
            rows={2}
            rowsMax={4}
            multiline
            inputProps={{
              type: "text",
              maxLength: 128
            }}
            margin="dense"
            className={classes.input}
          />
        </div>
        <div>
          <Typography variant="body1">Dirección cont.</Typography>
          <TextField
            id="input-direccion2"
            fullWidth
            variant="outlined"
            value={direccion2}
            onChange={({ target }) => setDireccion2(target.value.toUpperCase())}
            rows={2}
            rowsMax={4}
            multiline
            inputProps={{
              type: "text",
              maxLength: 128
            }}
            margin="dense"
            className={classes.input}
          />
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">C.P/ZIP</Typography>
            <TextField
              id="input-cp"
              fullWidth
              variant="outlined"
              value={codigo_postal}
              onChange={({ target }) =>
                setCodigoPostal(target.value.toUpperCase())
              }
              inputProps={{
                type: "text",
                maxLength: 16
              }}
              margin="dense"
              className={classes.input}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">Ciudad</Typography>
            <TextField
              id="input-ciudad"
              fullWidth
              variant="outlined"
              value={ciudad}
              onChange={({ target }) => setCiudad(target.value.toUpperCase())}
              inputProps={{
                type: "text",
                maxLength: 189
              }}
              margin="dense"
              className={classes.input}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">Estado</Typography>
            <TextField
              id="input-estado"
              fullWidth
              variant="outlined"
              value={estado}
              onChange={({ target }) => setEstado(target.value.toUpperCase())}
              inputProps={{
                type: "text",
                maxLength: 90
              }}
              margin="dense"
              className={classes.input}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">País</Typography>
            <TextField
              id="input-pais"
              fullWidth
              variant="outlined"
              value={pais}
              onChange={({ target }) => setPais(target.value.toUpperCase())}
              inputProps={{
                type: "text",
                maxLength: 90
              }}
              margin="dense"
              className={classes.input}
            />
          </Grid>
        </Grid>
        <div className={classes.verticalSpacing} />
        <div className={classes.actions}>
          <Button variant="contained" color="primary" fullWidth type="submit">
            Continuar
          </Button>
        </div>
      </form>
    </div>
  );
}

Datos.propTypes = {
  huesped: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired
};

export default Datos;
