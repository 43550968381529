import React from 'react'
import styles from "./style";
import Typography from '@material-ui/core/Typography'

export default function Error() {
    const classes = styles()

    return (
        <div className={classes.errorBox}>
            <Typography variant="subtitle1" color="inherit">
                Error al iniciar sesión. Esto puede deberse a:
            </Typography>
            <ul>
                <li>Correo o contraseña incorrectos</li>
                <li>Problemas en su conexión a internet</li>
            </ul>
            <Typography color="inherit">
                Revice la información y vuelva a intentar.
            </Typography>
        </div>
    )
}