import React from "react";
import PropTypes from "prop-types";

import List from "@material-ui/core/List";
import ListItem from "./ListItem";

function Lista({ reservaciones }) {
  return (
    <List
      style={{
        borderWidth: 1,
        borderColor: "#eee",
        borderStyle: "solid",
        padding: 0
      }}
    >
      {reservaciones.map(reservacion => (
        <ListItem key={reservacion.id} reservacion={reservacion} />
      ))}
    </List>
  );
}

Lista.propTypes = {
  reservaciones: PropTypes.array.isRequired
};

export default Lista;
