import {
  GET_ACCOUNTS_REQUEST,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
  LOAD_ACCOUNTS_REQUEST,
  LOAD_ACCOUNTS_SUCCESS,
  LOAD_ACCOUNTS_FAILURE,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  ADD_ACCOUNT_REQUEST,
  ADD_ACCOUNT_SUCCESS,
  ADD_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  SELECT_ACCOUNT,
  CLEAR_ACCOUNT,
  USER_LOGOUT_SUCCESS
} from "../../consts/actionTypes";

const initialState = {
  lista: [],
  seleccionado: null,
  total: 1,
  actual: 0,
  search: "",
  isLoading: false,
  isLoadingMore: false,
  isUploading: false,
  isUpdating: false,
  isDeleting: false,
  passwordReset: false,
  lastReset: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNTS_REQUEST:
      return { ...state, isLoading: true };
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lista: action.results.data.data,
        search: action.search,
        page: 1,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case GET_ACCOUNTS_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case LOAD_ACCOUNTS_REQUEST:
      return { ...state, isLoadingMore: true };
    case LOAD_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isLoadingMore: false,
        lista: state.lista.concat(action.results.data.data),
        page: action.results.data.current_page,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case LOAD_ACCOUNTS_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoadingMore: false };
    case UPDATE_ACCOUNT_REQUEST:
      return { ...state, isUpdating: true };
    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        seleccionado: action.results.data,
        lista: state.lista.map(usuario => {
          if (usuario.id === action.results.data.id) {
            return action.results.data;
          }
          return usuario;
        })
      };
    case UPDATE_ACCOUNT_FAILURE:
      return { ...state, error: action.error, isUpdating: false };
    case UPDATE_PASSWORD_REQUEST:
      return { ...state, passwordReset: true, lastReset: null };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: false,
        lastReset: action.results.data.id
      };
    case UPDATE_PASSWORD_FAILURE:
      return { ...state, error: action.error, passwordReset: false };
    case ADD_ACCOUNT_REQUEST:
      return { ...state, isUploading: true, error: null };
    case ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        isUploading: false,
        lista: [action.results.data, ...state.lista]
      };
    case ADD_ACCOUNT_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isUploading: false };
    case DELETE_ACCOUNT_REQUEST:
      return { ...state, isDeleting: true };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        seleccionado: null,
        lastDeleted: action.results.data.id,
        lista: state.lista.filter(
          usuario => usuario.id.toString() !== action.results.data.id
        )
      };
    case DELETE_ACCOUNT_FAILURE:
      return { ...state, error: action.error, isDeleting: false };
    case SELECT_ACCOUNT:
      return {
        ...state,
        seleccionado: action.usuario
      };
    case CLEAR_ACCOUNT: {
      return {
        ...state,
        seleccionado: null
      };
    }
    case USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return { ...state };
  }
}
