import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles(theme => ({
  chips: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5)
    }
  },
  verticalSpacing: {
    height: theme.spacing(2)
  },
  botonPago: {
    backgroundColor: "#43a047",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2e7d32"
    },
    "&:focus": {
      backgroundColor: "#2e7d32"
    }
  },
  botonAjuste: {
    backgroundColor: "#353A4B",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#000"
    }
  },
  price: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  }
}));
