import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { load } from "../../redux/actions/tarifas";

import styles from "./style";

function BotonCargar({ search }) {
  const classes = styles();
  const dispatch = useDispatch();
  const hotel_id = useSelector(state => state.user.hotel_id);
  const total = useSelector(state => state.tarifas.total);
  const actual = useSelector(state => state.tarifas.actual);
  const page = useSelector(state => state.tarifas.page);
  const isLoadingMore = useSelector(state => state.tarifas.isLoadingMore);

  const handleLoad = () => {
    dispatch(
      load({
        hotel_id: hotel_id,
        page: page + 1,
        orderBy: "tipo_de_cuarto",
        order: "asc",
        search: search,
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
  };
  if (actual === total) {
    return null;
  }
  return (
    <div className={classes.cargarBox}>
      {isLoadingMore ? (
        <CircularProgress size={40} color="inherit" />
      ) : (
        <Button variant="outlined" size="large" onClick={() => handleLoad()}>
          Cargar mas resultados
        </Button>
      )}
    </div>
  );
}

BotonCargar.propTypes = {
  search: PropTypes.string.isRequired
};

export default BotonCargar;