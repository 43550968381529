import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import styles from "./style";
import RolesSelect from "../../components/RolesSelect";

import { update } from "../../redux/actions/usuarios";

export default function Formulario({ handleEdit }) {
  const classes = styles();
  const dispatch = useDispatch();
  const usuario = useSelector(state => state.usuarios.seleccionado);
  const error = useSelector(state => state.usuarios.error);

  const [nombre, setNombre] = React.useState(usuario.nombre);
  const [email, setEmail] = React.useState(usuario.email);
  const [telefono, setTelefono] = React.useState(usuario.telefono);
  const [rol, setRol] = React.useState(usuario.rol);

  const handleUpdate = event => {
    event.preventDefault();
    dispatch(
      update({
        id: usuario.id,
        user: {
          nombre: nombre,
          email: email,
          telefono: telefono,
          rol: rol
        },
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    handleEdit();
  };

  return (
    <div className={classes.usuario}>
      <div className={classes.header}>
        <IconButton onClick={handleEdit}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" color="inherit">
          Editar usuario
        </Typography>
      </div>
      <div className={classes.verticalSpacing} />
      <form onSubmit={handleUpdate} autoComplete="off">
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Nombre completo{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-nombre"
              autoFocus
              fullWidth
              value={nombre}
              onChange={({ target }) => setNombre(target.value)}
              variant="outlined"
              margin="dense"
              inputProps={{ maxLength: 255 }}
              required
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Rol{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <RolesSelect value={rol} handleChange={value => setRol(value)} />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Teléfono{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-phone"
              fullWidth
              value={telefono}
              onChange={({ target }) => setTelefono(target.value)}
              variant="outlined"
              margin="dense"
              inputProps={{ type: "number", max: 9999999999 }}
              required
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Email{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-email"
              fullWidth
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              variant="outlined"
              margin="dense"
              inputProps={{ type: "email" }}
              required
              error={Boolean(error && error.response.data.errors.email)}
              helperText={
                error && error.response.data.errors.email
                  ? "Ya existe una cuenta con este correo."
                  : ""
              }
            />
          </Grid>
        </Grid>
        <div className={classes.verticalSpacing} />
        <div className={classes.actions}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
          >
            Guardar
          </Button>
        </div>
      </form>
    </div>
  );
}
