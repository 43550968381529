import {
  GET_RESERVATIONS_REQUEST,
  GET_RESERVATIONS_SUCCESS,
  GET_RESERVATIONS_FAILURE,
  LOAD_RESERVATIONS_REQUEST,
  LOAD_RESERVATIONS_SUCCESS,
  LOAD_RESERVATIONS_FAILURE,
  GET_RESERVATION_BY_ID_REQUEST,
  GET_RESERVATION_BY_ID_SUCCESS,
  GET_RESERVATION_BY_ID_FAILURE,
  CLEAR_RESERVATION_SELECTED,
  UPDATE_GUEST_REQUEST,
  UPDATE_GUEST_SUCCESS,
  UPDATE_GUEST_FAILURE,
  UPDATE_DATES_REQUEST,
  UPDATE_DATES_SUCCESS,
  UPDATE_DATES_FAILURE,
  CHECK_IN_REQUEST,
  CHECK_IN_SUCCESS,
  CHECK_IN_FAILURE,
  CHECK_OUT_REQUEST,
  CHECK_OUT_SUCCESS,
  CHECK_OUT_FAILURE,
  CANCEL_RESERVATION_REQUEST,
  CANCEL_RESERVATION_SUCCESS,
  CANCEL_RESERVATION_FAILURE,
  FORCE_CANCEL_RESERVATION_REQUEST,
  FORCE_CANCEL_RESERVATION_SUCCESS,
  FORCE_CANCEL_RESERVATION_FAILURE,
  GET_FULL_AVAILABILITY_REQUEST,
  GET_FULL_AVAILABILITY_SUCCESS,
  GET_FULL_AVAILABILITY_FAILURE,
  UPDATE_RESERVATION_ROOMS_REQUEST,
  UPDATE_RESERVATION_ROOMS_SUCCESS,
  UPDATE_RESERVATION_ROOMS_FAILURE,
  UPDATE_RESERVATION_COMMENT_REQUEST,
  UPDATE_RESERVATION_COMMENT_SUCCESS,
  UPDATE_RESERVATION_COMMENT_FAILURE,
  UPDATE_CARD_REQUEST,
  UPDATE_CARD_SUCCESS,
  UPDATE_CARD_FAILURE,
  DELETE_CARD_REQUEST,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_FAILURE,
  ADD_PRODUCT_TO_RESERVATION_REQUEST,
  ADD_PRODUCT_TO_RESERVATION_SUCCESS,
  ADD_PRODUCT_TO_RESERVATION_FAILURE,
  REMOVE_PRODUCT_TO_RESERVATION_REQUEST,
  REMOVE_PRODUCT_TO_RESERVATION_SUCCESS,
  REMOVE_PRODUCT_TO_RESERVATION_FAILURE,
  ADD_SERVICE_TO_RESERVATION_REQUEST,
  ADD_SERVICE_TO_RESERVATION_SUCCESS,
  ADD_SERVICE_TO_RESERVATION_FAILURE,
  REMOVE_SERVICE_TO_RESERVATION_REQUEST,
  REMOVE_SERVICE_TO_RESERVATION_SUCCESS,
  REMOVE_SERVICE_TO_RESERVATION_FAILURE,
  ADD_PAYMENT_REQUEST,
  ADD_PAYMENT_SUCCESS,
  ADD_PAYMENT_FAILURE,
  REMOVE_PAYMENT_REQUEST,
  REMOVE_PAYMENT_SUCCESS,
  REMOVE_PAYMENT_FAILURE,
  ADD_EXTRA_REQUEST,
  ADD_EXTRA_SUCCESS,
  ADD_EXTRA_FAILURE,
  REMOVE_EXTRA_REQUEST,
  REMOVE_EXTRA_SUCCESS,
  REMOVE_EXTRA_FAILURE,
  CLEAR_RESERVATION_ERRORS,
  USER_LOGOUT_SUCCESS
} from "../../consts/actionTypes";

const initialState = {
  lista: [],
  seleccionado: null,
  total: 1,
  actual: 0,
  search: "",
  isLoading: false,
  isLoadingMore: false,
  isSearching: false,
  isUpdating: false,
  disponibilidad: [],
  isFetching: false,
  isUpdatingComment: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_RESERVATIONS_REQUEST:
      return { ...state, isLoading: true };
    case GET_RESERVATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lista: action.results.data.data,
        search: action.search,
        page: 1,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case GET_RESERVATIONS_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoading: false };
    case LOAD_RESERVATIONS_REQUEST:
      return { ...state, isLoadingMore: true };
    case LOAD_RESERVATIONS_SUCCESS:
      return {
        ...state,
        isLoadingMore: false,
        lista: state.lista.concat(action.results.data.data),
        page: action.results.data.current_page,
        total: action.results.data.total,
        actual: action.results.data.to
      };
    case LOAD_RESERVATIONS_FAILURE:
      console.log(action);
      return { ...state, error: action.error, isLoadingMore: false };
    case GET_RESERVATION_BY_ID_REQUEST:
      return { ...state, isSearching: true, searchError: null };
    case GET_RESERVATION_BY_ID_SUCCESS:
      return {
        ...state,
        isSearching: false,
        seleccionado: action.results.data
      };
    case GET_RESERVATION_BY_ID_FAILURE:
      return { ...state, isSearching: false, searchError: action.error };
    case CLEAR_RESERVATION_SELECTED:
      return { ...state, seleccionado: null, searchError: null };
    case UPDATE_GUEST_REQUEST:
      return { ...state, isUpdating: true };
    case UPDATE_GUEST_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        seleccionado: { ...state.seleccionado, huesped: action.results.data },
        lista: state.lista.map(reservacion => {
          if (reservacion.id === action.res) {
            return {
              ...reservacion,
              huesped: action.results.data
            };
          }
          return reservacion;
        })
      };
    case UPDATE_GUEST_FAILURE:
      console.log(action);
      return { ...state, isUpdating: false, error: action.error };
    case UPDATE_DATES_REQUEST:
      return { ...state, isUpdating: true, datesError: null };
    case UPDATE_DATES_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        seleccionado: action.results.data
      };
    case UPDATE_DATES_FAILURE:
      console.log(action);
      return {
        ...state,
        isUpdating: false,
        error: action.error,
        datesError: action.error.response.data
      };
    case CHECK_IN_REQUEST:
      return { ...state, isUpdating: true };
    case CHECK_IN_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        seleccionado: action.results.data,
        lista: state.lista.map(reservacion => {
          if (reservacion.id === action.results.data.id) {
            return action.results.data;
          }
          return reservacion;
        })
      };
    case CHECK_IN_FAILURE:
      console.log(action);
      return {
        ...state,
        isUpdating: false,
        error: action.error
      };
    case CHECK_OUT_REQUEST:
      return { ...state, isUpdating: true };
    case CHECK_OUT_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        seleccionado: null,
        lista: state.lista.filter(
          reservacion => reservacion.id !== action.results.data.reservacion_id
        )
      };
    case CHECK_OUT_FAILURE:
      return {
        ...state,
        isUpdating: false
      };
    case CANCEL_RESERVATION_REQUEST:
      return { ...state, isUpdating: true };
    case CANCEL_RESERVATION_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        seleccionado: null,
        lista: state.lista.filter(
          res => res.id.toString() !== action.results.data.id
        )
      };
    case CANCEL_RESERVATION_FAILURE:
      console.log(action);
      return {
        ...state,
        isUpdating: false,
        error: action.error
      };
    case FORCE_CANCEL_RESERVATION_REQUEST:
      return { ...state, isUpdating: true };
    case FORCE_CANCEL_RESERVATION_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        seleccionado: null,
        lista: state.lista.filter(
          res => res.id.toString() !== action.results.data.id
        )
      };
    case FORCE_CANCEL_RESERVATION_FAILURE:
      console.log(action);
      return {
        ...state,
        isUpdating: false,
        error: action.error
      };
    case GET_FULL_AVAILABILITY_REQUEST:
      return { ...state, isFetching: true };
    case GET_FULL_AVAILABILITY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        disponibilidad: action.results.data
      };
    case GET_FULL_AVAILABILITY_FAILURE:
      console.log(action);
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case UPDATE_RESERVATION_ROOMS_REQUEST:
      return { ...state, isSearching: true };
    case UPDATE_RESERVATION_ROOMS_SUCCESS:
      return {
        ...state,
        isSearching: false,
        seleccionado: {
          ...state.seleccionado,
          cuartos_reservados: state.seleccionado.cuartos_reservados.map(res => {
            if (res.id === action.results.data.id) {
              return action.results.data;
            }
            return res;
          })
        }
      };
    case UPDATE_RESERVATION_ROOMS_FAILURE:
      console.log(action);
      return {
        ...state,
        isSearching: false,
        error: action.error
      };
    case UPDATE_RESERVATION_COMMENT_REQUEST:
      return { ...state, isUpdatingComment: true };
    case UPDATE_RESERVATION_COMMENT_SUCCESS:
      return {
        ...state,
        isUpdatingComment: false,
        seleccionado: {
          ...state.seleccionado,
          comentarios: action.results.data
        }
      };
    case UPDATE_RESERVATION_COMMENT_FAILURE:
      console.log(action);
      return {
        ...state,
        isUpdatingComment: false,
        error: action.error
      };
    case UPDATE_CARD_REQUEST:
      return { ...state, isUpdating: true, datesError: null };
    case UPDATE_CARD_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        seleccionado: { ...state.seleccionado, tarjeta: action.results.data }
      };
    case UPDATE_CARD_FAILURE:
      console.log(action);
      return {
        ...state,
        isUpdating: false,
        error: action.error
      };
    case DELETE_CARD_REQUEST:
      return { ...state, isUpdating: true, error: null };
    case DELETE_CARD_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        seleccionado: { ...state.seleccionado, tarjeta: null }
      };
    case DELETE_CARD_FAILURE:
      console.log(action);
      return {
        ...state,
        isUpdating: false,
        error: action.error
      };
    case ADD_PRODUCT_TO_RESERVATION_REQUEST:
      return { ...state, isUpdating: true };
    case ADD_PRODUCT_TO_RESERVATION_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        seleccionado: {
          ...state.seleccionado,
          productos: [...state.seleccionado.productos, action.results.data]
        }
      };
    case ADD_PRODUCT_TO_RESERVATION_FAILURE:
      console.log(action.error);
      return { ...state, isUpdating: false, error: action.error };
    case REMOVE_PRODUCT_TO_RESERVATION_REQUEST:
      return { ...state };
    case REMOVE_PRODUCT_TO_RESERVATION_SUCCESS:
      return {
        ...state,
        seleccionado: {
          ...state.seleccionado,
          productos: state.seleccionado.productos.filter(
            prod => prod.id.toString() !== action.results.data.id
          )
        }
      };
    case REMOVE_PRODUCT_TO_RESERVATION_FAILURE:
      console.log(action.error);
      return { ...state, error: action.error };
    case ADD_SERVICE_TO_RESERVATION_REQUEST:
      return { ...state, isUpdating: true };
    case ADD_SERVICE_TO_RESERVATION_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        seleccionado: {
          ...state.seleccionado,
          servicios: [...state.seleccionado.servicios, action.results.data]
        }
      };
    case ADD_SERVICE_TO_RESERVATION_FAILURE:
      console.log(action.error);
      return { ...state, isUpdating: false, error: action.error };
    case REMOVE_SERVICE_TO_RESERVATION_REQUEST:
      return { ...state };
    case REMOVE_SERVICE_TO_RESERVATION_SUCCESS:
      return {
        ...state,
        seleccionado: {
          ...state.seleccionado,
          servicios: state.seleccionado.servicios.filter(
            serv => serv.id.toString() !== action.results.data.id
          )
        }
      };
    case REMOVE_SERVICE_TO_RESERVATION_FAILURE:
      console.log(action.error);
      return { ...state, error: action.error };
    case ADD_PAYMENT_REQUEST:
      return { ...state, isUpdating: true };
    case ADD_PAYMENT_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        seleccionado: {
          ...state.seleccionado,
          pagos: [...state.seleccionado.pagos, action.results.data]
        }
      };
    case ADD_PAYMENT_FAILURE:
      return { ...state, isUpdating: false, error: action.error };
    case REMOVE_PAYMENT_REQUEST:
      return { ...state };
    case REMOVE_PAYMENT_SUCCESS:
      return {
        ...state,
        seleccionado: {
          ...state.seleccionado,
          pagos: state.seleccionado.pagos.filter(
            pago => pago.id.toString() !== action.results.data.id
          )
        }
      };
    case REMOVE_PAYMENT_FAILURE:
      console.log(action.error);
      return { ...state, error: action.error };
    case ADD_EXTRA_REQUEST:
      return { ...state, isUpdating: true };
    case ADD_EXTRA_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        seleccionado: {
          ...state.seleccionado,
          ajustes: [...state.seleccionado.ajustes, action.results.data]
        }
      };
    case ADD_EXTRA_FAILURE:
      return { ...state, isUpdating: false, error: action.error };
    case REMOVE_EXTRA_REQUEST:
      return { ...state };
    case REMOVE_EXTRA_SUCCESS:
      return {
        ...state,
        seleccionado: {
          ...state.seleccionado,
          ajustes: state.seleccionado.ajustes.filter(
            ajuste => ajuste.id.toString() !== action.results.data.id
          )
        }
      };
    case REMOVE_EXTRA_FAILURE:
      console.log(action.error);
      return { ...state, error: action.error };
    case CLEAR_RESERVATION_ERRORS:
      return { ...state, error: null, datesError: null, searchError: null };
    case USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return { ...state };
  }
}
