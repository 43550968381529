import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import CloseIcon from "@material-ui/icons/Close";

import { update } from "../../redux/actions/tiposCuarto";
import styles from "./style";

export default function Formulario({ cuarto, handleEdit }) {
  const classes = styles();
  const dispatch = useDispatch();

  const [nombreEs, setNombreEs] = React.useState(cuarto.nombre_hab.es);
  const [nombreEn, setNombreEn] = React.useState(cuarto.nombre_hab.en);
  const [descripcionEs, setDescripcionEs] = React.useState(
    cuarto.descripcion_hab.es
  );
  const [descripcionEn, setDescripcionEn] = React.useState(
    cuarto.descripcion_hab.en
  );
  const [camas, setCamas] = React.useState(cuarto.camas);
  const [huespedes, setHuespedes] = React.useState(cuarto.huespedes);

  const handleUpdate = event => {
    event.preventDefault();
    dispatch(
      update({
        id: cuarto.id,
        room: {
          nombre: {
            es: nombreEs,
            en: nombreEn
          },
          descripcion: {
            es: descripcionEs,
            en: descripcionEn
          },
          camas: camas,
          huespedes: huespedes
        },
        header: {
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token")
        }
      })
    );
    handleEdit();
  };

  return (
    <CardContent className={classes.content}>
      <div className={classes.header}>
        <Typography variant="h6">Editar información</Typography>
        <div style={{ flexGrow: 1 }} />
        <IconButton onClick={() => handleEdit()}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.verticalSpacing} />
      <form onSubmit={handleUpdate} autoComplete="off">
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Nombre (español){" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-nombre-es"
              autoFocus
              fullWidth
              value={nombreEs}
              onChange={({ target }) => setNombreEs(target.value)}
              variant="outlined"
              margin="dense"
              required
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Nombre (ingles){" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-nombre-en"
              fullWidth
              value={nombreEn}
              onChange={({ target }) => setNombreEn(target.value)}
              variant="outlined"
              margin="dense"
              required
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Descripción (español){" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-descripcion-es"
              fullWidth
              value={descripcionEs}
              onChange={({ target }) => setDescripcionEs(target.value)}
              variant="outlined"
              margin="dense"
              required
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Descripción (ingles){" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-descripcion-en"
              fullWidth
              value={descripcionEn}
              onChange={({ target }) => setDescripcionEn(target.value)}
              variant="outlined"
              margin="dense"
              required
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Camas
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-camas"
              fullWidth
              value={camas}
              onChange={({ target }) => setCamas(target.value)}
              variant="outlined"
              margin="dense"
              required
              InputProps={{
                type: "number"
              }}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1">
              Capacidad máxima
              <Typography color="error" component="span">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <TextField
              id="input-capacidad"
              fullWidth
              value={huespedes}
              onChange={({ target }) => setHuespedes(target.value)}
              variant="outlined"
              margin="dense"
              required
              InputProps={{
                type: "number"
              }}
            />
          </Grid>
        </Grid>
        <div className={classes.verticalSpacing} />
        <div className={classes.actions}>
          <Button variant="contained" color="secondary" type="submit">
            Guardar
          </Button>
        </div>
      </form>
    </CardContent>
  );
}

Formulario.propTypes = {
  cuarto: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired
};
