import { put, call, takeLatest } from "redux-saga/effects";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE
} from "../../consts/actionTypes";

import { apiCall } from "../api";

export function* userLogin({ payload }) {
  try {
    const results = yield call(apiCall, "auth/login", payload, null, "POST");
    localStorage.setItem("access_token", results.data.access_token);
    localStorage.setItem("token_type", results.data.token_type);
    yield put({ type: USER_LOGIN_SUCCESS, results });
  } catch (error) {
    yield put({ type: USER_LOGIN_FAILURE, error });
  }
}

export function* userLoginWithToken({ payload }) {
  try {
    const results = yield call(apiCall, "auth/user", null, payload, "GET");
    yield put({ type: GET_USER_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_USER_FAILURE, error });
  }
}

export function* userLogout({ payload }) {
  try {
    const results = yield call(apiCall, "auth/logout", null, payload, "GET");
    localStorage.clear();
    yield put({ type: USER_LOGOUT_SUCCESS, results });
  } catch (error) {
    yield put({ type: USER_LOGOUT_FAILURE, error });
  }
}

export default function* user() {
  yield takeLatest(USER_LOGIN_REQUEST, userLogin);
  yield takeLatest(GET_USER_REQUEST, userLoginWithToken);
  yield takeLatest(USER_LOGOUT_REQUEST, userLogout);
}
