import { NUEVO_MENSAJE, LIMPIAR_MENSAJE } from "../../consts/actionTypes";

export const nuevo = payload => ({
  type: NUEVO_MENSAJE,
  payload
});

export const limpiar = () => ({
  type: LIMPIAR_MENSAJE
});
